import "./questionary.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Completed from './Completed';
// import { useNavigate } from 'react-router-dom';

export default function SixthStep() {

    const handleSave = () => {

        if(document.getElementById('nitrogeno').value >= 0){
            localStorage.setItem('urea',  document.getElementById('nitrogeno').value);
        } else {
            
        }

        if(document.getElementById('creatinina').value >= 0){
            localStorage.setItem('creatinina',  document.getElementById('creatinina').value);
        }

        if(document.getElementById('acido').value >= 0){
            localStorage.setItem('acido_urico',  document.getElementById('acido').value);
        }

        if(document.getElementById('sodio').value >= 0){
            localStorage.setItem('sodio',  document.getElementById('sodio').value);
        }

        if(document.getElementById('potasio').value >= 0){
            localStorage.setItem('potasio',  document.getElementById('potasio').value);
        }

        if(document.getElementById('cloro').value >= 0){
            localStorage.setItem('cloro',  document.getElementById('cloro').value);
        }

        if(document.getElementById('calcio').value >= 0){
            localStorage.setItem('calcio',  document.getElementById('calcio').value);
        }
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {
            if(localStorage.hasOwnProperty('urea')){
                document.getElementById('nitrogeno').value = localStorage.getItem('urea');
            }
            

            if(localStorage.hasOwnProperty('creatinina')){
                document.getElementById('creatinina').value = localStorage.getItem('creatinina');
            }
            

            if(localStorage.hasOwnProperty('acidoUrico')){
                document.getElementById('acido').value = localStorage.getItem('acidoUrico');
            }
            

            if(localStorage.hasOwnProperty('sodio')){
                document.getElementById('sodio').value = localStorage.getItem('sodio');
            }
            

            if(localStorage.hasOwnProperty('potasio')){
                document.getElementById('potasio').value = localStorage.getItem('potasio');
            }
            

            if(localStorage.hasOwnProperty('cloro')){
                document.getElementById('cloro').value = localStorage.getItem('cloro');
            }
            

            if(localStorage.hasOwnProperty('calcio')){
                document.getElementById('calcio').value = localStorage.getItem('calcio');
            }
            
        }
    }

    useEffect( () =>  {
        handleLoad();
        window.scrollTo(0, 0);
    }, []);

    const ValNitrogeno = (e) => {
        e.preventDefault();
        var nitrogeno = document.getElementById('nitrogeno').value;
        if (/*nitrogeno.trim().length == 0 || */Number.isInteger(nitrogeno)) {
            document.getElementById('val29').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(nitrogeno < 0 || nitrogeno > 100) {
            document.getElementById('val29').innerHTML = '*Debe ser de 0 a 100.';
        } else {
            document.getElementById('val29').innerHTML = '';
            handleSave();
        }
    }

    const ValCreatinina = (e) => {
        
        var creatinina = document.getElementById('creatinina').value;
        if (/*creatinina.trim().length == 0 || */Number.isInteger(creatinina)) {
            document.getElementById('val30').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(creatinina < 0.00 || creatinina > 5.00) {
            document.getElementById('val30').innerHTML = '*Debe ser de 0.00 a 5.00.';
        } else {
            document.getElementById('val30').innerHTML = '';
            handleSave();
        }
    }

    const ValAcido = (e) => {
        e.preventDefault();
        var acido = document.getElementById('acido').value;
        if (/*acido.trim().length == 0 || */Number.isInteger(acido)) {
            document.getElementById('val31').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(acido < 0.00 || acido > 10.00) {
            document.getElementById('val31').innerHTML = '*Debe ser de 0.00 a 10.00.';
        } else {
            document.getElementById('val31').innerHTML = '';
            handleSave();
        }
    }

    const ValSodio = (e) => {
        e.preventDefault();
        var sodio = document.getElementById('sodio').value;
        if (/*sodio.trim().length == 0 || */Number.isInteger(sodio)) {
            document.getElementById('val32').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(sodio < 0 || sodio > 300) {
            document.getElementById('val32').innerHTML = '*Debe ser de 0 a 300.';
        } else {
            document.getElementById('val32').innerHTML = '';
            handleSave();
        }
    }

    const ValPotasio = (e) => {
        e.preventDefault();
        var potasio = document.getElementById('potasio').value;
        if (/*potasio.trim().length == 0 || */Number.isInteger(potasio)) {
            document.getElementById('val33').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(potasio < 0.00 || potasio > 10.00) {
            document.getElementById('val33').innerHTML = '*Debe ser de 0.00 a 10.00.';
        } else {
            document.getElementById('val33').innerHTML = '';
            handleSave();
        }
    }

    const ValCloro = (e) => {
        e.preventDefault();
        var cloro = document.getElementById('cloro').value;
        if (/*cloro.trim().length == 0 ||*/ Number.isInteger(cloro)) {
            document.getElementById('val34').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(cloro < 0 || cloro > 300) {
            document.getElementById('val34').innerHTML = '*Debe ser de 0 a 300.';
        } else {
            document.getElementById('val34').innerHTML = '';
            handleSave();
        }
    }

    const ValCalcio = (e) => {
        e.preventDefault();
        var calcio = document.getElementById('calcio').value;
        if (/*calcio.trim().length == 0 ||*/ Number.isInteger(calcio)) {
            document.getElementById('val35').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(calcio < 5.00 || calcio > 20.00) {
            document.getElementById('val35').innerHTML = '*Debe ser de 5.00 a 20.00.';
        } else {
            document.getElementById('val35').innerHTML = '';
            handleSave();
        }
    }

    return (
        <div className="container">
            <h4 className="advice">A continuación se le solicitará ingresar valores de exámenes de laboratorio. Sólo ingresar los resultados de examenes de sangre que hayan sido realizados en los últimos 3 meses.</h4>
            <div className="questionForm" style={{marginTop: "21px"}}>
                <div className="questionItem">
                    <p className="encabezado">31. Nitrógeno ureico/urea en sangre (mg/dL)  <i id="val29" className="validation"></i></p>
                    <input id="nitrogeno" type="number" placeholder="20" min="0" max="100" step="1" onChange={(e) => {ValNitrogeno(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">32. Creatinina en sangre (mg/dL)  <i id="val30" className="validation"></i></p>
                    <input id="creatinina" type="number" placeholder="0.98" min="0.00" max="5.00" step="0.01" onChange={(e) => {ValCreatinina(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">33. Acido úrico en sangre (mg/dL)  <i id="val31" className="validation"></i></p>
                    <input id="acido" type="number" placeholder="6.1" min="0.00" max="10.00" step="0.01" onChange={(e) => {ValAcido(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">34. Sodio en sangre (mg/dL)  <i id="val32" className="validation"></i></p>
                    <input id="sodio" type="number" placeholder="137" min="0" max="300" onChange={(e) => {ValSodio(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">35. Potasio en sangre (mg/dL)  <i id="val33" className="validation"></i></p>
                    <input id="potasio" type="number" placeholder="4.1" min="0.00" max="10.00" step="0.01" onChange={(e) => {ValPotasio(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">36. Cloro en sangre (mg/dL)  <i id="val34" className="validation"></i></p>
                    <input id="cloro" type="number" placeholder="102" min="0" max="300" onChange={(e) => {ValCloro(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">37. Calcio en sangre (mg/dL)  <i id="val35" className="validation"></i></p>
                    <input id="calcio" type="number" placeholder="9.7" min="5.00" max="20.00" step="0.01" onChange={(e) => {ValCalcio(e)}}/>
                </div>
            </div>
        </div>
    );
}