import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft, FaPlusCircle} from "react-icons/fa";
import { SendRounded} from "@material-ui/icons";

export default function ExamCalculos() {
  const [rut, setRut] = useState('');
  const [fecha, setFecha] = useState('');
  const [opcion, setOpcion] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [archivo, setArchivo] = useState('');
//! REvisar segunda vez que envia el archivo
  useEffect(() => {
    validateForm();
  }, [rut, fecha, opcion]);

  const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;

    let c = rut.replace("-", "");
    let b = c.charAt(c.length-1);
    let a = c.slice(0,c.length-1);
    document.querySelector('#rut').value = a + "-" + b;

    let rut2 = rut.replace("-", "");
    let n = rut2.charAt(rut2.length-1);
    let rut3 = rut2.slice(0,rut2.length-1);
    
    var M=0,S=1;
    for(;rut3;rut3=Math.floor(rut3/10))
    {
      S=(S+rut3%10*(9-M++%6))%11;
    }
    
    let result = S?S-1:'k';

    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
      setRut(e.target.value);
    }
  }

  const validateForm = () => {
    if (
      rut.trim() !== '' &&
      fecha.trim() !== '' &&
      opcion.trim() !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const Clear = () => {
    const form = document.getElementById('examenes');
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      if (element.type !== 'button') {
        element.value = '';
      }
    }
    const opciones = document.getElementsByName("composicion");
    for (let i = 0; i < opciones.length; i++) {
      if (opciones[i].checked) {
        opciones[i].checked = false;
        break;
      }
    }
    setIsSubmitDisabled(true);
  }

  const Validation = async (e) => {
    e.preventDefault();
    const rut = document.getElementById("rut").value;
    if (rut.trim() === "") {
      alert("Por favor ingresa un RUT");
    } else {
      const fecha = document.getElementById("fecha").value;
      if (fecha === "") {
        alert("Por favor ingresa una Fecha");
      } else {
        const currentDate = new Date().toISOString().split("T")[0];
        if (fecha > currentDate) {
          alert("La fecha no puede ser posterior a la fecha actual");
        } else {
          const opciones = document.getElementsByName("composicion");
          let seleccionado = false;
          for (let i = 0; i < opciones.length; i++) {
            if (opciones[i].checked) {
              Submit(e);
              seleccionado = true;
              break;
            }
          }
          if (!seleccionado) {
            alert("Por favor selecciona una opción");
            return false;
          }
        }
      }
    }
  }

  var new_laboratory;
  let formData = new FormData();
  const Submit = async (e) => {
    e.preventDefault();
    
    formData.append('dni', document.getElementById("rut").value);
    formData.append('date', document.getElementById("fecha").value);

    const elementosRadio = document.querySelectorAll('input[name="composicion"]');
    let valorSeleccionado;
    elementosRadio.forEach((elemento) => {
      if (elemento.checked) {
        valorSeleccionado = elemento.value;
      }
    });

    const answers = [{
      question: 'composition',
      answer: valorSeleccionado,
    }];

    formData.append(`answers[0]question`, 'composition');
    formData.append(`answers[0]answer`, valorSeleccionado);

    
    await Axios.post('/core/laboratories-tests/kidney_stone_comp/', formData, {
      headers: { 
        "content-type": "multipart/form-data",
        'Authorization': "Token " + `${token}`
      },
    }).then((response) => {
      if (response.status === 201 || response.status === 200) {
        alert('Se ha creado correctamente.');
        new_laboratory = response.data.new_laboratory;
        SubmitFile(new_laboratory);
        Clear();
      }   
    }).catch(function (error) {
      if (error.response) {
        alert('Error ' + error.response.status);
      }
    });
  };

  const SubmitFile = async (new_laboratory) => {
    let formData2 = new FormData();
    const fileInput = document.querySelector('input[type="file"]');
    const files = fileInput.files;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData2.append(`exams_files[${i}]file`, file);
      }

      await Axios.post('/core/laboratories-tests/'+new_laboratory+'/files/', formData2, {
        headers: { 
          "content-type": "multipart/form-data",
          'Authorization': "Token " + `${token}`
        },
      }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('Los archivos se han subido correctamente.');
        }   
      }).catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });
    }
  }

  const Enviar = async(new_laboratory) => {
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      //await SubmitFile();
      await Axios.post('/core/laboratories-tests/' + new_laboratory + '/send/', {}, {
        headers: {
          'Authorization': "Token " + token
        }
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('El examen fue enviado correctamente.');
          navigate("/laboratorios/examenes");
        }
      })
      .catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });
    }
  };

  const BotonEnviar = async (e) => {
    e.preventDefault();
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      formData.append("dni", document.getElementById("rut").value);
      formData.append("date", document.getElementById("fecha").value);
    
      const elementosRadio = document.querySelectorAll('input[name="composicion"]');
      let valorSeleccionado;
      elementosRadio.forEach((elemento) => {
        if (elemento.checked) {
          valorSeleccionado = elemento.value;
        }
      });
    
      const answers = [
        {
          question: "composition",
          answer: valorSeleccionado,
        },
      ];
    
      formData.append(`answers[0]question`, "composition");
      formData.append(`answers[0]answer`, valorSeleccionado);
    
      try {
        const response = await Axios.post(
          "/core/laboratories-tests/kidney_stone_comp/",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: "Token " + `${token}`,
            },
          }
        );
    
        if (response.status === 201 || response.status === 200) {
          alert("Se ha creado correctamente.");
          const new_laboratory = response.data.new_laboratory;
          await SubmitFile(new_laboratory);
          await Enviar(new_laboratory);
          Clear();
        }
      } catch (error) {
        if (error.response) {
          alert("Error " + error.response.status);
        }
      }
    }
  };

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios/examenes"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Ingreso de Composición de Cálculo </h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form id="examenes" className="examenesForm">
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange= {RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Fecha</label>
            <input id="fecha" type="date"  required onChange={(e) => setFecha(e.target.value)}/>
          </div>

          <div className="examenesItem" style={{width: "100%"}}></div>          
          
          <div className="questionItem">
            <label>Debe seleccionar las opciones que correspondan:</label>
            <div className="questionaryRadio">
              <input id="otro" name="composicion" type="radio" className="pregunta" value="Otro" required onChange={handleOpcionChange}/>
              <label>Otro</label><br></br>
              <input id="oxalatoCalcio" name="composicion" type="radio" className="pregunta" value="Contiene más de 90% de oxalato de calcio" onChange={handleOpcionChange}/>
              <label>Contiene más de 90% de oxalato de calcio</label><br></br>
              <input id="acidoUrico" name="composicion" type="radio" className="pregunta" value="Contiene más de 15% de ácido úrico" onChange={handleOpcionChange}/>
              <label>Contiene más de 15% de ácido úrico</label><br></br>
              <input id="brushita" name="composicion" type="radio" className="pregunta" value="Contiene más de 10% de calcio dihidratado(brushita)" onChange={handleOpcionChange}/>
              <label>Contiene más de 10% de calcio dihidratado(brushita)</label><br></br>
              <input id="carbapatita" name="composicion" type="radio" className="pregunta" value="Contiene más de 10% de fosato de calcio(carbapatita)" onChange={handleOpcionChange}/>
              <label>Contiene más de 10% de fosato de calcio(carbapatita)</label><br></br>
            </div>
          </div>
          <div className="examenesItem">
            <label>Selecciona los archivos</label>
            <input id="archivo" type="file" style={{width: "450px", backgroundColor: "rgba(255, 255, 255, 1)"}} required multiple onChange={(e) => setArchivo(e.target.value)}/>
          </div>

          <div className="examenesItem" style={{width: "100%"}}></div>

          <button type="button" id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Validation(e)} disabled={isSubmitDisabled}>
            <FaPlusCircle className="reportShowIcon" />Guardar exámen
          </button>
          <button type="button" id="submit" className="examenesButton bigButton alignButton" onClick={(e) => BotonEnviar(e)} disabled={isSubmitDisabled}>
            <SendRounded className="reportShowIcon"/>Enviar exámen
          </button>
        </form>
      </div>
    </div>
  );
}
