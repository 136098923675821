import "./home.css";
import { useContext } from "react";
import { Context } from "../../context/Context";
import Sidebar from "../../components/sidebar/Sidebar";
import Img from '../../img/lab-doctor-performing-medical-exam-of-urine.jpg';

export default function Home() {
  const { user, dispatch } = useContext(Context);
  return (
    <div className="container">
      <Sidebar />
      <div className="home">
        <div className="homeCard">
          <div className="welcome">
            {/* <img src={Logo} alt="iStone Logo" /> */}
            <h1 className="title">Te damos la bienvenida, </h1>
            <h2 className="title">{user.name}</h2>
          </div>
          <img className="home-Img" src={Img} alt="image" />
        </div>
      </div>
    </div>
  );
}
