import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft, FaEdit} from "react-icons/fa";
import { SendRounded} from "@material-ui/icons";

export default function EditExamCalculos() {
  const [rut, setRut] = useState('');
  const [fecha, setFecha] = useState('');
  const [opcion, setOpcion] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  let id = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [archivo, setArchivo] = useState('');

  //! Desbloquear el validador de Rut, bloqueado para pruebas

  useEffect(() => {
    getExam();
  }, []);

  const getExam = async () => {
    try {
      const response = await Axios.get(`/core/laboratories-tests/`+id.id, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Token " + token,
        },
      });
      const obj = response.data;

      document.getElementById("rut").value = obj.dni;
      document.getElementById("rut").disabled = true;

      document.getElementById("fecha").value = obj.date;
      document.getElementById("fecha").disabled = true;

      const filesDiv = document.getElementById("files");
      obj.files.forEach((file) => {

        const label = document.createElement("label");
        const link = document.createElement("a");
        link.href = file.file;
        link.textContent = file.name;
        link.target = "_blank";
        label.appendChild(link);
        filesDiv.appendChild(label);

        const br = document.createElement("br");
        filesDiv.appendChild(br);
      });

      document.getElementById("composicion").innerHTML = obj.values[0].answer;

    } catch (error) {
      console.error(error);
    }
  };


  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
  };


  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios/examenes"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Modificación de Composición de Cálculo </h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form id="examenes" className="examenesForm">
          <div className="examenesItem">
            <label>Rut </label> 
            <input type="text" id="rut"></input>
          </div>
          <div className="examenesItem">
            <label>Fecha</label>
            <input id="fecha" type="date"/>
          </div>

          <div className="examenesItem" style={{width: "100%"}}></div>          
          
          <div className="examenesItem">
            <label>Composición: </label>
            <div id="composicion"></div>
          </div>
          <div className="examenesItem">
            <label>Archivos: </label>
            <div id="files"></div>
          </div>

        </form>
      </div>
    </div>
  );
}
