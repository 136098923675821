import "./App.css";
import { BrowserRouter as Router, Routes, Switch, Route } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./context/Context";
import {Helmet} from 'react-helmet';

import Topbar from "./components/topbar/Topbar";
import Home from "./pages/home/Home";

import Login from "./pages/Auth/login/Login";
import ChangePassword from "./pages/Auth/changePassword/ChangePassword";
import ForgetPassword from "./pages/Auth/recoveryPassword/RecoveryPassword";
import Email from "./pages/Auth/forgetPassword/ForgetPassword";

import ReadUser from "./pages/Usuarios/readUser/ReadUser";
import EditUser from "./pages/Usuarios/editUser/EditUser";
import CreateUser from "./pages/Usuarios/createUser/CreateUser";

import ReadLab from "./pages/Laboratorios/readLab/ReadLab";
import EditLab from "./pages/Laboratorios/editLab/EditLab";
import CreateLab from "./pages/Laboratorios/createLab/CreateLab";

import ReadReport from "./pages/Pacientes/readReport/ReadReport";
import Report from "./pages/Pacientes/report/Report";
import Summary from "./pages/Pacientes/summary/Summary";

import ReadExam from "./pages/Examenes/readExam/ReadExam";
import ReadExamReport from "./pages/Examenes/examReport/ExamReport";

import ExamCalculos from "./pages/Examenes/examCalculos/ExamCalculos";
import ExamOrina from "./pages/Examenes/examOrina/ExamOrina";
import EditExamCalculos from "./pages/Examenes/editExamCalculos/EditExamCalculos";
import EditExamOrina from "./pages/Examenes/editExamOrina/EditExamOrina";
import ViewExamCalculos from "./pages/Examenes/viewExamCalculos/ViewExamCalculos";
import ViewExamOrina from "./pages/Examenes/viewExamOrina/ViewExamOrina";

import Questionary from "./pages/questionary/Questionary";
import Completed from "./pages/questionary/Completed";
import Prediction from "./pages/prediction/Prediction";
import PCompleted from "./pages/prediction/Completed";
import Login2 from "./pages/prediction/Login";

import PacienteReporte from "./pages/Limitado/paciente/Report";
import DoctorReporte from "./pages/Limitado/doctor/Report";
import Retorno from "./pages/Limitado/Retorno";

// Foto de <a href="https://unsplash.com/es/@roberto_sorin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Roberto Sorin</a> en <a href="https://unsplash.com/es/fotos/RS0-h_pyByk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
function App() {
  const { user } = useContext(Context);

  return (
    <Router>
      <Helmet>
        <style>{'body { background-color: #f0f0f0; }'}</style>
      </Helmet>
      <Topbar />
      <Routes>
        <Route exact path="/" element={user ? <Home/> : <Login />}  />
        <Route path="/login" element={user ? <Home/> : <Login />} />
        <Route path="/cambiarContrasena" element={user ? <ChangePassword/> : <Login />} />
        <Route path="/olvideContrasena" element={<Email />} />
        <Route path="/recuperarContrasena" element={<ForgetPassword />} />

        <Route path="/usuarios" element={user ? <ReadUser/> : <Login />} />
        <Route path="/usuarios/nuevoUsuario" element={user ? <CreateUser/> : <Login />} />
        <Route path="/usuarios/editarUsuario/:userId" element={user ? <EditUser/> : <Login />} />

        <Route path="/laboratorios" element={user ? <ReadLab/> : <Login />} />
        <Route path="/laboratorios/nuevoLaboratorio" element={user ? <CreateLab/> : <Login />} />
        <Route path="/laboratorios/editarLaboratorio/:labId" element={user ? <EditLab/> : <Login />} />

        <Route path="/laboratorios/examenes" element={user ? <ReadExam /> : <Login />} />
        <Route path="/laboratorios/examenes/reporte" element={user ? <ReadExamReport /> : <Login />} />
        <Route path="/laboratorios/examenes/nuevaComposicion" element={user ? <ExamCalculos /> : <Login />} />
        <Route path="/laboratorios/examenes/nuevoExamen" element={user ? <ExamOrina /> : <Login />} />
        <Route path="/laboratorios/examenes/composicion/:id" element={user ? <EditExamCalculos /> : <Login />} />
        <Route path="/laboratorios/examenes/orina/:id" element={user ? <EditExamOrina /> : <Login />} />
        <Route path="/laboratorios/examenes/composicion/:id/ver" element={user ? <ViewExamCalculos /> : <Login />} />
        <Route path="/laboratorios/examenes/orina/:id/ver" element={user ? <ViewExamOrina /> : <Login />} />
        
        <Route path="/pacientes" element={user ? <ReadReport /> : <Login />}  />
        <Route path="/pacientes/:id" element={user ? <Report /> : <Login />} />
        <Route path="/pacientes/:id/examenes" element={user ? <Summary /> : <Login />} />
        
        <Route path="/prediccion/login" element={<Login2 />} />
        <Route path="/encuesta" element={<Questionary />} />
        <Route path="/encuesta/completada" element={<Completed />} />
        <Route path="/prediccion" element={<Prediction />} />
        <Route path="/prediccion/completada" element={<PCompleted />} />


        <Route path="/paciente" element={<PacienteReporte />} />
        <Route path="/doctor" element={<DoctorReporte />} />
        <Route path="/gracias" element={<Retorno />}/>
      </Routes>
    </Router>
  );
}

export default App;
