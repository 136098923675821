import "./prediction.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

export default function FourthStep() {
    
    const handleSave = () => {
        var checkRadio17 = document.querySelectorAll('input[name="preg17"]:checked');
        var val17;
        for (var i = 0; i < checkRadio17.length; i++) {
            if (checkRadio17[i].checked == true) {
                val17 = checkRadio17[i].value;
                break;
            }
        }
        localStorage.setItem('cant_calculos', val17);

        if(val17 != 0){
            var checkRadio18_1 = document.querySelectorAll('input[name="preg18-1"]:checked');
            var val18_1;
            for (var i = 0; i < checkRadio18_1.length; i++) {
                if (checkRadio18_1[i].checked == true) {
                    val18_1 = checkRadio18_1[i].value;
                    break;
                }
            }
            localStorage.setItem('oper_endoscopia', val18_1);
    
            var checkRadio18_2 = document.querySelectorAll('input[name="preg18-2"]:checked');
            var val18_2;
            for (var i = 0; i < checkRadio18_2.length; i++) {
                if (checkRadio18_2[i].checked == true) {
                    val18_2 = checkRadio18_2[i].value;
                    break;
                }
            }
            localStorage.setItem('oper_litotripsia', val18_2);
    
            var checkRadio18_3 = document.querySelectorAll('input[name="preg18-3"]:checked');
            var val18_3;
            for (var i = 0; i < checkRadio18_3.length; i++) {
                if (checkRadio18_3[i].checked == true) {
                    val18_3 = checkRadio18_3[i].value;
                    break;
                }
            }
            localStorage.setItem('oper_percutanea', val18_3);
    
            var checkRadio18_4 = document.querySelectorAll('input[name="preg18-4"]:checked');
            var val18_4;
            for (var i = 0; i < checkRadio18_4.length; i++) {
                if (checkRadio18_4[i].checked == true) {
                    val18_4 = checkRadio18_4[i].value;
                    break;
                }
            }
            localStorage.setItem('oper_otra', val18_4);
    
            var checkRadio19 = document.querySelectorAll('input[name="preg19"]:checked');
            var val19;
            for (var i = 0; i < checkRadio19.length; i++) {
                if (checkRadio19[i].checked == true) {
                    val19 = checkRadio19[i].value;
                    break;
                }
            }
            localStorage.setItem('comp_calc', val19);

        }

        var checkRadio20 = document.querySelectorAll('input[name="preg20"]:checked');
        var val20 = [];
        for (var i = 0; i < checkRadio20.length; i++) {
            if (checkRadio20[i].checked == true) {
                val20.push(checkRadio20[i].value);
            }
        }
        localStorage.setItem('cond_salud', val20);

        var checkRadio21 = document.querySelectorAll('input[name="preg21"]:checked');
        var val21;
        for (var i = 0; i < checkRadio21.length; i++) {
            if (checkRadio21[i].checked == true) {
                val21 = checkRadio21[i].value;
                break;
            }
        }
        localStorage.setItem('familiar_calculo', val21);

        var checkRadio22 = document.querySelectorAll('input[name="preg22"]:checked');
        var val22 = [];
        for (var i = 0; i < checkRadio22.length; i++) {
            if (checkRadio22[i].checked == true) {
                val22.push(checkRadio22[i].value);
            }
        }
        localStorage.setItem('medicamentos_vitaminas', val22);

        if(document.getElementById('comentario').value.trim == null){
            localStorage.setItem('comentario', ".");
        } else {
            localStorage.setItem('comentario', document.getElementById('comentario').value);
        }
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {
            if(localStorage.hasOwnProperty('cant_calculos')){
                var checkRadio17 = document.querySelectorAll('input[name="preg17"]');
                for (var i = 0; i < checkRadio17.length; i++) {
                    if (checkRadio17[i].value == (localStorage.getItem('cant_calculos'))) {
                        checkRadio17[i].checked = true;
                        break;
                    }
                }
            }

            if (localStorage.hasOwnProperty('cant_calculos') && localStorage.getItem('cant_calculos') != 0){
                var elements = document.querySelectorAll('[id=preg18]');
                for(var i = 0; i < elements.length; i++) {
                    elements[i].disabled = false;
                }
                if(localStorage.hasOwnProperty('oper_endoscopia')){
                    var checkRadio18_1 = document.querySelectorAll('input[name="preg18-1"]');
                    for (var i = 0; i < checkRadio18_1.length; i++) {
                        if (checkRadio18_1[i].value == (localStorage.getItem('oper_endoscopia'))) {
                            checkRadio18_1[i].checked = true;
                            break;
                        }
                    }
                }
                
    
                if(localStorage.hasOwnProperty('oper_litotripsia')){
                    var checkRadio18_2 = document.querySelectorAll('input[name="preg18-2"]');
                    for (var i = 0; i < checkRadio18_2.length; i++) {
                        if (checkRadio18_2[i].value == (localStorage.getItem('oper_litotripsia'))) {
                            checkRadio18_2[i].checked = true;
                            break;
                        }
                    }
                }
                
    
                if(localStorage.hasOwnProperty('oper_percutanea')){
                    var checkRadio18_3 = document.querySelectorAll('input[name="preg18-3"]');
                    for (var i = 0; i < checkRadio18_3.length; i++) {
                        if (checkRadio18_3[i].value == (localStorage.getItem('oper_percutanea'))) {
                            checkRadio18_3[i].checked = true;
                            break;
                        }
                    }
                }
    
                if(localStorage.hasOwnProperty('oper_otra')){
                    var checkRadio18_4 = document.querySelectorAll('input[name="preg18-4"]');
                    for (var i = 0; i < checkRadio18_4.length; i++) {
                        if (checkRadio18_4[i].value == (localStorage.getItem('oper_otra'))) {
                            checkRadio18_4[i].checked = true;
                            break;
                        }
                    }
                }
            } else {
                var elements = document.querySelectorAll('[id=preg18]');
                for(var i = 0; i < elements.length; i++) {
                    elements[i].disabled = true;
                }
            }
            

            if(localStorage.hasOwnProperty('comp_calc')){
                var checkRadio19 = document.querySelectorAll('input[name="preg19"]');
                for (var i = 0; i < checkRadio19.length; i++) {
                    if (checkRadio19[i].value == (localStorage.getItem('comp_calc'))) {
                        checkRadio19[i].checked = true;
                        break;
                    }
                }
            }
            

            if(localStorage.hasOwnProperty('cond_salud')){
                var checkRadio20 = document.querySelectorAll('input[name="preg20"]');
                var preg20 = localStorage.getItem('cond_salud');
                preg20 = preg20.split(",");
                for (var i = 0; i < checkRadio20.length; i++) {
                    for (let x = 0; x < preg20.length; x++) {
                        if (checkRadio20[i].value == preg20[x]) {
                            checkRadio20[i].checked = true;
                        }
                    }                
                }
            }

            if(localStorage.hasOwnProperty('familiar_calculo')){
                var checkRadio21 = document.querySelectorAll('input[name="preg21"]');
                for (var i = 0; i < checkRadio21.length; i++) {
                    if (checkRadio21[i].value == (localStorage.getItem('familiar_calculo'))) {
                        checkRadio21[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('medicamentos_vitaminas')){
                var checkRadio22 = document.querySelectorAll('input[name="preg22"]');
                var preg22 = localStorage.getItem('medicamentos_vitaminas');
                preg22 = preg22.split(",");
                for (var i = 0; i < checkRadio22.length; i++) {
                    for (let x = 0; x < preg22.length; x++) {
                        if (checkRadio22[i].value == preg22[x]) {
                            checkRadio22[i].checked = true;
                        }
                    }   
                }
            }
            
            if(localStorage.getItem('comentario') != null){
                document.getElementById('comentario').value = localStorage.getItem('comentario');
            }
        }
        valSalud();
        valMedicamento();
        validate();
    }

    const valOperacion = () => {
        if (localStorage.getItem('cant_calculos') != 0){
            var elements = document.querySelectorAll('[id=preg18]');
            for(var i = 0; i < elements.length; i++) {
                elements[i].disabled = false;
            }

            var elements2 = document.querySelectorAll('[id=preg19]');
            for(var i = 0; i < elements2.length; i++) {
                elements2[i].disabled = false;
            }
        } else {
            var elements = document.querySelectorAll('[id=preg18]');
            for(var i = 0; i < elements.length; i++) {
                elements[i].disabled = true;
            }

            var elements2 = document.querySelectorAll('[id=preg19]');
            for(var i = 0; i < elements2.length; i++) {
                elements2[i].disabled = true;
            }
        }
    }

    const valSalud = () => {
        var elements = document.querySelectorAll('[id=preg20]');
        if(localStorage.hasOwnProperty('cond_salud')){
            if (localStorage.getItem('cond_salud').includes("Ninguna de las anteriores")){
                for(var i = 1; i < elements.length; i++) {
                    elements[i].disabled = true;
                }
            } else {
                for(var i = 1; i < elements.length; i++) {
                    elements[i].disabled = false;
                }
            }
        }
    }

    const valMedicamento = () => {
        var elements = document.querySelectorAll('[id=preg22]');
        if(localStorage.hasOwnProperty('cond_salud')){
            if (localStorage.getItem('medicamentos_vitaminas').includes("Ninguno de los anteriores")){
                for(var i = 1; i < elements.length; i++) {
                    elements[i].disabled = true;
                }
            } else {
                for(var i = 1; i < elements.length; i++) {
                    elements[i].disabled = false;
                }
            }
        }
    }

    useEffect( () =>  {
        handleLoad();
        window.scrollTo(0, 0);
    }, []);

    const validate = () => {
        var checkRadio17 = document.querySelectorAll('input[name="preg17"]:checked');
        if (checkRadio17.length == 0) {
            document.getElementById('val17').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val17').innerHTML = '';
            handleSave();
        }

        if (localStorage.hasOwnProperty('cant_calculos') && localStorage.getItem('cant_calculos') != 0){
            var checkRadio18_1 = document.querySelectorAll('input[name="preg18-1"]:checked');
            if (checkRadio18_1.length == 0) {
                document.getElementById('val18').innerHTML = '*Obligatorio';
            } else {
                document.getElementById('val18').innerHTML = '';
                handleSave();
            }

            var checkRadio18_2 = document.querySelectorAll('input[name="preg18-2"]:checked');
            if (checkRadio18_2.length == 0) {
                document.getElementById('val18').innerHTML = '*Obligatorio';
            } else {
                document.getElementById('val18').innerHTML = '';
                handleSave();
            }

            var checkRadio18_3 = document.querySelectorAll('input[name="preg18-3"]:checked');
            if (checkRadio18_3.length == 0) {
                document.getElementById('val18').innerHTML = '*Obligatorio';
            } else {
                document.getElementById('val18').innerHTML = '';
                handleSave();
            }

            var checkRadio18_4 = document.querySelectorAll('input[name="preg18-4"]:checked');
            if (checkRadio18_4.length == 0) {
                document.getElementById('val18').innerHTML = '*Obligatorio';
            } else {
                document.getElementById('val18').innerHTML = '';
                handleSave();
            }

            var checkRadio19 = document.querySelectorAll('input[name="preg19"]:checked');
            if (checkRadio19.length == 0) {
                document.getElementById('val19').innerHTML = '*Obligatorio';
            } else {
                document.getElementById('val19').innerHTML = '';
                handleSave();
            }
        } else {
            document.getElementById('val18').innerHTML = '';
            document.getElementById('val19').innerHTML = '';
        }

        

        var checkRadio20 = document.querySelectorAll('input[name="preg20"]:checked');
        if (checkRadio20.length == 0) {
            document.getElementById('val20').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val20').innerHTML = '';
            handleSave();
        }

        var checkRadio21 = document.querySelectorAll('input[name="preg21"]:checked');
        if (checkRadio21.length == 0) {
            document.getElementById('val21').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val21').innerHTML = '';
            handleSave();
        }

        var checkRadio22 = document.querySelectorAll('input[name="preg22"]:checked');
        if (checkRadio22.length == 0) {
            document.getElementById('val22').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val22').innerHTML = '';
            handleSave();
        }

    }

    return (
        <div className="container">
            <div className="questionForm">
                <div className="questionItem">
                    <p className="encabezado">19. ¿Cuántas veces en su vida ha tenido cálculos renales/cólico renal? <i id="val17" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="0" required onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="a">Nunca tuve</label> <br></br>
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="1" onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="b">1 vez</label><br></br>
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="2" onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="c">2 veces</label> <br></br>
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="3" onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="d">3 veces</label> <br></br>
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="4" onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="e">4 veces</label> <br></br>
                        <input type="radio" name="preg17" id="preg17" className="pregunta" value="5" onChange={(e) => {validate(); valOperacion();}}/>
                        <label for="d">5 o más</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">20. Si fuiste operado de cálculos renales, ¿qué tipo de cirugías fueron? <i id="val18" className="validation"></i></p>
                    <div className="questionMultiple">
                        <table>
                            <tr>
                                <td></td>
                                <td><label>Ninguna</label></td>
                                <td><label>1</label></td>
                                <td><label>2</label></td>
                                <td><label>3</label></td>
                                <td><label>4</label></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Endoscópica (Ureteroscopía)</label></td>
                                <td><input type="radio" name="preg18-1" id="preg18" className="pregunta" value="0" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-1" id="preg18" className="pregunta" value="1" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-1" id="preg18" className="pregunta" value="2" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-1" id="preg18" className="pregunta" value="3" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-1" id="preg18" className="pregunta" value="4" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Litotripsia extracorpórea</label></td>
                                <td><input type="radio" name="preg18-2" id="preg18" className="pregunta" value="0" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-2" id="preg18" className="pregunta" value="1" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-2" id="preg18" className="pregunta" value="2" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-2" id="preg18" className="pregunta" value="3" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-2" id="preg18" className="pregunta" value="4" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Percutánea</label></td>
                                <td><input type="radio" name="preg18-3" id="preg18" className="pregunta" value="0" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-3" id="preg18" className="pregunta" value="1" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-3" id="preg18" className="pregunta" value="2" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-3" id="preg18" className="pregunta" value="3" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-3" id="preg18" className="pregunta" value="4" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Otra</label></td>
                                <td><input type="radio" name="preg18-4" id="preg18" className="pregunta" value="0" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-4" id="preg18" className="pregunta" value="1" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-4" id="preg18" className="pregunta" value="2" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-4" id="preg18" className="pregunta" value="3" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg18-4" id="preg18" className="pregunta" value="4" onChange={(e) => {validate();}}/></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">21. En caso de haber sido estudiada la composición de calculo, indique cual opción es la que mayormente se asemeja al último analizado:
                    <i id="val19" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="radio" name="preg19" id="preg19" className="pregunta" value="Otra/No recuerdo/No Aplica" required onChange={(e) => {validate();}}/>
                        <label for="a">Nunca se ha estudiado/No recuerdo/Otra composición</label> <br></br>
                        <input type="radio" name="preg19" id="preg19" className="pregunta" value="Contiene más del 90% de Oxalato de calcio" onChange={(e) => {validate();}}/>
                        <label for="b">Contiene más del 90% de oxalato de calcio</label><br></br>
                        <input type="radio" name="preg19" id="preg19" className="pregunta" value="Contiene más del 15% de Ácido úrico" onChange={(e) => {validate();}}/>
                        <label for="c">Contiene más del 15% de ácido úrico</label> <br></br>
                        <input type="radio" name="preg19" id="preg19" className="pregunta" value="Contiene más del 10% de Fosfato de calcio dihidratado (brushita)" onChange={(e) => {validate();}}/>
                        <label for="d">Contiene más del 10% de fosfato de calcio dihidratado (brushita)</label> <br></br>
                        <input type="radio" name="preg19" id="preg19" className="pregunta" value="Contiene más del 10% de Fosfato de calcio (carbapatita)" onChange={(e) => {validate();}}/>
                        <label for="e">Contiene más del 10% de fosfato de calcio (carbapatita)</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">22. ¿Tiene alguna de las siguientes condiciones de salud? <i id="val20" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Ninguna de las anteriores" onChange={(e) => {validate(); valSalud();}}/>
                        <label for="j">Ninguna</label><br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Infección urinaria recurrente" required onChange={(e) => {validate();}}/>
                        <label for="a">Infección urinaria recurrente</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Diabetes mellitus" onChange={(e) => {validate();}}/>
                        <label for="b">Diabetes mellitus</label><br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Hipertensión arterial" onChange={(e) => {validate();}}/>
                        <label for="c">Hipertensión arterial</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Insuficiencia renal" onChange={(e) => {validate();}}/>
                        <label for="d">Insuficiencia renal</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Enfermedad Inmunológica" onChange={(e) => {validate();}}/>
                        <label for="e">Enfermedad Inmunológica</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Cáncer" onChange={(e) => {validate();}}/>
                        <label for="f">Cáncer</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Gota" onChange={(e) => {validate();}}/>
                        <label for="g">Gota</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Enfermedad inflamatoria intestinal (Enfermedad de Crohn o colitis ulcerosa)" onChange={(e) => {validate();}}/>
                        <label for="h">Enfermedad inflamatoria intestinal (Enfermedad de Crohn o colitis ulcerosa)</label> <br></br>
                        <input type="checkbox" name="preg20" id="preg20" className="pregunta" value="Cirugía bariátrica (bypass gástrico o manga gástrica)" onChange={(e) => {validate();}}/>
                        <label for="i">Cirugía bariátrica (bypass gástrico o manga gástrica)</label> 
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">23. ¿Tiene familiares directos (padres o hermanos) que tuvieron cálculos renales? <i id="val21" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="radio" name="preg21" id="preg21" className="pregunta" value="Si" required onChange={(e) => {validate();}}/>
                        <label for="a">Sí</label> <br></br>
                        <input type="radio" name="preg21" id="preg21" className="pregunta" value="No" onChange={(e) => {validate();}}/>
                        <label for="b">No</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">24. ¿Toma alguno de los medicamentos/vitaminas de la siguiente lista? <i id="val22" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Ninguno de los anteriores" onChange={(e) => {validate(); valMedicamento();}}/>
                        <label for="m">Ninguno</label><br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Vitamina C" required onChange={(e) => {validate(); }}/>
                        <label for="a">Vitamina C</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Vitamina D" onChange={(e) => {validate(); }}/>
                        <label for="b">Vitamina D</label><br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Vitamina B6" onChange={(e) => {validate(); }}/>
                        <label for="c">Vitamina B6</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Calcio" onChange={(e) => {validate(); }}/>
                        <label for="d">Calcio</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Hidroclorotiazida; Clortalidona; Indapamida" onChange={(e) => {validate(); }}/>
                        <label for="e">Hidroclorotiazida, Clortalidona, Indapamida</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Furosemida" onChange={(e) => {validate(); }}/>
                        <label for="f">Furosemida</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Diuréticos" onChange={(e) => {validate(); }}/>
                        <label for="g">Diuréticos</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Laxantes" onChange={(e) => {validate(); }}/>
                        <label for="h">Laxantes</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Citrato de Potasio" onChange={(e) => {validate(); }}/>
                        <label for="i">Citrato de Potasio (Kanacitrin, Uroalkine, etc)</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Chancapiedra" onChange={(e) => {validate(); }}/>
                        <label for="j">Chancapiedra</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Corticoides" onChange={(e) => {validate(); }}/>
                        <label for="k">Corticoides (Prednisona)</label> <br></br>
                        <input type="checkbox" name="preg22" id="preg22" className="pregunta" value="Anticonvulsivantes" onChange={(e) => {validate(); }}/>
                        <label for="l">Anticonvulsivantes</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">25. ¿Le gustaría dejarnos algún comentario o aclaración de algún punto? </p>
                    <input id="comentario" type="text" placeholder="Tu respuesta" maxLength="200" onChange={(e) => {handleSave()}}/>
                </div>
            </div>
        </div>
    );
}