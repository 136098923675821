import "./prediction.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

export default function FifthStep() {

    const handleSave = () => {
        var checkRadio25 = document.querySelectorAll('input[name="preg25"]:checked');
        var val25;
        for (var i = 0; i < checkRadio25.length; i++) {
            if (checkRadio25[i].checked == true) {
                val25 = checkRadio25[i].value;
                break;
            }
        }

        if(val25 != "undefined"){
            localStorage.setItem('aspecto', val25);
        }

        localStorage.setItem('densidad',  document.getElementById('densidad').value);
        localStorage.setItem('ph', document.getElementById('ph').value);

        var checkRadio28 = document.querySelectorAll('input[name="preg28"]:checked');
        var val28;
        for (var i = 0; i < checkRadio28.length; i++) {
            if (checkRadio28[i].checked == true) {
                val28 = checkRadio28[i].value;
                break;
            }
        }
        
        if(val28 != "undefined"){
            localStorage.setItem('hematies', val28);
        }

        var checkRadio29 = document.querySelectorAll('input[name="preg29"]:checked');
        var val29;
        for (var i = 0; i < checkRadio29.length; i++) {
            if (checkRadio29[i].checked == true) {
                val29 = checkRadio29[i].value;
                break;
            }
        }
        
        if(val29 != "undefined"){
            localStorage.setItem('cristales', val29);
        }
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {
            if(localStorage.hasOwnProperty('aspecto')){
                var checkRadio25 = document.querySelectorAll('input[name="preg25"]');
                for (var i = 0; i < checkRadio25.length; i++) {
                    if (checkRadio25[i].value == (localStorage.getItem('aspecto'))) {
                        checkRadio25[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('densidad')){
                document.getElementById('densidad').value = localStorage.getItem('densidad');
            }
            

            if(localStorage.hasOwnProperty('ph')){
                document.getElementById('ph').value = localStorage.getItem('ph');
            }
            
            if(localStorage.hasOwnProperty('hematies')){
                var checkRadio28 = document.querySelectorAll('input[name="preg28"]');
                for (var i = 0; i < checkRadio25.length; i++) {
                    if (checkRadio28[i].value == (localStorage.getItem('hematies'))) {
                        checkRadio28[i].checked = true;
                        break;
                    }
                }
            }
            

            if(localStorage.hasOwnProperty('cristales')){
                var checkRadio29 = document.querySelectorAll('input[name="preg29"]');
                for (var i = 0; i < checkRadio29.length; i++) {
                    if (checkRadio29[i].value == (localStorage.getItem('cristales'))) {
                        checkRadio29[i].checked = true;
                        break;
                    }
                }
            }
            
        }
        validate();
    }

    useEffect( () =>  {
        handleLoad();
        window.scrollTo(0, 0);
    }, []);

    const validate = () => {
        var checkRadio25 = document.querySelectorAll('input[name="preg25"]:checked');
        if (checkRadio25.length == 0) {
            //document.getElementById('val25').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val25').innerHTML = '';
            handleSave();
        }

        var checkRadio28 = document.querySelectorAll('input[name="preg28"]:checked');
        if (checkRadio28.length == 0) {
            //document.getElementById('val28').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val28').innerHTML = '';
            handleSave();
        }

        var checkRadio29 = document.querySelectorAll('input[name="preg29"]:checked');
        if (checkRadio29.length == 0) {
            //document.getElementById('val29').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val29').innerHTML = '';
            handleSave();
        }
    }

    const ValDensidad = (e) => {
        e.preventDefault();
        var densidad = document.getElementById('densidad').value;
        if (/*densidad.trim().length == 0 ||*/ Number.isInteger(densidad)) {
            document.getElementById('val26').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(densidad < 1000 || densidad > 1200) {
            document.getElementById('val26').innerHTML = '*Debe ser de 1000 a 1200.';
        } else {
            document.getElementById('val26').innerHTML = '';
            handleSave();
        }
    }

    const ValPh= (e) => {
        e.preventDefault();
        var ph = document.getElementById('ph').value;
        if (/*ph.trim().length == 0 ||*/ Number.isInteger(ph)) {
            document.getElementById('val27').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        } else if(ph < 4.0 || ph > 9.0) {
            document.getElementById('val27').innerHTML = '*Debe ser de 4.0 a 9.0.';
        } else {
            document.getElementById('val27').innerHTML = '';
            handleSave();
        }
    }


    return (
        <div className="container">
            <h4 className="advice">A continuación se le solicitará ingresar valores de exámenes de laboratorio. Sólo ingresar los resultados de examenes de orina que hayan sido realizados en los últimos 3 meses.</h4>
            <div className="questionForm" style={{marginTop: "21px"}}>
                <div className="questionItem">
                <p className="encabezado">26. Aspecto <i id="val25" className="validation"></i></p>
                <div className="questionaryRadio">
                    <input type="radio" name="preg25" id="preg25" className="pregunta" value="No se sabe" onChange={(e) => {validate();}}/>
                    <label for="d">No se sabe</label><br></br>
                    <input type="radio" name="preg25" id="preg25" className="pregunta" value="Transparente" onChange={(e) => {validate();}}/>
                    <label for="a">Transparente</label> <br></br>
                    <input type="radio" name="preg25" id="preg25" className="pregunta" value="Ligeramente turbio" onChange={(e) => {validate();}}/>
                    <label for="b">Ligeramente turbio</label> <br></br>
                    <input type="radio" name="preg25" id="preg25" className="pregunta" value="Turbio" onChange={(e) => {validate();}}/>
                    <label for="c">Turbio</label> <br></br>
                </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">27. Densidad  (mg/dL) <i id="val26" className="validation"></i></p>
                    <input id="densidad" type="number" placeholder="1015" min="1000" max="1200" onChange={(e) => {ValDensidad(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">28. pH  <i id="val27" className="validation"></i></p>
                    <input id="ph" type="number" placeholder="6.8" min="4.0" max="9.0" step="0.1" onChange={(e) => {ValPh(e)}}/>
                </div>
                <div className="questionItem">
                    <p className="encabezado">29. Hematies (Glóbulos rojos) <i id="val28" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg28" id="preg28" className="pregunta" value="No se observan" onChange={(e) => {validate();}}/>
                        <label for="a">No se observan</label> <br></br>
                        <input type="radio" name="preg28" id="preg28" className="pregunta" value="Escasos" onChange={(e) => {validate();}}/>
                        <label for="b">Escasos</label> <br></br>
                        <input type="radio" name="preg28" id="preg28" className="pregunta" value="Regulares" onChange={(e) => {validate();}}/>
                        <label for="c">Regulares</label> <br></br>
                        <input type="radio" name="preg28" id="preg28" className="pregunta" value="Abundantes" onChange={(e) => {validate();}}/>
                        <label for="d">Abundantes</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">30. Cristales <i id="val29" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg29" id="preg29" className="pregunta" value="No se observan" onChange={(e) => {validate();}}/>
                        <label for="a">No se observan</label> <br></br>
                        <input type="radio" name="preg29" id="preg29" className="pregunta" value="Escasos" onChange={(e) => {validate();}}/>
                        <label for="b">Escasos</label> <br></br>
                        <input type="radio" name="preg29" id="preg29" className="pregunta" value="Regulares" onChange={(e) => {validate();}}/>
                        <label for="c">Regulares</label> <br></br>
                        <input type="radio" name="preg29" id="preg29" className="pregunta" value="Abundantes" onChange={(e) => {validate();}}/>
                        <label for="d">Abundantes</label>
                    </div>
                </div>
            </div>
        </div>
    );
}