import "./prediction.css";
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import $ from 'jquery';

export default function Completed() {

  return (
    <div className="container">
      <div className="completed">
        <h3>La predicción fue completada exitosamente.</h3>
        <h2>Se ha enviado la predicción</h2>
      </div>
    </div>
  );
}
