import "./questionary.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

export default function ThirdStep() {
    const handleSave = () => {
        var checkRadio5_1 = document.querySelectorAll('input[name="preg5-1"]:checked');
        var val5_1;
        for (var i = 0; i < checkRadio5_1.length; i++) {
            if (checkRadio5_1[i].checked == true) {
                val5_1 = checkRadio5_1[i].value;
                break;
            }
        }
        localStorage.setItem('frutas', val5_1);

        var checkRadio5_2 = document.querySelectorAll('input[name="preg5-2"]:checked');
        var val5_2;
        for (var i = 0; i < checkRadio5_2.length; i++) {
            if (checkRadio5_2[i].checked == true) {
                val5_2 = checkRadio5_2[i].value;
                break;
            }
        }
        localStorage.setItem('verduras', val5_2);

        var checkRadio5_3 = document.querySelectorAll('input[name="preg5-3"]:checked');
        var val5_3;
        for (var i = 0; i < checkRadio5_3.length; i++) {
            if (checkRadio5_3[i].checked == true) {
                val5_3 = checkRadio5_3[i].value;
                break;
            }
        }
        localStorage.setItem('lacteos', val5_3);

        var checkRadio6_1 = document.querySelectorAll('input[name="preg6-1"]:checked');
        var val6_1;
        for (var i = 0; i < checkRadio6_1.length; i++) {
            if (checkRadio6_1[i].checked == true) {
                val6_1 = checkRadio6_1[i].value;
                break;
            }
        }
        localStorage.setItem('pescado', val6_1);

        var checkRadio6_2 = document.querySelectorAll('input[name="preg6-2"]:checked');
        var val6_2;
        for (var i = 0; i < checkRadio6_2.length; i++) {
            if (checkRadio6_2[i].checked == true) {
                val6_2 = checkRadio6_2[i].value;
                break;
            }
        }
        localStorage.setItem('cerdo', val6_2);

        var checkRadio6_3 = document.querySelectorAll('input[name="preg6-3"]:checked');
        var val6_3;
        for (var i = 0; i < checkRadio6_3.length; i++) {
            if (checkRadio6_3[i].checked == true) {
                val6_3 = checkRadio6_3[i].value;
                break;
            }
        }
        localStorage.setItem('pollo', val6_3);

        var checkRadio6_4 = document.querySelectorAll('input[name="preg6-4"]:checked');
        var val6_4;
        for (var i = 0; i < checkRadio6_4.length; i++) {
            if (checkRadio6_4[i].checked == true) {
                val6_4 = checkRadio6_4[i].value;
                break;
            }
        }
        localStorage.setItem('vacuno', val6_4);

        var checkRadio7 = document.querySelectorAll('input[name="preg7"]:checked');
        var val7;
        for (var i = 0; i < checkRadio7.length; i++) {
            if (checkRadio7[i].checked == true) {
                val7 = checkRadio7[i].value;
                break;
            }
        }
        localStorage.setItem('tamano_porcion_vacuna', val7);

        var checkRadio8 = document.querySelectorAll('input[name="preg8"]:checked');
        var val8;
        for (var i = 0; i < checkRadio8.length; i++) {
            if (checkRadio8[i].checked == true) {
                val8 = checkRadio8[i].value;
                break;
            }
        }
        localStorage.setItem('sal', val8);

        var checkRadio9 = document.querySelectorAll('input[name="preg9"]:checked');
        var val9;
        for (var i = 0; i < checkRadio9.length; i++) {
            if (checkRadio9[i].checked == true) {
                val9 = checkRadio9[i].value;
                break;
            }
        }
        localStorage.setItem('conservas_sopas_caldos', val9);

        var checkRadio10 = document.querySelectorAll('input[name="preg10"]:checked');
        var val10;
        for (var i = 0; i < checkRadio10.length; i++) {
            if (checkRadio10[i].checked == true) {
                val10 = checkRadio10[i].value;
                break;
            }
        }
        localStorage.setItem('procesados', val10);

        var checkRadio11_1 = document.querySelectorAll('input[name="preg11-1"]:checked');
        var val11_1;
        for (var i = 0; i < checkRadio11_1.length; i++) {
            if (checkRadio11_1[i].checked == true) {
                val11_1 = checkRadio11_1[i].value;
                break;
            }
        }
        localStorage.setItem('cerveza', val11_1);

        var checkRadio11_2 = document.querySelectorAll('input[name="preg11-2"]:checked');
        var val11_2;
        for (var i = 0; i < checkRadio11_2.length; i++) {
            if (checkRadio11_2[i].checked == true) {
                val11_2 = checkRadio11_2[i].value;
                break;
            }
        }
        localStorage.setItem('destilados', val11_2);

        var checkRadio11_3 = document.querySelectorAll('input[name="preg11-3"]:checked');
        var val11_3;
        for (var i = 0; i < checkRadio11_3.length; i++) {
            if (checkRadio11_3[i].checked == true) {
                val11_3 = checkRadio11_3[i].value;
                break;
            }
        }
        localStorage.setItem('vino_otros', val11_3);

        var checkRadio12 = document.querySelectorAll('input[name="preg12"]:checked');
        var val12;
        for (var i = 0; i < checkRadio12.length; i++) {
            if (checkRadio12[i].checked == true) {
                val12 = checkRadio12[i].value;
                break;
            }
        }

        if (val12 != "Otro") {
            localStorage.setItem('dieta', val12);
        } else {
            localStorage.setItem('dieta', val12 +":" + document.getElementById('otro').value);
        }

        var checkRadio13 = document.querySelectorAll('input[name="preg13"]:checked');
        var val13;
        for (var i = 0; i < checkRadio13.length; i++) {
            if (checkRadio13[i].checked == true) {
                val13 = checkRadio13[i].value;
                break;
            }
        }
        localStorage.setItem('aumento_peso', val13);

        var checkRadio14 = document.querySelectorAll('input[name="preg14"]:checked');
        var val14;
        for (var i = 0; i < checkRadio14.length; i++) {
            if (checkRadio14[i].checked == true) {
                val14 = checkRadio14[i].value;
                break;
            }
        }
        localStorage.setItem('aumento_kg', val14);

        var checkRadio15 = document.querySelectorAll('input[name="preg15"]:checked');
        var val15;
        for (var i = 0; i < checkRadio15.length; i++) {
            if (checkRadio15[i].checked == true) {
                val15 = checkRadio15[i].value;
                break;
            }
        }
        localStorage.setItem('cigarrillos', val15);

        var checkRadio16 = document.querySelectorAll('input[name="preg16"]:checked');
        var val16;
        for (var i = 0; i < checkRadio16.length; i++) {
            if (checkRadio16[i].checked == true) {
                val16 = checkRadio16[i].value;
                break;
            }
        }
        localStorage.setItem('actividad_fisica', val16);
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {

            if(localStorage.hasOwnProperty('frutas')){
                var checkRadio5_1 = document.querySelectorAll('input[name="preg5-1"]');
                for (var i = 0; i < checkRadio5_1.length; i++) {
                    if (checkRadio5_1[i].value == (localStorage.getItem('frutas'))) {
                        checkRadio5_1[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('verduras')){
                var checkRadio5_2 = document.querySelectorAll('input[name="preg5-2"]');
                for (var i = 0; i < checkRadio5_2.length; i++) {
                    if (checkRadio5_2[i].value == (localStorage.getItem('verduras'))) {
                        checkRadio5_2[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('lacteos')){
                var checkRadio5_3 = document.querySelectorAll('input[name="preg5-3"]');
                for (var i = 0; i < checkRadio5_3.length; i++) {
                    if (checkRadio5_3[i].value == (localStorage.getItem('lacteos'))) {
                        checkRadio5_3[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('pescado')){
                var checkRadio6_1 = document.querySelectorAll('input[name="preg6-1"]');
                for (var i = 0; i < checkRadio6_1.length; i++) {
                    if (checkRadio6_1[i].value == (localStorage.getItem('pescado'))) {
                        checkRadio6_1[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('cerdo')){
                var checkRadio6_2 = document.querySelectorAll('input[name="preg6-2"]');
                for (var i = 0; i < checkRadio6_2.length; i++) {
                    if (checkRadio6_2[i].value == (localStorage.getItem('cerdo'))) {
                        checkRadio6_2[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('pollo')){
                var checkRadio6_3 = document.querySelectorAll('input[name="preg6-3"]');
                for (var i = 0; i < checkRadio6_3.length; i++) {
                    if (checkRadio6_3[i].value == (localStorage.getItem('pollo'))) {
                        checkRadio6_3[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('vacuno')){
                var checkRadio6_4 = document.querySelectorAll('input[name="preg6-4"]');
                for (var i = 0; i < checkRadio6_4.length; i++) {
                    if (checkRadio6_4[i].value == (localStorage.getItem('vacuno'))) {
                        checkRadio6_4[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('tamano_porcion_vacuna')){
                var checkRadio7 = document.querySelectorAll('input[name="preg7"]');
                for (var i = 0; i < checkRadio7.length; i++) {
                    if (checkRadio7[i].value == (localStorage.getItem('tamano_porcion_vacuna'))) {
                        checkRadio7[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('sal')){
                var checkRadio8 = document.querySelectorAll('input[name="preg8"]');
                for (var i = 0; i < checkRadio8.length; i++) {
                    if (checkRadio8[i].value == (localStorage.getItem('sal'))) {
                        checkRadio8[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('conservas_sopas_caldos')){
                var checkRadio9 = document.querySelectorAll('input[name="preg9"]');
                for (var i = 0; i < checkRadio9.length; i++) {
                    if (checkRadio9[i].value == (localStorage.getItem('conservas_sopas_caldos'))) {
                        checkRadio9[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('procesados')){
                var checkRadio10 = document.querySelectorAll('input[name="preg10"]');
                for (var i = 0; i < checkRadio10.length; i++) {
                    if (checkRadio10[i].value == (localStorage.getItem('procesados'))) {
                        checkRadio10[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('cerveza')){
                var checkRadio11_1 = document.querySelectorAll('input[name="preg11-1"]');
                for (var i = 0; i < checkRadio11_1.length; i++) {
                    if (checkRadio11_1[i].value == (localStorage.getItem('cerveza'))) {
                        checkRadio11_1[i].checked = true;
                        break;
                    }
                }
            }
            
            if(localStorage.hasOwnProperty('destilados')){
                var checkRadio11_2 = document.querySelectorAll('input[name="preg11-2"]');
                for (var i = 0; i < checkRadio11_2.length; i++) {
                    if (checkRadio11_2[i].value == (localStorage.getItem('destilados'))) {
                        checkRadio11_2[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('vino_otros')){
                var checkRadio11_3 = document.querySelectorAll('input[name="preg11-3"]');
                for (var i = 0; i < checkRadio11_3.length; i++) {
                    if (checkRadio11_3[i].value == (localStorage.getItem('vino_otros'))) {
                        checkRadio11_3[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('dieta')){
                var checkRadio12 = document.querySelectorAll('input[name="preg12"]');
                for (var i = 0; i < checkRadio12.length; i++) {
                    if (checkRadio12[i].value == (localStorage.getItem('dieta')) || checkRadio12[i].value.includes("Otro")) {
                        checkRadio12[i].checked = true;
                        break;
                    }
                }
                
                if (localStorage.getItem('dieta').includes("Otro"))
                {
                    let text = localStorage.getItem('dieta'); 
                    document.getElementById('otro').value = text.replace("Otro:", "");
                }
            }

            if(localStorage.hasOwnProperty('aumento_peso')){
                var checkRadio13 = document.querySelectorAll('input[name="preg13"]');
                for (var i = 0; i < checkRadio13.length; i++) {
                    if (checkRadio13[i].value == (localStorage.getItem('aumento_peso'))) {
                        checkRadio13[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('aumento_kg')){
                var checkRadio14 = document.querySelectorAll('input[name="preg14"]');
                for (var i = 0; i < checkRadio14.length; i++) {
                    if (checkRadio14[i].value == (localStorage.getItem('aumento_kg'))) {
                        checkRadio14[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('cigarrillos')){
                var checkRadio15 = document.querySelectorAll('input[name="preg15"]');
                for (var i = 0; i < checkRadio15.length; i++) {
                    if (checkRadio15[i].value == (localStorage.getItem('cigarrillos'))) {
                        checkRadio15[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('actividad_fisica')){
                var checkRadio16 = document.querySelectorAll('input[name="preg16"]');
                for (var i = 0; i < checkRadio16.length; i++) {
                    if (checkRadio16[i].value == (localStorage.getItem('actividad_fisica'))) {
                        checkRadio16[i].checked = true;
                        break;
                    }
                }
            }
        }

        validate();
    }

    useEffect( () =>  {
        handleLoad();
        window.scrollTo(0, 0);
    }, []);

    const validate = () => {
        var checkRadio5_1 = document.querySelectorAll('input[name="preg5-1"]:checked');
        if (checkRadio5_1.length == 0) {
            document.getElementById('val5').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val5').innerHTML = '';
            handleSave();
        }

        var checkRadio5_2 = document.querySelectorAll('input[name="preg5-2"]:checked');
        if (checkRadio5_2.length == 0) {
            document.getElementById('val5').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val5').innerHTML = '';
            handleSave();
        }

        var checkRadio5_3 = document.querySelectorAll('input[name="preg5-3"]:checked');
        if (checkRadio5_3.length == 0) {
            document.getElementById('val5').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val5').innerHTML = '';
            handleSave();
        }

        var checkRadio6_1 = document.querySelectorAll('input[name="preg6-1"]:checked');
        if (checkRadio6_1.length == 0) {
            document.getElementById('val6').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val6').innerHTML = '';
            handleSave();
        }

        var checkRadio6_2 = document.querySelectorAll('input[name="preg6-2"]:checked');
        if (checkRadio6_2.length == 0) {
            document.getElementById('val6').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val6').innerHTML = '';
            handleSave();
        }

        var checkRadio6_3 = document.querySelectorAll('input[name="preg6-3"]:checked');
        if (checkRadio6_3.length == 0) {
            document.getElementById('val6').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val6').innerHTML = '';
            handleSave();
        }

        var checkRadio6_4 = document.querySelectorAll('input[name="preg6-4"]:checked');
        if (checkRadio6_4.length == 0) {
            document.getElementById('val6').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val6').innerHTML = '';
            handleSave();
        }

        var checkRadio7 = document.querySelectorAll('input[name="preg7"]:checked');
        if (checkRadio7.length == 0) {
            document.getElementById('val7').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val7').innerHTML = '';
            handleSave();
        }

        var checkRadio8 = document.querySelectorAll('input[name="preg8"]:checked');
        if (checkRadio8.length == 0) {
            document.getElementById('val8').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val8').innerHTML = '';
            handleSave();
        }

        var checkRadio9 = document.querySelectorAll('input[name="preg9"]:checked');
        if (checkRadio9.length == 0) {
            document.getElementById('val9').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val9').innerHTML = '';
            handleSave();
        }

        var checkRadio10 = document.querySelectorAll('input[name="preg10"]:checked');
        if (checkRadio10.length == 0) {
            document.getElementById('val10').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val10').innerHTML = '';
            handleSave();
        }

        var checkRadio11_1 = document.querySelectorAll('input[name="preg11-1"]:checked');
        if (checkRadio11_1.length == 0) {
            document.getElementById('val11').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val11').innerHTML = '';
            handleSave();
        }

        var checkRadio11_2 = document.querySelectorAll('input[name="preg11-2"]:checked');
        if (checkRadio11_2.length == 0) {
            document.getElementById('val11').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val11').innerHTML = '';
            handleSave();
        }

        var checkRadio11_3 = document.querySelectorAll('input[name="preg11-3"]:checked');
        if (checkRadio11_3.length == 0) {
            document.getElementById('val11').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val11').innerHTML = '';
            handleSave();
        }

        var checkRadio12 = document.querySelectorAll('input[name="preg12"]:checked');
        if (checkRadio12.length == 0) {
            document.getElementById('val12').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val12').innerHTML = '';
            handleSave();
        }

        var checkRadio13 = document.querySelectorAll('input[name="preg13"]:checked');
        if (checkRadio13.length == 0) {
            document.getElementById('val13').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val13').innerHTML = '';
            handleSave();
        }

        var checkRadio14 = document.querySelectorAll('input[name="preg14"]:checked');
        if (checkRadio14.length == 0) {
            document.getElementById('val14').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val14').innerHTML = '';
            handleSave();
        }

        var checkRadio15 = document.querySelectorAll('input[name="preg15"]:checked');
        if (checkRadio15.length == 0) {
            document.getElementById('val15').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val15').innerHTML = '';
            handleSave();
        }

        var checkRadio16 = document.querySelectorAll('input[name="preg16"]:checked');
        if (checkRadio16.length == 0) {
            document.getElementById('val16').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val16').innerHTML = '';
            handleSave();
        }


    }

    const ValOtro = (e) => {
        e.preventDefault();
        
        var checkboxes = document.querySelectorAll('input[name="preg12"]');
        var preg12;
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked == true) {
                preg12 = checkboxes[i].value;
                break;
            }
        }

        if(preg12 == "Otro"){
            var otro = document.getElementById('otro').value;
            if (otro.trim().length == 0 || Number.isInteger(otro) == true) {
                document.getElementById('val12').innerHTML = '*Ingrese un texto válido';
            } else {
                document.getElementById('val12').innerHTML = '';
                handleSave();
            }
        }
    }

    return (
        <div className="container">
            {/* <div className="subTitle">
                <h2 className="subText">Nutrición y Deporte</h2>
            </div> */}
            <div className="questionForm">
                <div className="questionItem"> 
                    <p className="encabezado">7.  ¿Cuántas veces a la semana consume...? <i id="val5" className="validation"></i></p>
                    <div className="questionMultiple">
                        <table>
                            <tr>
                                <td></td>
                                <td><label>Nunca</label></td>
                                <td><label>1 a 3 veces</label><br></br><label> por semana</label></td>
                                <td><label>4 a 6 veces</label><br></br><label> por semana</label></td>
                                <td><label>Diariamente</label></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Fruta</label></td>
                                <td><input type="radio" name="preg5-1" id="preg5" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-1" id="preg5" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-1" id="preg5" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-1" id="preg5" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Verduras</label></td>
                                <td><input type="radio" name="preg5-2" id="preg5" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-2" id="preg5" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-2" id="preg5" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-2" id="preg5" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Lácteos</label></td>
                                <td><input type="radio" name="preg5-3" id="preg5" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-3" id="preg5" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-3" id="preg5" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg5-3" id="preg5" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                        </table>
                    </div>  
                </div>
                <div className="questionItem"> 
                    <p className="encabezado">8. ¿Cuántas veces a la semana consume carnes (blancas o rojas)? <i id="val6" className="validation"></i> </p>
                    <div className="questionMultiple">
                        <table>
                            <tr>
                                <td></td>
                                <td><label>Nunca</label></td>
                                <td><label>1 a 3 veces</label><br></br><label> por semana</label></td>
                                <td><label>4 a 6 veces</label><br></br><label> por semana</label></td>
                                <td><label>Diariamente</label></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Pescado</label></td>
                                <td><input type="radio" name="preg6-1" id="preg6" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-1" id="preg6" className="pregunta" value="1 a 3 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-1" id="preg6" className="pregunta" value="4 a 6 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-1" id="preg6" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Cerdo</label></td>
                                <td><input type="radio" name="preg6-2" id="preg6" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-2" id="preg6" className="pregunta" value="1 a 3 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-2" id="preg6" className="pregunta" value="4 a 6 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-2" id="preg6" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Vacuno</label></td>
                                <td><input type="radio" name="preg6-3" id="preg6" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-3" id="preg6" className="pregunta" value="1 a 3 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-3" id="preg6" className="pregunta" value="4 a 6 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-3" id="preg6" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                            <tr>
                                <td className="nombre"><label>Pollo</label></td>
                                <td><input type="radio" name="preg6-4" id="preg6" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-4" id="preg6" className="pregunta" value="1 a 3 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-4" id="preg6" className="pregunta" value="4 a 6 veces" onChange={(e) => {validate();}}/></td>
                                <td><input type="radio" name="preg6-4" id="preg6" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">9. Si consume carnes rojas, ¿Qué tamaño o porción consume por vez? <i id="val7" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg7" id="preg7" className="pregunta" value="No consumo" required onChange={(e) => {validate();}}/> <label for="a">No consumo</label> <br></br>
                        <input type="radio" name="preg7" id="preg7" className="pregunta" value="Una palma de mano (100gr)" onChange={(e) => {validate();}}/> <label for="b">Una palma de mano (100gr)</label> <br></br>
                        <input type="radio" name="preg7" id="preg7" className="pregunta" value="Una palma y media de mano (150 gr)" onChange={(e) => {validate();}}/> <label for="c">Una palma y media de mano (150 gr)</label> <br></br>
                        <input type="radio" name="preg7" id="preg7" className="pregunta" value="Dos palmas de mano ( 200gr)" onChange={(e) => {validate();}}/> <label for="d">Dos palmas de mano ( 200gr)</label> <br></br>
                        <input type="radio" name="preg7" id="preg7" className="pregunta" value="Mas de 200gr" onChange={(e) => {validate();}}/> <label for="e">Mas de 200gr</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">10. ¿Qué cantidad de sal añade a la comida diariamente? Tanto en la cocción como la sal de mesa.
                    <i id="val8" className="validation"></i></p>            
                    <div className="questionaryRadio">
                        <input type="radio" name="preg8" id="preg8" className="pregunta" value="Ninguna" required onChange={(e) => {validate();}}/> <label for="a">Ninguna</label> <br></br>
                        <input type="radio" name="preg8" id="preg8" className="pregunta" value="Media cucharadita de café" onChange={(e) => {validate();}}/> <label for="b">Media cucharadita de café</label> <br></br>
                        <input type="radio" name="preg8" id="preg8" className="pregunta" value="Una cucharadita de café" onChange={(e) => {validate();}}/> <label for="c">Una cucharadita de café</label> <br></br>
                        <input type="radio" name="preg8" id="preg8" className="pregunta" value="Dos cucharaditas de café" onChange={(e) => {validate();}}/> <label for="d">Dos cucharaditas de café</label> <br></br>
                        <input type="radio" name="preg8" id="preg8" className="pregunta" value="Más de dos cucharaditas de café" onChange={(e) => {validate()}}/> <label for="e">Más de dos cucharaditas de café</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">11. ¿Con qué frecuencia come alimentos en conserva, sopas de sobre o utiliza cubitos de caldo?
                    <i id="val9" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg9" id="preg9" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/> <label for="a">Nunca</label> <br></br>
                        <input type="radio" name="preg9" id="preg9" className="pregunta" value="Ocasionalmente" onChange={(e) => {validate();}}/> <label for="b">Ocasionalmente</label> <br></br>
                        <input type="radio" name="preg9" id="preg9" className="pregunta" value="Una vez a la semana" onChange={(e) => {validate();}}/> <label for="c">Una vez a la semana</label> <br></br>
                        <input type="radio" name="preg9" id="preg9" className="pregunta" value="Varias veces a la semana" onChange={(e) => {validate();}}/> <label for="d">Varias veces a la semana</label> <br></br>
                        <input type="radio" name="preg9" id="preg9" className="pregunta" value="A diario" onChange={(e) => {validate();}}/> <label for="e">A diario</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">12. ¿Con qué frecuencia come alimentos preprocesados? (ej: Ketchup, puré instantáneo, preparaciones congeladas listas para su consumo, conservas)
                    <i id="val10" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg10" id="preg10" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/> 
                        <label for="a">Nunca</label> <br></br>
                        <input type="radio" name="preg10" id="preg10" className="pregunta" value="Ocasionalmente" onChange={(e) => {validate();}}/> 
                        <label for="b">Ocasionalmente</label> <br></br>
                        <input type="radio" name="preg10" id="preg10" className="pregunta" value="Una vez a la semana" onChange={(e) => {validate();}}/> 
                        <label for="c">Una vez a la semana</label> <br></br>
                        <input type="radio" name="preg10" id="preg10" className="pregunta" value="Varias veces a la semana" onChange={(e) => {validate();}}/> 
                        <label for="d">Varias veces a la semana</label> <br></br>
                        <input type="radio" name="preg10" id="preg10" className="pregunta" value="A diario" onChange={(e) => {validate();}}/> 
                        <label for="e">A diario</label>
                    </div>
                </div>
                <div className="questionItem"> 
                    <p className="encabezado">13. ¿Consume bebidas alcohólicas? <i id="val11" className="validation"></i></p>
                    <div className="questionMultiple">
                        <table>
                        <tr>
                            <td></td>
                            <td><label>Nunca</label></td>
                            <td><label>1 a 3 veces</label><br></br><label> por semana</label></td>
                            <td><label>4 a 6 veces</label><br></br><label> por semana</label></td>
                            <td><label>Diariamente</label></td>
                        </tr>
                        <tr>
                            <td className="nombre"><label>Cerveza</label></td>
                            <td><input type="radio" name="preg11-1" id="preg11" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-1" id="preg11" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-1" id="preg11" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-1" id="preg11" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                        </tr>
                        <tr>
                            <td className="nombre"><label>Destilados</label></td>
                            <td><input type="radio" name="preg11-2" id="preg11" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-2" id="preg11" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-2" id="preg11" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-2" id="preg11" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                        </tr>
                        <tr>
                            <td className="nombre"><label>Vinos u otros</label></td>
                            <td><input type="radio" name="preg11-3" id="preg11" className="pregunta" value="Nunca" required onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-3" id="preg11" className="pregunta" value="1 a 3 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-3" id="preg11" className="pregunta" value="4 a 6 veces por semana" onChange={(e) => {validate();}}/></td>
                            <td><input type="radio" name="preg11-3" id="preg11" className="pregunta" value="Diariamente" onChange={(e) => {validate();}}/></td>
                        </tr>
                        </table>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">14. ¿Está realizando alguna dieta en particular? <i id="val12" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="Ninguna" required onChange={(e) => {validate();}}/>
                        <label for="a">Ninguna</label> <br></br>
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="A base de jugos" onChange={(e) => {validate();}}/>
                        <label for="b">A base de jugos</label> <br></br>
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="A base de medicamentos" onChange={(e) => {validate();}}/>
                        <label for="c">A base de medicamentos</label> <br></br>
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="Proteica" onChange={(e) => {validate();}}/>
                        <label for="d">Proteica</label> <br></br>
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="Cetogénica" onChange={(e) => {validate();}}/>
                        <label for="e">Cetogénica</label> <br></br>
                        <input type="radio" name="preg12" id="preg12" className="pregunta" value="Otro" onChange={(e) => {validate();}}/>
                        <label for="otro">Otro</label> <input id="otro" type="text" className="inputOtros" onChange={(e) => ValOtro(e)}/>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">15. ¿Ha aumentado su talla o peso en los últimos 12 meses? <i id="val13" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg13" id="preg13" className="pregunta" value="Si" required onChange={(e) => {validate();}}/> 
                        <label for="si">Sí</label> <br></br>
                        <input type="radio" name="preg13" id="preg13" className="pregunta" value="No" onChange={(e) => {validate();}}/> 
                        <label for="no">No</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">16. Si ha aumentado de peso en los últimos 12 meses, ¿Cuánto a subido? <i id="val14" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="No he aumentado" required onChange={(e) => {validate();}}/> 
                        <label for="a">No he aumentado</label> <br></br>
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="Menos de 2 kg" onChange={(e) => {validate();}}/> 
                        <label for="b">Menos de 2 kg</label> <br></br>
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="2-5 kg" onChange={(e) => {validate();}}/> 
                        <label for="c">2-5 kg</label> <br></br>
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="6-10 kg" onChange={(e) => {validate();}}/> 
                        <label for="d">6-10 kg</label> <br></br>
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="11-15 kg" onChange={(e) => {validate();}}/> 
                        <label for="e">11-15 kg</label> <br></br>
                        <input type="radio" name="preg14" id="preg14" className="pregunta" value="Mas de 15 kg" onChange={(e) => {validate();}}/> 
                        <label for="f">Más de 15 kg</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">17. ¿Cuántos cigarrillos fuma por día? <i id="val15" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg15" id="preg15" className="pregunta" value="Ninguno" required onChange={(e) => {validate();}}/> 
                        <label for="a">Ninguno</label> <br></br>
                        <input type="radio" name="preg15" id="preg15" className="pregunta" value="Menos de 10 por dia" onChange={(e) => {validate();}}/> 
                        <label for="b">Menos de 10 por día</label> <br></br>
                        <input type="radio" name="preg15" id="preg15" className="pregunta" value="Entre 10 y 20 por dia" onChange={(e) => {validate();}}/> 
                        <label for="c">Entre 10 y 20 por día</label> <br></br>
                        <input type="radio" name="preg15" id="preg15" className="pregunta" value="Mas de 20 por dia" onChange={(e) => {validate();}}/> 
                        <label for="d">Más de 20 por día</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">18. ¿Cuántos días a la semana realiza deporte y/o actividad física? (Mas de 30 minutos de duración por vez)
                    <i id="val16" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg16" id="preg16" className="pregunta" value="Ninguno" required onChange={(e) => {validate();}}/> 
                        <label for="a">Ninguno</label> <br></br>
                        <input type="radio" name="preg16" id="preg16" className="pregunta" value="De 1 a 2 días" onChange={(e) => {validate();}}/> 
                        <label for="b">De 1 a 2 días</label> <br></br>
                        <input type="radio" name="preg16" id="preg16" className="pregunta" value="3 o mas días" onChange={(e) => {validate();}}/> 
                        <label for="c">3 o mas días</label>
                    </div>
                </div>
            </div>
        </div>
    );
}