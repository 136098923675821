import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import Axios from '../../api/axios';
import "./login.css";
import { Context } from "../../context/Context";
import Logo from '../../img/iStone.png';
import Img from '../../img/hands-of-unrecognizable-female-doctor-writing-on-form-and-typing-on-laptop-keyboard.jpg';
import { FaDoorOpen, FaIdCard, FaKey } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const userRef = useRef();
  const passwordRef = useRef();
  const {dispatch, isFetching } = useContext(Context);
  const navigate = useNavigate();

  const handleSubmit  = async (e) => {
    e.preventDefault();
    dispatch({type:"LOGIN_START"});
    try {
      const res = await Axios.post('/users/login/patients', {
        email: userRef.current.value,
        password: passwordRef.current.value,
      }).catch(function (error) {
        if(error.response.status == "400") {
          document.getElementById('validation').innerHTML = '¡Email o contraseña incorrecta!';
        } else {
          document.getElementById('validation').innerHTML = '¡Error '+error.response.status+'!';
        }
      });
      dispatch({ type:"LOGIN_SUCCESS", payload:res.data });
      localStorage.setItem('token', res.data.token);
      navigate('/prediccion');
    } catch(err) {
      dispatch({ type:"LOGIN_FAILURE" });
    }
  };

  return (
    <div className="login">
      {/* <h1 className="title">Iniciar Sesión</h1> */}
      <div className="i-right">
        <form className="loginForm" onSubmit={handleSubmit}>
          <div className="loginItem">
            <span><img className="logo-Login" src={Logo} alt="iStone Logo" /></span>
            <br></br>
            <span className="blue-text">Accede a tu cuenta</span>
            <table className="loginTable">
              <tr>
                <td><label><FaIdCard/> Email</label></td>
                <td><input id="user" type="text" placeholder="Ingresa tu email" required ref = {userRef}/></td>
              </tr>
              <tr>
                <td><label><FaKey/> Contraseña</label></td>
                <td><input id="pass" type="password" placeholder="Ingresa tu contraseña" required ref = {passwordRef}/></td>
              </tr>
            </table>
            <div className="loginItem">
              <label id="validation"></label>
            </div>
            <div className="loginItem">
            <button className="loginButton" type="submit" disabled = {isFetching}>Entrar <FaDoorOpen/></button>
            </div>
          </div>
        </form>
        <footer>
          <span style={{marginRight:"12px"}}>Términos y Condiciones</span>
          <span style={{marginLeft:"12px"}}>Política de Privacidad</span>
        </footer>
      </div>
      <div className="i-left">
          <img className="login-Img" src={Img} alt="image" />
      </div>
    </div>
  );
}
