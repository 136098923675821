import "./sidebar.css";
import React, { useState, useEffect } from 'react';
import {
  Home,
  Group,
  Business,
  SupervisedUserCircle,
  FileCopy,
  AttachFile,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

//!LAB solo ve lista de examenes
//!DOC ve todo menos lista de examenes

export default function Sidebar() {
  const [esTipoLab, setEsTipoLab] = useState(localStorage.getItem('tipoUsuario') === 'lab');

  // Este efecto se ejecuta cuando cambia el tipo de usuario en localStorage
  useEffect(() => {
    const tipoUsuario = localStorage.getItem('tipoUsuario');
    setEsTipoLab(tipoUsuario === 'lab');
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem active">
                <Home className="sidebarIcon" />
                Principal
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          {!esTipoLab ? (
            <>
              <h3 className="sidebarTitle">Usuarios</h3>
              <ul className="sidebarList">
                <Link to="/usuarios" className="link">
                  <li className="sidebarListItem">
                    <SupervisedUserCircle className="sidebarIcon" />
                    Lista de Usuarios
                  </li>
                </Link>
              </ul>
            </>
          ) : (
            <></>
          )}
          {esTipoLab ? (
            <>
              <h3 className="sidebarTitle">Laboratorios</h3>
              <ul className="sidebarList">
                <Link to="/laboratorios/examenes" className="link">
                  <li className="sidebarListItem">
                    <FileCopy className="sidebarIcon" />
                    Lista de Exámenes
                  </li>
                </Link>
              </ul>
            </>
          ) : (
            <>
              <h3 className="sidebarTitle">Laboratorios</h3>
              <ul className="sidebarList">
                <Link to="/laboratorios" className="link">
                  <li className="sidebarListItem">
                    <Business className="sidebarIcon" />
                    Lista de Laboratorios
                  </li>
                </Link>
              </ul>
              <h3 className="sidebarTitle">Pacientes</h3>
              <ul className="sidebarList">
                <Link to="/pacientes" className="link">
                  <li className="sidebarListItem">
                    <Group className="sidebarIcon" />
                    Lista de Pacientes
                  </li>
                </Link>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
