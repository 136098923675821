import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./forgetPassword.css";
import Axios from '../../../api/axios';
import { FaArrowAltCircleLeft} from "react-icons/fa";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");

  const Submit = async (e) => {
    e.preventDefault();
    await Axios.post('/users/password-recovery-request', {  
      email: email,
    }).then((response) => {
      if (response.status === 201 || response.status === 200) {
        alert('Se envió un email a tu correo para que recuperes tu contraseña.');
        document.getElementById("email").value = '';
      }   
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.status);
        if(error.response.status == 400){
          document.getElementById('validation').innerHTML = "Email no encontrado.";
        }
      }
    });
  };

  return (
    <div className="container">
      <div className="password">
        <div className="reportTitleContainer">
        <Link to={"/login"}><FaArrowAltCircleLeft className="iconBack" style={{marginLeft:"25px"}}/></Link>
          <h1 className="title">Recupera tu Contraseña</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <form className="passwordForm">
            <div>
              <table className="passwordTable">
                <tr className="passwordItem">
                  <td><label>Email</label></td>
                  <td><input id="email" type="email" placeholder="Ingresa tu email" required  onChange={(e) => setEmail(e.target.value)}/></td>
                </tr>
              </table>
              <div className="passwordItem">
                <label id="validation"></label>
              </div>
              <div className="passwordItem">
                <button className="passwordButton" onClick={(e) => Submit(e)}>Solicitar Contraseña</button>
              </div>
            </div>
          </form>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
      </div>
    </div>
  );
}
