import React, { useState, useEffect } from 'react';
import Axios from '../../../api/axios';
import $ from 'jquery';
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { FaArrowAltCircleLeft, FaRegPlusSquare} from "react-icons/fa";

export default function CreateLab() { 
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [email, setEmail] = useState("");
  const [rut, setRut] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  
  const [nombre2, setNombre2] = useState("");
  const [email2, setEmail2] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const [pais, setPais] = useState("");
  const [region, setRegion] = useState("");
  const [comuna, setComuna] = useState("");

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    validateForm();
  }, [email, email2, rut, nombre, nombre2, direccion, nombre2, pass1, pass2]);

  const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;

    let c = rut.replace("-", "");
    let b = c.charAt(c.length-1);
    let a = c.slice(0,c.length-1);
    document.querySelector('#rut').value = a + "-" + b;

    let rut2 = rut.replace("-", "");
    let n = rut2.charAt(rut2.length-1);
    let rut3 = rut2.slice(0,rut2.length-1);
    
    var M=0,S=1;
    for(;rut3;rut3=Math.floor(rut3/10))
    {
      S=(S+rut3%10*(9-M++%6))%11;
    }
    
    let result = S?S-1:'k';

    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
      setRut(e.target.value);
    }
  }

  const validateForm = () => {
    if (
      email.trim() !== '' &&
      email2.trim() !== '' &&
      rut.trim() !== '' &&
      nombre.trim() !== '' &&
      nombre2.trim() !== '' &&
      direccion.trim() !== '' &&
      nombre2.trim() !== '' &&
      pass1.trim() !== '' &&
      pass2.trim() !== '' &&
      pass1 === pass2
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const getCountries = async () => {
    await Axios.get('/masters/countries/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("pais");
      console.log(obj);
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    }).finally(() => {
      validateCountry();
    });
  }

  const getStates = async () => {
    $("#region").empty();
    await Axios.get('/masters/states/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("region");
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    }).finally(() => {
      getCommunes();
    });
  }

  const getCommunes = async () => {
    $("#comuna").empty();
    await Axios.get('/masters/communes/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("comuna");
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    });
  }

  const validateCountry = () => {
    setPais(document.getElementById("pais").value);
    if (document.getElementById("pais").value == 1){
      document.getElementById("region").disabled = false;
      document.getElementById("comuna").disabled = false;
      getStates();
    } else {
      $("#region").empty();
      $("#comuna").empty();
      document.getElementById("region").disabled = true;
      document.getElementById("comuna").disabled = true;
    }
  }

  const Clear = () => {
    document.getElementById('email').value = '';
    document.getElementById('rut').value = '';
    document.getElementById('nombre').value = '';
    document.getElementById('telefono').value = '';
    document.getElementById('direccion').value = '';
    document.getElementById('ncontacto').value = '';
    document.getElementById('econtacto').value = '';
    document.getElementById('tcontacto').value = '';
    document.getElementById('pais').value = "1";
    document.getElementById('region').value = "1";
    document.getElementById('comuna').value = "1";
    document.getElementById('pass1').value = '';
    document.getElementById('pass2').value = '';
    setIsSubmitDisabled(true);
  }

  const Submit = async (e) => {
    e.preventDefault();
    await Axios.post('/users/labs/', {
      name: nombre,  
      email: email,
      password: pass1,
      extra_data: {
        dni: rut,
        address: direccion,
        contact_name: nombre2,
        contact_email: email2,
        contact_phone: telefono2,
        phone: telefono,
        commune: document.getElementById('comuna').value
      }
    }).then((response) => {
      if (response.status == "201") {
        alert('El laboratorio se ha creado correctamente.');
        Clear();
      }
    }).catch(function (error) {
      if (error.response) {
        if(error.response.status == "400"){
          alert("Hay datos mal introducidos.")
        }
      }
    });
  };
  
  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Crear Laboratorio</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        
        <form className="examenesForm">
          <div className="examenesItem">
            <label>Email</label>
            <input id="email" type="email" placeholder="ejemplo@email.cl" required onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange= {RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Nombre</label>
            <input id="nombre" type="text" placeholder="John Smith" required onChange={(e) => setNombre(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Teléfono</label>
            <input id="telefono" type="number" placeholder="912341234" onChange={(e) => setTelefono(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Dirección</label>
            <input id="direccion" type="text" placeholder="Avenida Los Lirios 56" required onChange={(e) => setDireccion(e.target.value)}/>
          </div>
          <div className="examenesItem" style={{width:"100%"}}></div>
          <div className="examenesItem">
            <label>Nombre de Contacto</label>
            <input id="ncontacto" type="text" placeholder="John Smith" required onChange={(e) => setNombre2(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Email de Contacto</label>
            <input id="econtacto" type="email" placeholder="example@email.com" onChange={(e) => setEmail2(e.target.value)}/> 
          </div>
          <div className="examenesItem">
            <label>Teléfono de Contacto</label>
            <input id="tcontacto" type="number" placeholder="912341234" onChange={(e) => setTelefono2(e.target.value)}/> 
          </div>
          <div className="examenesItem">
            <label>País </label>
            <select id="pais" name="pais" onChange={validateCountry}>
            </select>
          </div>
          <div className="examenesItem">
            <label>Región </label>
            <select id="region" name="region" onChange={(e) => setRegion(e.target.value)}>
            </select>
          </div>
          <div className="examenesItem">
            <label>Comuna </label>
            <select id="comuna" name="comuna" onChange={(e) => setComuna(e.target.value)}>
            </select>
          </div>
          <div className="examenesItem" style={{width:"100%"}}></div>
          <div className="examenesItem">
            <label>Contraseña</label>
            <input id="pass1" type="password" placeholder="contraseña" required onChange={(e) => setPass1(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Repetir Contraseña</label>
            <input id="pass2" type="password" placeholder="contraseña" required onChange={(e) => setPass2(e.target.value)}/>
          </div>
          <div className='examenesItem' style={{width:"100%"}}></div>
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Submit(e)} disabled={isSubmitDisabled}>
            <FaRegPlusSquare className="reportShowIcon"/> Crear Laboratorio
          </button>
        </form>
      </div>
    </div>
  );
}
