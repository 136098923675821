import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./changePassword.css";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft} from "react-icons/fa";


//!Revisar datos que solicita
export default function ChangePassword() {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const token = localStorage.getItem("token");

  const Submit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('password', pass1);
    formData.append('repeat_password', pass2);
  
    try {
      const response = await Axios.post('/users/change-password', formData, {
        headers: { 
          "content-type": "multipart/form-data",
          'Authorization': "Token " + token
        },
      });
  
      if (response.status === 200) {
        alert('Cambiada correctamente.');
        document.getElementById('pass1').value = '';
        document.getElementById('pass2').value = '';
      }
    } catch (error) {
      if (error.response) {
        document.getElementById('validation').innerText =
          "Error: " + error.response.data.msg;
      }
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Cambiar Constraseña</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <div className="formContainer">
          <form className="examenesForm formContainer">
            <div className="examenesItem">
              <label>Contraseña</label>
              <input id="pass1" type="password" placeholder="Ingresa una nueva contraseña" required  onChange={(e) => setPass1(e.target.value)}/>
            </div>
            <div className='examenesItem' style={{width:"100%"}}></div>
            <div className="examenesItem">
              <label>Repite Contraseña</label>
              <input id="pass2" type="password" placeholder="Repite tu nueva contraseña" required  onChange={(e) => setPass2(e.target.value)}/>
            </div>
            <div className='examenesItem' style={{width:"100%"}}></div>
            <label id="validation"></label>
            <div className='examenesItem' style={{width:"100%"}}></div>
            <button className="examenesButton bigButton alignButton" onClick={(e) => Submit(e)}>Cambiar contraseña</button>
          </form>
        </div>
      </div>
    </div>
  );
}
