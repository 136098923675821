import "./questionary.css";
import React, { useState, useEffect } from 'react';
import Axios from '../../api/axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {BrowserView, MobileView} from 'react-device-detect';
import { useTheme } from '@mui/material/styles';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import FifthStep from './FifthStep';
import SixthStep from './SixthStep';

const steps = ['Datos Generales', 'Personal', 'Nutrición y Deporte', 'Historial médico', 'Análisis de Orina', 'Análisis de Sangre'];
const getStepContent = (step) => {
  switch (step) {
    case 0:
      return <FirstStep />;
    case 1:
      return <SecondStep />;
    case 2:
      return <ThirdStep />;
    case 3:
      return <FourthStep />;
    case 4:
      return <FifthStep />;
    case 5:
      return <SixthStep />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Questionary() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const navigate = useNavigate();
  
  const Send = async (e) => {
    if(localStorage.getItem('calculos_actual').includes("Si")){
      localStorage.setItem('calculos_actual', "Si");
    } else if(localStorage.getItem('calculos_actual').includes("No")){
      localStorage.setItem('calculos_actual', "No");
    }

    if(localStorage.getItem('cond_salud').includes("Ninguna de las anteriores")){
      localStorage.setItem('cond_salud', "Ninguna de las anteriores");
    }

    if(localStorage.getItem('medicamentos_vitaminas').includes("Ninguno de los anteriores")){
      localStorage.setItem('medicamentos_vitaminas', "Ninguno de los anteriores");
    }
    
    
    e.preventDefault();
    await Axios.post('/core/training/', {
        edad: localStorage.getItem('edad'),  
        sexo: localStorage.getItem('sexo'),
        altura: localStorage.getItem('altura'),
        peso: localStorage.getItem('peso'),
        raza: localStorage.getItem('raza'),
        nacionalidad: localStorage.getItem('nacionalidad'),
        residencia: localStorage.getItem('residencia'),
        region_chile: localStorage.getItem('region_chile'),
        calculos_actual: localStorage.getItem('calculos_actual'),
        temp_altas_trabajo: localStorage.getItem('temp_altas_trabajo'),
        sed: localStorage.getItem('sed'),
        horas_sin_agua: localStorage.getItem('horas_sin_agua'),
        agua_por_dia: localStorage.getItem('agua_por_dia'),
        color_orina: localStorage.getItem('color_orina'),
        frutas: localStorage.getItem('frutas'),
        verduras: localStorage.getItem('verduras'),
        lacteos: localStorage.getItem('lacteos'),
        pescado: localStorage.getItem('pescado'),
        cerdo: localStorage.getItem('cerdo'),
        pollo: localStorage.getItem('pollo'),
        vacuno: localStorage.getItem('vacuno'),
        tamano_porcion_vacuna: localStorage.getItem('tamano_porcion_vacuna'),
        sal: localStorage.getItem('sal'),
        conservas_sopas_caldos: localStorage.getItem('conservas_sopas_caldos'),
        procesados: localStorage.getItem('procesados'),
        cerveza: localStorage.getItem('cerveza'),
        destilados: localStorage.getItem('destilados'),
        vino_otros: localStorage.getItem('vino_otros'),
        dieta: localStorage.getItem('dieta'),
        aumento_peso: localStorage.getItem('aumento_peso'),
        aumento_kg: localStorage.getItem('aumento_kg'),
        cigarrillos: localStorage.getItem('cigarrillos'),
        actividad_fisica: localStorage.getItem('actividad_fisica'),
        cant_calculos: localStorage.getItem('cant_calculos'),
        oper_endoscopia: localStorage.getItem('oper_endoscopia'),
        oper_litotripsia: localStorage.getItem('oper_litotripsia'),
        oper_percutanea: localStorage.getItem('oper_percutanea'),
        oper_otra: localStorage.getItem('oper_otra'),
        comp_calc: localStorage.getItem('comp_calc'),
        cond_salud: localStorage.getItem('cond_salud'),
        familiar_calculo: localStorage.getItem('familiar_calculo'),
        medicamentos_vitaminas: localStorage.getItem('medicamentos_vitaminas'),
        comentario: localStorage.getItem('comentario'),
        aspecto: localStorage.getItem('aspecto'),
        densidad: localStorage.getItem('densidad'),
        ph: localStorage.getItem('ph'),
        hematies: localStorage.getItem('hematies'),
        cristales: localStorage.getItem('cristales'),
        urea: localStorage.getItem('urea'),
        creatinina: localStorage.getItem('creatinina'),
        acido_urico: localStorage.getItem('acido_urico'),
        sodio: localStorage.getItem('sodio'),
        potasio: localStorage.getItem('potasio'),
        cloro: localStorage.getItem('cloro'),
        calcio: localStorage.getItem('calcio'),
    }).then((response) => {
        if (response.status == "200") {
            localStorage.clear();
            navigate('/encuesta/completada');
        }
    }).catch(function (error) {
        if (error.response) {
            if(error.response.status == "400"){
                alert("Hubo un error enviado las respuestas.");
            }
        }
    });
  };

  const StepTree = async (e) => {
    switch (activeStep) {
      case 0 :
        ValidationStep1(e);
        break;
      case 1:
        ValidationStep2(e);
        break;
      case 2:
        ValidationStep3(e);
        break;
      case 3:
        ValidationStep4(e);
        break;
      case 4:
        ValidationStep5(e);
        break;
      case 5:
        ValidationStep6(e);
        break;
      default:
        break;
    }
  };

  const ValidationStep1 = async (e) => {
    e.preventDefault();
    var error = [];

    if(!localStorage.hasOwnProperty('edad') || localStorage.getItem('edad') == "undefined" ||  localStorage.getItem('edad') == "") {
        error.push("Campo 'Edad' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('sexo') || localStorage.getItem('sexo') == "undefined" ||  localStorage.getItem('sexo') == "") {
        error.push("Campo 'Sexo' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('altura') || localStorage.getItem('altura') == "undefined" ||  localStorage.getItem('altura') == "") {
        error.push("Campo 'Altura' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('peso') || localStorage.getItem('peso') == "undefined" ||  localStorage.getItem('peso') == "") {
        error.push("Campo 'Peso' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('raza') || localStorage.getItem('raza') == "undefined" ||  localStorage.getItem('raza') == "") {
        error.push("Campo 'Raza' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('nacionalidad') || localStorage.getItem('nacionalidad') == "undefined" ||  localStorage.getItem('nacionalidad') == "") {
        error.push("Campo 'Nacionalidad' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('residencia') || localStorage.getItem('residencia') == "undefined" ||  localStorage.getItem('residencia') == "") {
        error.push("Campo 'Residencia' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('region_chile') || localStorage.getItem('region_chile') == "undefined" ||  localStorage.getItem('region_chile') == "") {
        error.push("Campo 'Region' está sin responder correctamente.");
    }

    if (error.length == 0){
      handleNext(e);
    } else {
        alert(error.join("\n"));
    }
  };

  const ValidationStep2 = async (e) => {
    e.preventDefault();
    var error = [];
    if(!localStorage.hasOwnProperty('calculos_actual') || localStorage.getItem('calculos_actual') == "undefined" ||  localStorage.getItem('calculos_actual') == "") {
      error.push("'Pregunta 1' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('temp_altas_trabajo') || localStorage.getItem('temp_altas_trabajo') == "undefined" ||  localStorage.getItem('temp_altas_trabajo') == "") {
        error.push("'Pregunta 2' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('sed') || localStorage.getItem('sed') == "undefined" ||  localStorage.getItem('sed') == "") {
        error.push("'Pregunta 3' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('horas_sin_agua') || localStorage.getItem('horas_sin_agua') == "undefined" ||  localStorage.getItem('horas_sin_agua') == "") {
      error.push("'Pregunta 4' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('agua_por_dia') || localStorage.getItem('agua_por_dia') == "undefined" ||  localStorage.getItem('agua_por_dia') == "") {
        error.push("'Pregunta 5' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('color_orina') || localStorage.getItem('color_orina') == "undefined" ||  localStorage.getItem('color_orina') == "") {
        error.push("'Pregunta 6' está sin responder correctamente.");
    }

    if (error.length == 0){
      handleNext(e);
    } else {
        alert(error.join("\n"));
    }
  };

  const ValidationStep3 = async (e) => {
    e.preventDefault();
    var error = [];
    if(!localStorage.hasOwnProperty('frutas') || localStorage.getItem('frutas') == "undefined" ||  localStorage.getItem('frutas') == "" || !localStorage.hasOwnProperty('verduras') || localStorage.getItem('verduras') == "undefined" ||  localStorage.getItem('verduras') == "" || !localStorage.hasOwnProperty('lacteos') || localStorage.getItem('lacteos') == "undefined" ||  localStorage.getItem('lacteos') == "") {
      error.push("'Pregunta 7' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('pescado') || localStorage.getItem('pescado') == "undefined" ||  localStorage.getItem('pescado') == "" || !localStorage.hasOwnProperty('cerdo') || localStorage.getItem('cerdo') == "undefined" ||  localStorage.getItem('cerdo') == "" || !localStorage.hasOwnProperty('pollo') || localStorage.getItem('pollo') == "undefined" ||  localStorage.getItem('pollo') == "" || !localStorage.hasOwnProperty('vacuno') || localStorage.getItem('vacuno') == "undefined" ||  localStorage.getItem('vacuno') == "") {
        error.push("'Pregunta 8' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('tamano_porcion_vacuna') || localStorage.getItem('tamano_porcion_vacuna') == "undefined" ||  localStorage.getItem('tamano_porcion_vacuna') == "") {
        error.push("'Pregunta 9' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('sal') || localStorage.getItem('sal') == "undefined" ||  localStorage.getItem('sal') == "") {
        error.push("'Pregunta 10' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('conservas_sopas_caldos') || localStorage.getItem('conservas_sopas_caldos') == "undefined" ||  localStorage.getItem('conservas_sopas_caldos') == "") {
        error.push("'Pregunta 11' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('procesados') || localStorage.getItem('procesados') == "undefined" ||  localStorage.getItem('procesados') == "") {
        error.push("'Pregunta 12' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('cerveza') || localStorage.getItem('cerveza') == "undefined" ||  localStorage.getItem('cerveza') == "" || !localStorage.hasOwnProperty('destilados') || localStorage.getItem('destilados') == "undefined" ||  localStorage.getItem('destilados') == "" || !localStorage.hasOwnProperty('vino_otros') || localStorage.getItem('vino_otros') == "undefined" ||  localStorage.getItem('vino_otros') == "") {
        error.push("'Pregunta 13' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('dieta') || localStorage.getItem('dieta') == "undefined" ||  localStorage.getItem('dieta') == "") {
        error.push("'Pregunta 14' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('aumento_peso') || localStorage.getItem('aumento_peso') == "undefined" ||  localStorage.getItem('aumento_peso') == "") {
        error.push("'Pregunta 15' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('aumento_kg') || localStorage.getItem('aumento_kg') == "undefined" ||  localStorage.getItem('aumento_kg') == "") {
        error.push("'Pregunta 16' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('cigarrillos') || localStorage.getItem('cigarrillos') == "undefined" ||  localStorage.getItem('cigarrillos') == "") {
        error.push("'Pregunta 17' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('actividad_fisica') || localStorage.getItem('actividad_fisica') == "undefined" ||  localStorage.getItem('actividad_fisica') == "") {
        error.push("'Pregunta 18' está sin responder correctamente.");
    }

    if (error.length == 0){
      handleNext(e);
    } else {
        alert(error.join("\n"));
    }
  };

  const ValidationStep4 = async (e) => {
    e.preventDefault();
    var error = [];

    if(!localStorage.hasOwnProperty('cant_calculos') || localStorage.getItem('cant_calculos') == "undefined" ||  localStorage.getItem('cant_calculos') == ""){
      error.push("'Pregunta 19' está sin responder correctamente.");
    }

    if (localStorage.hasOwnProperty('cant_calculos') && localStorage.getItem('cant_calculos') != 0){
      if(!localStorage.hasOwnProperty('oper_endoscopia') || localStorage.getItem('oper_endoscopia') == "undefined" ||  localStorage.getItem('oper_endoscopia') == "" || !localStorage.hasOwnProperty('oper_litotripsia') || localStorage.getItem('oper_litotripsia') == "undefined" ||  localStorage.getItem('oper_litotripsia') == "" || !localStorage.hasOwnProperty('oper_percutanea') || localStorage.getItem('oper_percutanea') == "undefined" ||  localStorage.getItem('oper_percutanea') == "" || !localStorage.hasOwnProperty('oper_otra') || localStorage.getItem('oper_otra') == "undefined" ||  localStorage.getItem('oper_otra') == "") {
        error.push("'Pregunta 20' está sin responder correctamente.");
      }

      if(!localStorage.hasOwnProperty('comp_calc') || localStorage.getItem('comp_calc') == "undefined" ||  localStorage.getItem('comp_calc') == "") {
        error.push("'Pregunta 21' está sin responder correctamente.");
      }
    } else {
      localStorage.removeItem('oper_endoscopia');
      localStorage.removeItem('oper_litotripsia');
      localStorage.removeItem('oper_percutanea');
      localStorage.removeItem('oper_otra');
      localStorage.removeItem('comp_calc');
    }
    
    if(!localStorage.hasOwnProperty('cond_salud') || localStorage.getItem('cond_salud') == "undefined" ||  localStorage.getItem('cond_salud') == "") {
        error.push("'Pregunta 22' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('familiar_calculo') || localStorage.getItem('familiar_calculo') == "undefined" ||  localStorage.getItem('familiar_calculo') == "") {
        error.push("'Pregunta 23' está sin responder correctamente.");
    }

    if(!localStorage.hasOwnProperty('medicamentos_vitaminas' || localStorage.getItem('medicamentos_vitaminas') == "undefined" ||  localStorage.getItem('medicamentos_vitaminas') == "")) {
        error.push("'Pregunta 24' está sin responder correctamente.");
    }

    if (!localStorage.hasOwnProperty('comentario') || localStorage.getItem('comentario') == null || localStorage.getItem('comentario') == ""){
      localStorage.removeItem('comentario');
    }

    if (error.length == 0){
      handleNext(e);
    } else {
        alert(error.join("\n"));
    }
  };

  const ValidationStep5 = async (e) => {
    e.preventDefault();
    // var error = [];

    if(localStorage.hasOwnProperty('ph') && localStorage.getItem('ph') == "" || localStorage.getItem('ph') == '') {
      localStorage.removeItem('ph');
    }

    if(localStorage.hasOwnProperty('densidad') && localStorage.getItem('densidad') == "" || localStorage.getItem('densidad') == '') {
      localStorage.removeItem('densidad');
    }

    handleNext(e);
  };

  const ValidationStep6 = async (e) => {
    e.preventDefault();
    if(localStorage.hasOwnProperty('urea') && localStorage.getItem('urea') == "" || localStorage.getItem('urea') == '') {
      localStorage.removeItem('urea');
    }

    if(localStorage.hasOwnProperty('creatinina') && localStorage.getItem('creatinina') == "" || localStorage.getItem('creatinina') == '') {
      localStorage.removeItem('creatinina');
    }

    if(localStorage.hasOwnProperty('acido_urico') && localStorage.getItem('acido_urico') == "" || localStorage.getItem('acido_urico') == '') {
      localStorage.removeItem('acido_urico');
    }

    if(localStorage.hasOwnProperty('sodio') && localStorage.getItem('sodio') == "" || localStorage.getItem('sodio') == '') {
      localStorage.removeItem('sodio');
    }

    if(localStorage.hasOwnProperty('potasio') && localStorage.getItem('potasio') == "" || localStorage.getItem('potasio') == '') {
      localStorage.removeItem('potasio');
    }

    if(localStorage.hasOwnProperty('cloro') && localStorage.getItem('cloro') == "" || localStorage.getItem('cloro') == '') {
      localStorage.removeItem('cloro');
    }

    if(localStorage.hasOwnProperty('calcio') && localStorage.getItem('calcio') == "" || localStorage.getItem('calcio') == '') {
      localStorage.removeItem('calcio');
    }
    handleNext(e);
  };

//------------------------------------------------------------------
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? 
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className="container">
      <div className="question">
        <h1 className="title">Cuestionario</h1>
        <BrowserView>
          <form>
            <Box sx={{ flexGrow: 0, }}>
              <Stepper Non-linear activeStep={activeStep} sx={{
                display: 'flex',
                bgcolor: 'background.paper',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 50,          
              }}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {isLastStep() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      {getStepContent(activeStep)}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                        size="medium"
                        sx={{ ml: 2, mb: 2 }}
                        className="AntButton"
                      >
                        Anterior
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                        <Button 
                          // className="sendButton"
                          onClick={Send} 
                          variant="contained"
                          size="large"
                          sx={{ mr: 2, mb: 2, backgroundColor: '#f05a24' }}
                          className="EnviarButton"
                        >
                          Finalizar
                        </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      {getStepContent(activeStep)}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                        size="medium"
                        sx={{ ml: 2, mb: 2 }}
                        className="AntButton"
                      >
                        Anterior
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                        <Button 
                          disabled={activeStep === 5}
                          onClick={StepTree} 
                          variant="contained"
                          size="large"
                          sx={{ mr: 2, mb: 2, backgroundColor: '#662d90' }}
                          className="SigButton"
                        >
                          Siguiente
                        </Button>
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </form>
        </BrowserView>

        <MobileView>
          <h3 className="subtitle">{steps[activeStep]}</h3>
          <form>
            {getStepContent(activeStep)}
            <div>
              {isLastStep() ? (
                <MobileStepper
                variant="progress"
                steps={6}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 736, flexGrow: 1 }}
                nextButton={
                  <Button size="large" onClick={Send}>
                    Finalizar
                  </Button>
                }
                backButton={
                  <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Anterior
                  </Button>
                }
              />
              ) : (
                <MobileStepper
                  variant="progress"
                  steps={6}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 643, flexGrow: 1 }}
                  nextButton={
                    <Button size="large" onClick={StepTree} disabled={activeStep === 5}>
                      Siguiente
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      Anterior
                    </Button>
                  }
                />
              )}
            </div>
            
          </form>
        </MobileView>
      </div>
    </div>
  );
}
