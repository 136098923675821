import "./questionary.css";
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import $ from 'jquery';

export default function Completed() {

  return (
    <div className="container">
      <div className="completed">
        <h3>La encuesta fue completada exitosamente.</h3>
        <h2>¡Muchas gracias por Participar!</h2>
      </div>
    </div>
  );
}
