import {
  CalendarToday,
  MailOutline,
  PhoneAndroid,
  LocalDrink,
  Fastfood,
  EmojiPeople,
} from "@material-ui/icons";
import React, { useState, useEffect } from 'react';
import {  useLocation  } from "react-router-dom";
import Axios from '../../../api/axios';
import "./report.css";
import { FaUserAlt, FaHeart, FaSyringe, FaAddressCard, FaFilePdf, FaExclamationCircle, FaFileMedical, FaSave} from "react-icons/fa";

export default function Report() {
  const [vars, setVars] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  var date;

  var medicoTrat = [];
  var recomendaciones = [];
  var recomendaciones2 = [];

  const location = useLocation();
  const token = localStorage.getItem('token');

  var accessCode;
  
  var arrayModelo = [];
  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    accessCode = queryParams.get('access_code');
    console.log({accessCode});
    getPaciente(accessCode);
  }, []);


  const getPaciente = async (accessCode) => {
    clearModelo();
    clearPersonalizado();
    
    await Axios.get(`/predictions/public?access_code=`+ accessCode).then((response) => {     
      const obj = response.data;

      setVars(obj.vars);
      medicoTrat = obj.medico_tratante;
      recomendaciones = obj.recomendaciones;
      recomendaciones2 = obj.medico_recomendaciones;

      if(obj.comentarios_paciente && obj.comentarios_paciente.length > 0) {
        document.getElementById('comentarioPaciente').innerHTML = obj.comentarios_paciente;
      } else {
        document.getElementById('comentarioPaciente').innerHTML = "No hay comentarios";
      }
      limpiarComentarioMedico();
      
      reloadRecomendaciones(recomendaciones);
      RecomendacionesPersonalizadas(recomendaciones2);


      document.getElementById('fecha').innerHTML = obj.current_prediction.date;
      document.getElementById('puntos').innerHTML = (obj.current_prediction.probability*100).toFixed(2)+"%";
      document.getElementById('riesgo').innerHTML = obj.current_prediction.risk;
      document.getElementById('riesgo').style.color = obj.current_prediction.color;

      document.getElementById('nombre').innerHTML = obj.personal_data.name;
      document.getElementById('email').innerHTML = obj.personal_data.email;
      document.getElementById('rut').innerHTML = obj.personal_data.dni;
      document.getElementById('ingreso').innerHTML = obj.personal_data.registered_on;

      document.getElementById('ultimaFecha').innerHTML = obj.last_prediction.date;
      document.getElementById('ultimoPuntos').innerHTML = (obj.last_prediction.probability).toFixed(6);
      document.getElementById('ultimoRiesgo').innerHTML = obj.last_prediction.risk;
      document.getElementById('ultimoRiesgo').style.color = obj.last_prediction.color;
      
    });
  }


  const limpiarComentarioMedico = () => {
    var tbl = document.getElementById("medicos");
  
    while (tbl.rows.length > 0) {
      tbl.deleteRow(0);
    }
  
    ComentarioMedico();
  }
  
  const ComentarioMedico = () => {
    var tbl = document.getElementById("medicos");

    if(medicoTrat.length > 0){
      for (let i = 0; i < medicoTrat.length; i++) {
        const tr = tbl.insertRow();
        
        const td1 = tr.insertCell();
        td1.appendChild(document.createTextNode(""));
        td1.innerHTML = "<label>Fecha: </label><label id='fechaMedico"+i+"'></label>";
        
        const td2 = tr.insertCell();
        td2.appendChild(document.createTextNode(""));
        td2.innerHTML = "<label>Nombre: </label><label id='nombreMedico"+i+"'></label>";

        const td3 = tr.insertCell();
        td3.appendChild(document.createTextNode(""));
        td3.innerHTML = "<label>Especialidad: </label><label id='especialidadMedico"+i+"'></label>";

        const td4 = tr.insertCell();
        td4.appendChild(document.createTextNode(""));
        td4.innerHTML = "<label>Comentario: </label><q id='comentarioMedico"+i+"'></q>";

        document.getElementById('fechaMedico'+i).innerHTML = medicoTrat[i].fecha;
        document.getElementById('nombreMedico'+i).innerHTML = medicoTrat[i].nombre;
        document.getElementById('especialidadMedico'+i).innerHTML = medicoTrat[i].especialidad;
        document.getElementById('comentarioMedico'+i).innerHTML = medicoTrat[i].comentario;
      }
    } else {
      const tr = tbl.insertRow();
      const td1 = tr.insertCell();
      td1.appendChild(document.createTextNode(""));
      td1.innerHTML = "<label>No hay comentarios</label>";
    }
  }

  const RecomendacionesModelo = (recomendaciones) => {
    const divModelo = document.getElementById('modelo');
    // var fecha_actual = document.getElementById("ultimaFecha").innerHTML;

    if(recomendaciones.length > 0){
      for (let i = 0; i < recomendaciones.length; i++) {
        const elem0 = document.createElement('div');
        elem0.className = "text";
        elem0.id = "div"+i;
  
        // if (fecha_actual == selectedDate) {
        //   const elem2 = document.createElement('button');
        //   elem2.innerHTML = "-";
        //   elem2.id = "modelo" + i;
        //   elem2.className = "lessButton";
        //   elem2.addEventListener("click", function(e) { 
        //     e.preventDefault();
        //     if (window.confirm("¿Eliminar?")) {
        //       const index = parseInt(this.id.replace("modelo", ""));
        //       if (index > -1) {
        //         recomendaciones.splice(index, 1);
        //         reloadRecomendaciones(recomendaciones);
        //       }
        //     }
        //   }, false);
        //   elem0.appendChild(elem2);
        // }
  
        const elem1 = document.createElement('label');
        elem1.innerHTML = recomendaciones[i] + " ";
        elem1.id = "recommendation" + i;
        elem1.className = "recommendation";
        elem0.appendChild(elem1);
  
        const elem4 = document.createElement("BR");
        elem0.appendChild(elem4);
  
        divModelo.appendChild(elem0);
      }
    } else {
      const elem0 = document.createElement('div');
      elem0.className = "text";
      elem0.id = "div0";

      const elem1 = document.createElement('label');
      elem1.innerHTML = "No hay recomendaciones.";
      elem1.id = "recommendation0";
      elem1.className = "recommendation";
      elem0.appendChild(elem1);

      divModelo.appendChild(elem0);
    }
  };

  const RecomendacionesPersonalizadas = (recomendaciones2) => {
    const divpersonalizado = document.getElementById('personalizado');
    // var fecha_actual = document.getElementById("ultimaFecha").innerHTML;

    if(recomendaciones2.length > 0){
      for (let i = 0; i < recomendaciones2.length; i++) {
        const elem0 = document.createElement('div');
        elem0.className = "text";
        elem0.id = "div"+i;
  
        // if (fecha_actual == selectedDate) {
        //   const elem2 = document.createElement('button');
        //   elem2.innerHTML = "-";
        //   elem2.id = "personalizado" + i;
        //   elem2.className = "lessButton";
        //   elem2.addEventListener("click", function (e) {
        //     e.preventDefault();
        //     if (window.confirm("¿Eliminar?")) {
        //       const index = parseInt(this.id.replace("personalizado", ""));
        //       if (index > -1) {
        //         recomendaciones2.splice(index, 1);
        //         reloadRecomendaciones2(recomendaciones2);
        //       }
        //     }
        //   }, false);
        //   elem0.appendChild(elem2);
        // }
  
        const elem1 = document.createElement('label');
        elem1.innerHTML = recomendaciones2[i].comentario;
        elem1.id = "recommendationArray" + i;
        elem1.className = "recommendation";
        elem0.appendChild(elem1);
  
        const elem4 = document.createElement("BR");
        elem0.appendChild(elem4);
  
        divpersonalizado.appendChild(elem0);
        
        arrayModelo.push(recomendaciones2[i].comentario);
      }
    } else {
      const elem0 = document.createElement('div');
      elem0.className = "text";
      elem0.id = "div0";

      const elem1 = document.createElement('label');
      elem1.innerHTML = "No hay recomendaciones.";
      elem1.id = "recommendation0";
      elem1.className = "recommendation";
      elem0.appendChild(elem1);

      divpersonalizado.appendChild(elem0);
    }
  };
    
  const reloadRecomendaciones = (recomendaciones) => {
    const divModelo = document.getElementById('modelo');
    while (divModelo.childNodes.length > 1) {
      divModelo.removeChild(divModelo.childNodes[1]);
    }
    RecomendacionesModelo(recomendaciones);
  }


  const clearModelo = async () => {
    var modelo = document.getElementById("modelo");
    while (modelo.hasChildNodes()) {
      modelo.removeChild(modelo.children[1]);
    }
  }

  const clearPersonalizado = async () => {
    var personalizado = document.getElementById("personalizado");
    while (personalizado.hasChildNodes()) {
      personalizado.removeChild(personalizado.children[1]);
    }
  }
  
  const reloadArrayModelo = () => {
    const divpersonalizado = document.getElementById('personalizado');
    var childToRemove = divpersonalizado.children[1]; // Obtener el segundo hijo

    while (childToRemove) {
      divpersonalizado.removeChild(childToRemove); // Eliminar el segundo hijo
      childToRemove = divpersonalizado.children[1]; // Obtener el nuevo segundo hijo
    }

    for (let i = 0; i < arrayModelo.length; i++) {
      const elem0 = document.createElement('div');
      elem0.className = "text";
      elem0.id = "divArray" + i;
  
      const elem1 = document.createElement('button');
      elem1.innerHTML = "-";
      elem1.id = "personalizado" + i;
      elem1.className = "lessButton";
      elem1.addEventListener("click", function (e) {
        e.preventDefault();
        if (window.confirm("¿Eliminar?")) {
          const index = parseInt(this.id.replace("personalizado", ""));
          if (index > -1) {
            arrayModelo.splice(index, 1);
            reloadArrayModelo();
          }
        }
      }, false);
      elem0.appendChild(elem1);
  
      const elem2 = document.createElement('label');
      elem2.innerHTML = arrayModelo[i] + " ";
      elem2.id = "recommendationArray" + i;
      elem2.className = "recommendation";
      elem0.appendChild(elem2);
  
      const elem4 = document.createElement("BR");
      elem0.appendChild(elem4);
  
      divpersonalizado.appendChild(elem0);
    }

    const inputPersonalizada = document.createElement('input');
    inputPersonalizada.id = "personalizada";
    inputPersonalizada.type = "text";
  
    const buttonPersonalizada = document.createElement('button');
    buttonPersonalizada.className = "plusButton";
    buttonPersonalizada.innerHTML = "+";
    buttonPersonalizada.addEventListener("click", () => {
      addRecommendation();
    });

    divpersonalizado.appendChild(inputPersonalizada);
    divpersonalizado.appendChild(buttonPersonalizada);
  };
  
  const addRecommendation = () => {
    const txt = document.getElementById('personalizada').value;
    if(txt.trim().length > 0) {
      arrayModelo.push(txt);
      reloadArrayModelo();
      document.getElementById('personalizada').value = "";
    } else {
      alert("Escriba alguna recomendación");
    }
  }


  const pdfDownload = async (e) => {
    const queryParams = new URLSearchParams(location.search);
      accessCode = queryParams.get('access_code');
    await Axios.get(`/predictions/public/report?access_code=`+ accessCode , {
      responseType: 'blob',
    }).then((response) => {
      const fileUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'Resumen.pdf');
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(fileUrl);
      document.body.removeChild(link);
    }).catch((error) => {
      console.error(error);
    });
}

  return (
    <div className="container">
      <div className="report">
        <div className="reportTitleContainer">
          {/* <Link to={"/pacientes"}><FaArrowAltCircleLeft className="iconBack"/></Link> */}
          <h1 className="reportTitle title"> Detalles del Paciente</h1>
          <button className="smallButton" onClick={pdfDownload} style={{ display: "flex", alignItems: "center" }}> <FaFilePdf className="reportShowIcon" style={{marginLeft:"5px"}}/>Descargar Pdf</button>
        </div>
        <div className="reportContainer">
          <div className="reportShow">
            <div className="reportShowBottom">
              <span className="reportShowTitle">Datos Personales</span>
              <div className="summaryShowInfo">
                <FaUserAlt className="reportShowIcon" />
                <span className="reportShowInfoTitle">Nombre: </span>
                <span className="reportShowInfoText" id="nombre"></span>
              </div>
              <div className="reportShowInfo">
                <MailOutline className="reportShowIcon" />
                <span className="reportShowInfoTitle">Correo: </span>
                <span className="reportShowInfoText" id="email"></span>
              </div>
              <div className="reportShowInfo">
                <FaAddressCard className="reportShowIcon" />
                <span className="reportShowInfoTitle">Rut: </span>
                <span className="reportShowInfoText" id="rut"></span>
              </div>
              <div className="reportShowInfo">
                <PhoneAndroid className="reportShowIcon" />
                <span className="reportShowInfoTitle">Ingreso en la App:</span>
                <span className="reportShowInfoText" id="ingreso"></span>
              </div>
              <span className="reportShowTitle">Última Ejecución</span>
              <div className="reportShowInfo">
                <CalendarToday className="reportShowIcon" />
                <span className="reportShowInfoTitle">Fecha: </span>
                <span className="reportShowInfoText" id="ultimaFecha"></span>
              </div>
              <div className="reportShowInfo">
                <FaHeart className="reportShowIcon" />
                <span className="reportShowInfoTitle">Probabilidad: </span>
                <span className="reportShowInfoText" id="ultimoPuntos"></span>
              </div>
              <div className="reportShowInfo">
                <FaExclamationCircle className="reportShowIcon" />
                <span className="reportShowInfoTitle">Riesgo:  </span>
                <span className="reportShowInfoText" id="ultimoRiesgo"></span>
              </div>
            </div>
            {/* <div className="divButton" style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="reportButton bigButton alignButton" onClick={(e) => send(e)}>
                <SendRounded className="reportShowIcon" style={{marginLeft:"5px"}}/>
                Enviar
              </button>
            </div> */}
          </div>
          <div className="reportUpdate">
            <div className="divButton" id="dates"></div>
            <div className="divResumen">
              <label className="resumen">Fecha</label> <label id="fecha"></label>
              <label className="resumen">Probabilidad</label> <label id="puntos"></label>
              <label className="resumen">Riesgo</label> <label id="riesgo"></label>
            </div>
            <span className="reportUpdateTitle">Detalles</span>

            <div className="reportUpdateForm">
              <div className="reportUpdateLeft">
                <div className="reportUpdateItem">
                  <label className="themeName"><EmojiPeople className="reportShowIcon"/> Personal</label> 
                  <label id="personal">{vars.personal?.length === 0 ? "No hay valores" : vars.personal?.join(", ")}</label>
                </div>
                <div className="reportUpdateItem">
                  <label className="themeName"><Fastfood className="reportShowIcon"/> Nutrición y Deporte</label>
                  <label id="nutricion">{vars.nutricion_deporte?.length === 0 ? "No hay valores" : vars.nutricion_deporte?.join(", ")}</label>
                </div>
                <div className="reportUpdateItem">
                  <label className="themeName"><FaHeart className="reportShowIcon"/> Historial Médico</label>
                  <label id="historial">{vars.historial_medico?.length === 0 ? "No hay valores" : vars.historial_medico?.join(", ")}</label>
                </div>
              </div>
              <div className="reportUpdateRight">
                <div className="reportUpdateItem">
                  <label className="themeName"><LocalDrink className="reportShowIcon"/> Exámen Orina</label>
                  <label id="orina">{vars.examen_orina?.length === 0 ? "No hay valores" : vars.examen_orina?.join(", ")}</label>
                </div>
                <div className="reportUpdateItem">
                  <label className="themeName"><LocalDrink className="reportShowIcon"/> Exámen Orina 24h</label>
                  <label id="orina24">{vars.examen_orina_24?.length === 0 ? "No hay valores" : vars.examen_orina_24?.join(", ")}</label>
                </div>
                <div className="reportUpdateItem">
                  <label className="themeName"><FaSyringe className="reportShowIcon"/> Exámen de Sangre</label>
                  <label id="sangre">{vars.examen_sangre?.length === 0 ? "No hay valores" : vars.examen_sangre?.join(", ")}</label>
                </div>
              </div>
            </div>

            <div className="reportUpdateForm" style={{height:"100px"}}>
              <div className="reportUpdateLeft" style={{display:"initial"}}>
                <summary className="commentary">Comentarios Paciente: </summary>
                <q id="comentarioPaciente"></q>
              </div>
              <div className="reportUpdateRight" style={{display:"initial"}}>
                <summary className="commentary">Comentarios Médico Tratante: </summary>
                <table className="commentaryTable" id="medicos">
                </table>
              </div>
            </div>
            <div className="reportUpdateForm">
              <div className="reportUpdateLeft" id="modelo" style={{display:"initial"}}>
                <summary className="commentary">Recomendaciones Modelo: </summary>
              </div>
              <div className="reportUpdateRight" id="personalizado" style={{display:"initial"}}>
                <summary className="commentary">Recomendaciones Personalizada: </summary>
              </div>
            </div>
            {/* <div className="divButton" style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="reportButton bigButton alignButton" onClick={(e) => saveChanges(e)} >
                <FaSave className="reportShowIcon"/> Guardar Cambios
              </button>
              <button className="reportButton bigButton">
                <Link to={"/pacientes/"+id.id+"/examenes/"} className="alignButton" style={{color:"white", textDecoration:"none"}}>
                  <FaFileMedical className="reportShowIcon"/> Ver Exámenes
                </Link>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}