import "./examReport.css";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";

export default function ExamReport() {
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);

  useEffect(() => {
    getExams();
  }, []);

  const getExams = async () => {
    await Axios.get('/core/laboratories-tests/?is_sent=True', {
      headers: {
        Authorization: "Token "+ `${token}`,
      }
    }).then((response) => {  
      const obj = response.data;       
      setData(obj);      
      console.log(obj);
    });
  };

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: true,
    displayRowCheckbox:false,
    filter: false,
    download: false,
    print: false
  };
  
  const columns = [
    { name: "id", label: "ID", options: { display: false}},
    { name: "dni", label: "Rut"},
    { name: "date", label: "Fecha Examen"},
    { name: "exam_type", label: "Tipo"},
    { name: "date", label: "Fecha Envío"},
    { name: "exam_type", label: "Densidad"},
    { name: "exam_type", label: "pH"},
    { name: "exam_type", label: "Hematies"},
    { name: "exam_type", label: "Cristales"},
  ];

  const columns24 = [
    { name: "id", label: "ID", options: { display: false}},
    { name: "dni", label: "Rut"},
    { name: "date", label: "Fecha Examen"},
    { name: "exam_type", label: "Tipo"},
    { name: "date", label: "Fecha Envío"},
    { name: "exam_type", label: "Acido Úrico"},
    { name: "exam_type", label: "Calcio"},
    { name: "exam_type", label: "Volumen"},
    { name: "exam_type", label: "Fosforo"},
    { name: "exam_type", label: "Magnesio"},
    { name: "exam_type", label: "Sodio"},
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="report">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Registro de Exámenes enviados</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
          <div className="">
            <h2 className="title" style={{fontSize:"28px"}}>Normal</h2>
            <MUIDataTable
              data={data}
              columns={columns}
              options = {options}
            />
          </div>
          <div className="">
            <h2 className="title" style={{fontSize:"28px"}}>24 Horas</h2>
            <MUIDataTable
              data={data}
              columns={columns24}
              options = {options}
            />
          </div>
      </div>
    </div>
  );
}
