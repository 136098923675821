import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./recoveryPassword.css";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";

export default function RecoveryPassword() {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const Submit = async (e) => {
    e.preventDefault();
    await Axios.post('/users/password-recovery-change', {
      password: pass1,
      password2: pass2,
    }).then((response) => {
      console.log(response.status);
      if (response.status == "200") {
        alert('Cambiada correctamente.');
      } else if (response.status == "400")   {
        alert('Usuario no encontrado.');
      }
    });
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="password">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Cambiar Constraseña</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <form className="passwordForm">
            <div className="passwordItem">
              <table className="passwordTable">
                <tr className="passwordItem">
                  <td><label>Contraseña</label></td>
                  <td><input id="pass1" type="password" placeholder="Ingresa una nueva contraseña" required  onChange={(e) => setPass1(e.target.value)}/></td>
                </tr>
                <tr className="passwordItem">
                  <td><label>Repite Contraseña</label></td>
                  <td><input id="pass2" type="password" placeholder="Repite tu nueva contraseña" required  onChange={(e) => setPass2(e.target.value)}/></td>
                </tr>
              </table>
              <div className="passwordItem">
                <label id="validation"></label>
              </div>
              <div className="passwordItem">
                <button className="passwordButton" onClick={(e) => Submit(e)}>Cambiar contraseña</button>
              </div>
            </div>
          </form>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
      </div>
    </div>
  );
}
