import React, { useState, useEffect } from "react";
import { useParams, useNavigate   } from "react-router-dom";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { FaArrowAltCircleLeft, FaEdit} from "react-icons/fa";
import { SendRounded} from "@material-ui/icons";

export default function EditExamOrina() {
  const [showPart, setShowPart] = useState(false);

  const [rut, setRut] = useState('');
  const [fecha, setFecha] = useState('');
  const [opcion, setOpcion] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  let id = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();


  useEffect( () =>  {
    getExam();
  }, []);

  const getExam = async () => {
    try {
      const response = await Axios.get(`/core/laboratories-tests/`+id.id, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Token " + token,
        },
      });
      const obj = response.data;

      document.getElementById("rut").value = obj.dni;
      document.getElementById("rut").disabled = true;

      document.getElementById("fecha").value = obj.date;
      document.getElementById("fecha").disabled = true;

      const filesDiv = document.getElementById("files");
      obj.files.forEach((file) => {
        const label = document.createElement("label");
        const link = document.createElement("a");
        link.href = file.file;
        link.textContent = file.name;
        link.target = "_blank";
        label.appendChild(link);
        filesDiv.appendChild(label);       

        const br = document.createElement("br");
        filesDiv.appendChild(br);
      });

      if(obj.exam_type == "ORINA"){
        document.getElementById("tipo").value = 1;
        document.getElementById("tipo").disabled = true;

        document.getElementById('aspecto').value = obj.values[0].answer;
        document.getElementById("aspecto").disabled = true;

        document.getElementById('densidad').value = obj.values[1].answer;
        document.getElementById("densidad").disabled = true;

        document.getElementById('ph').value = obj.values[2].answer;
        document.getElementById("ph").disabled = true;

        document.getElementById('hematies').value = obj.values[3].answer;
        document.getElementById("hematies").disabled = true;
        
        document.getElementById('cristales').value = obj.values[4].answer;
        document.getElementById("cristales").disabled = true;
      } else {
        document.getElementById("tipo").value = 2;
        setShowPart(2);
        document.getElementById("tipo").disabled = true;

        document.getElementById('acido_urico24').value = obj.values[0].answer;
        document.getElementById("acido_urico24").disabled = true;

        document.getElementById('calcio24').value = obj.values[1].answer;
        document.getElementById("calcio24").disabled = true;

        document.getElementById('volumen24').value = obj.values[2].answer;
        document.getElementById("volumen24").disabled = true;

        document.getElementById('magnesio24').value = obj.values[3].answer;
        document.getElementById("magnesio24").disabled = true;

        document.getElementById('sodio24').value = obj.values[4].answer;
        document.getElementById("sodio24").disabled = true;

        document.getElementById('creatinina24').value = obj.values[5].answer;
        document.getElementById("creatinina24").disabled = true;

        document.getElementById('oxalato24').value = obj.values[6].answer;
        document.getElementById("oxalato24").disabled = true;

        document.getElementById('ph_orina24').value = obj.values[7].answer;
        document.getElementById("ph_orina24").disabled = true;

        document.getElementById('fosforo24').value = obj.values[8].answer;
        document.getElementById("fosforo24").disabled = true;

        document.getElementById('nitrogeno24').value = obj.values[9].answer;
        document.getElementById("nitrogeno24").disabled = true;

        document.getElementById('sulfato24').value = obj.values[10].answer;
        document.getElementById("sulfato24").disabled = true;

        document.getElementById('cloro24').value = obj.values[11].answer;
        document.getElementById("cloro24").disabled = true;

        document.getElementById('amonio24').value = obj.values[12].answer;
        document.getElementById("amonio24").disabled = true;

        document.getElementById('potasio24').value = obj.values[13].answer;
        document.getElementById("potasio24").disabled = true;
      }      
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (event) => {
    const value = event.target.value;
    setShowPart(value === "2");
  };
  
  
  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios/examenes"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Modificación de Exámen de Orina</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form className="examenesForm" id="examenes">
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="13"/>
          </div>
          <div className="examenesItem">
            <label>Fecha</label>
            <input id="fecha" type="date"  required onChange={(e) => setFecha(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Tipo</label>
            <select name="tipo" id="tipo" onChange={handleSelect}>
              <option className="ExamSelect" value="1">Normal</option>
              <option className="ExamSelect" value="2">24 Horas</option>
            </select>
          </div>

        {!showPart && (
          <div style={{display:"contents"}}>
            <div className="examenesItem">
              <label>Aspecto <i id="valAspecto" className="validation"></i></label>
              <select id="aspecto">
                <option value="Transparente">Transparente</option>
                <option value="Ligeramente Turbio">Ligeramente Turbio</option>
                <option value="Turbio">Turbio</option>
                <option value="No se sabe">No se sabe</option>
              </select>
            </div>
            <div className="examenesItem">
              <label>Densidad <i id="valDensidad" className="validation"></i></label> 
              <input id="densidad" type="number" placeholder="1015" min="1000" max="1200"/>
            </div>
            <div className="examenesItem">
              <label>pH <i id="valPh" className="validation"></i></label> 
              <input id="ph" type="number" placeholder="6.8" min="4.0" max="9.0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Hematies (Glóbulos Rojos)<i id="valHematies" className="validation"></i></label>
              <select id="hematies">
                <option value="No se observan">No se observan </option>
                <option value="Escasos">Escasos</option>
                <option value="Regulares">Regulares</option>
                <option value="Abundantes">Abundantes</option>
              </select>
            </div>
            <div className="examenesItem">
              <label>Cristales <i id="valCristales" className="validation"></i></label>
              <select id="cristales">
                <option value="No se observan">No se observan </option>
                <option value="Escasos">Escasos</option>
                <option value="Regulares">Regulares</option>
                <option value="Abundantes">Abundantes</option>
              </select>
            </div>
          </div>
        )}

        {showPart && (
          <div style={{display:"contents"}}>
            <div className="examenesItem separador">Obligatorios</div>
            <div className="examenesItem">
              <label>Acido Úrico <i id="valAcido24" className="validation"></i></label>
              <input id="acido_urico24" type="number" placeholder="20" min="0" max="100" step="1" style={{width: "50px"}}/>
            </div>
            <div className="examenesItem">
              <label>Calcio <i id="valCalcio24" className="validation"></i></label>
              <input id="calcio24" type="number" placeholder="9.7" min="5.00" max="20.00"style={{width: "50px"}} step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Volumen Orina <i id="valVolumen24" className="validation"></i></label>
              <input id="volumen24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Magnesio <i id="valMagnesio24" className="validation"></i></label>
              <input id="magnesio24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Sodio <i id="valSodio24" className="validation"></i></label>
              <input id="sodio24" type="number" placeholder="137" min="0" max="300" style={{width: "50px"}}/>
            </div>
            <div className="examenesItem">
              <label>Creatinina <i id="valCreatinina24" className="validation"></i></label>
              <input id="creatinina24" type="number" placeholder="0.98" min="0.00" max="5.00" step="0.1" style={{width: "50px"}}/>
            </div>

            <div className="examenesItem separador">Opcionales</div>

            <div className="examenesItem" >
              <label>Oxalato <i id="valOxalato24" className="validation"></i></label>
              <input id="oxalato24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Ph. Orina <i id="valphOrina24" className="validation"></i></label>
              <input id="ph_orina24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Fosforo <i id="valFosforo24" className="validation"></i></label>
              <input id="fosforo24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Nitrógeno Ureico <i id="valNitrogeno24" className="validation"></i></label>
              <input id="nitrogeno24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Sulfato <i id="valSulfato24" className="validation"></i></label>
              <input id="sulfato24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Cloro <i id="valCloro24" className="validation"></i></label>
              <input id="cloro24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
            <div className="examenesItem">
              <label>Amonio <i id="valAmonio24" className="validation"></i></label>
              <input id="amonio24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1" />
            </div>
            <div className="examenesItem">
              <label>Potasio <i id="valPotasio24" className="validation"></i></label>
              <input id="potasio24" type="number" placeholder="" style={{width: "50px"}} min="0" step="0.1"/>
            </div>
          </div>
        )}
          <div className="examenesItem" style={{width: "100%"}}></div>
      
          <div className="examenesItem">
            <label>Archivos: </label>
            <div id="files"></div>
          </div>
        </form>
      </div>
    </div>
  );
}
