import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import Axios from '../../../api/axios';
import $ from 'jquery';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft, FaUserEdit} from "react-icons/fa";

export default function EditUser() {
  let id = useParams();

  useEffect(() => {
    getDoctor();
  }, []);
  
  const getDoctor = async () => {
    await Axios.get(`/users/doctors/`+ id.userId).then((response) => {     
      const obj = response.data;
      document.getElementById('rut').value = obj.extra_data.dni;
      document.getElementById('nombre').value = obj.name;
      document.getElementById('telefono').value = obj.extra_data.phone;
      document.getElementById('email').value = obj.email;
    })
  }
  const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;
    let rut2 = rut.replace("-", "");
    let n = rut2.slice(8);
    let rut3 = rut.slice(0,8);
    
    var M=0,S=1;
	  for(;rut3;rut3=Math.floor(rut3/10))
      S=(S+rut3%10*(9-M++%6))%11;
	  
    let result = S?S-1:'k';
    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
    }
  }

  const Update = async (e) => {
    e.preventDefault();
    await Axios.patch('/users/doctors/'+ id.userId +'/', {
      name: document.getElementById('nombre').value,  
      password: document.getElementById('pass1').value !== '' ? document.getElementById('pass1').value : null,
      extra_data: {
        dni: document.getElementById('rut').value,
        address: "string",
        phone: document.getElementById('telefono').value,
      }
    }).then((response) => {
      if (response.status == "200") {
        alert('El usuario se ha modificado correctamente.');
      }   
    }).catch(function (error) {
      if (error.response) {
        if(error.response.status == "400"){
          alert("El email introducido ya está en uso.")
        }
      }
    });
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link  to={"/usuarios"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Editar Usuario</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form className="examenesForm">
          <div className="examenesItem">
            <label>Email</label>
            <input id="email" type="email" disabled/>
          </div>
          <div className="examenesItem">
          <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange= {RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Nombre</label>
            <input id="nombre" type="text" placeholder="John Smith" required/>
          </div>
          <div className="examenesItem">
            <label>Teléfono</label>
            <input id="telefono" type="number" placeholder="012345678" />
          </div>
          <div className="examenesItem">
            <label>Contraseña</label>
            <input id="pass1" type="password" placeholder="contraseña" required/>
          </div>
          <div style={{width:"100%"}}></div>
          <button className="examenesButton bigButton alignButton" onClick={(e) => Update(e)}>
            <FaUserEdit className="reportShowIcon"/>Guardar Cambios
          </button>
        </form>
      </div>
    </div>
  );
}
