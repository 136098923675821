import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import Axios from '../../../api/axios';
import $ from 'jquery';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft, FaEdit} from "react-icons/fa";

export default function EditLab() {
  let id = useParams();

  const [rut, setRut] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");

  const [nombre2, setNombre2] = useState("");
  const [email, setEmail] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    getCountries();
    getLab();
  }, []);


  const getLab = async () => {
    await Axios.get(`/users/labs/`+ id.labId).then((response) => {     
      const obj = response.data;
      document.getElementById('rut').value = obj.extra_data.dni;
      document.getElementById('nombre').value = obj.name;
      document.getElementById('telefono').value = obj.extra_data.phone;
      document.getElementById('email').value = obj.email;
      document.getElementById('direccion').value = obj.extra_data.address;
      document.getElementById('ncontacto').value = obj.extra_data.contact_name;
      document.getElementById('econtacto').value = obj.extra_data.contact_email;
      document.getElementById('tcontacto').value = obj.extra_data.contact_phone;
    });
  }

  const RUTValidation = (e) => {
    e.preventDefault();
    let rut = document.querySelector('#rut').value;
    let rut2 = rut.replace("-", "");
    let n = rut2.slice(8);
    let rut3 = rut.slice(0,8);
    
    var M=0,S=1;
	  for(;rut3;rut3=Math.floor(rut3/10))
      S=(S+rut3%10*(9-M++%6))%11;

    let result = S?S-1:'k';
    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
    }
  }

  const getCountries = async () => {
    await Axios.get('/masters/countries/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("pais");
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    }).finally(() => {
      validateCountry();
    });
  }

  const getStates = async () => {
    $("#region").empty();
    await Axios.get('/masters/states/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("region");
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    }).finally(() => {
      getCommunes();
    });
  }

  const getCommunes = async () => {
    $("#comuna").empty();
    await Axios.get('/masters/communes/').then((response) => {     
      const obj = response.data;  
      var selectList = document.getElementById("comuna");
      
      for (var i = 0; i < obj.length; i++) 
      {
        var option = document.createElement("option");
        option.value = obj[i].id;
        option.text = obj[i].name;
        selectList.appendChild(option);
      }
    });
  }

  const validateCountry = () => {
    if (document.getElementById("pais").value == 1){
      document.getElementById("region").disabled = false;
      document.getElementById("comuna").disabled = false;
      getStates();
    } else {
      $("#region").empty();
      $("#comuna").empty();
      document.getElementById("region").disabled = true;
      document.getElementById("comuna").disabled = true;
    }
  }

  const Update = async (e) => {
    e.preventDefault();

    await Axios.patch('/users/labs/'+ id.labId +'/', {
      name: document.getElementById('nombre').value,  
      password: document.getElementById('pass1').value !== '' ? document.getElementById('pass1').value : null,
      extra_data: {
        dni: document.getElementById('rut').value,
        address: document.getElementById('direccion').value,
        contact_name: document.getElementById('ncontacto').value,
        contact_email: document.getElementById('econtacto').value,
        contact_phone: document.getElementById('tcontacto').value,
        phone: document.getElementById('telefono').value,
        commune: document.getElementById('comuna').value
      }
    }).then((response) => {
      if (response.status == "200") {
        alert('El laboratorio se ha modificado correctamente.');
      }
    }).catch(function (error) {
      if (error.response) {
        if(error.response.status == "400"){
          alert("El email introducido ya está en uso.")
        }
      }
    });
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Editar Laboratorio</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form className="examenesForm">
          <div className="examenesItem">
              <label>Email </label> 
              <input id="email" type="email" disabled/>
          </div>
          <div className="examenesItem">
              <label>Rut  <sub id="validation"></sub> </label> 
              <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange= {RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Nombre</label>
            <input id="nombre" type="text" placeholder="John Smith" required onChange={(e) => setNombre(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Teléfono</label>
            <input id="telefono" type="number" placeholder="012345678" onChange={(e) => setTelefono(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Dirección</label>
            <input id="direccion" type="text" placeholder="Avenida Los Lirios 56" required onChange={(e) => setDireccion(e.target.value)}/>
          </div>
          <div className="examenesItem" style={{width:"100%"}}></div>
          <div className="examenesItem">
            <label>Nombre de Contacto</label>
            <input id="ncontacto" type="text" placeholder="John Smith" required onChange={(e) => setNombre2(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Email de Contacto</label>
            <input id="econtacto" type="email" placeholder="example@email.com" onChange={(e) => setEmail(e.target.value)}/> 
          </div>
          <div className="examenesItem">
            <label>Teléfono de Contacto</label>
            <input id="tcontacto" type="number" placeholder="912341234" onChange={(e) => setTelefono2(e.target.value)}/> 
          </div>
          <div className="examenesItem">
            <label>País </label>
            <select id="pais" name="pais" onChange={validateCountry}>
            </select>
          </div>
          <div className="examenesItem">
            <label>Región </label>
            <select id="region" name="region">
            </select>
          </div>
          <div className="examenesItem">
            <label>Comuna </label>
            <select id="comuna" name="comuna">
            </select>
          </div>
          <div className="examenesItem" style={{width:"100%"}}></div>
          <div className="examenesItem">
            <label>Contraseña</label>
            <input id="pass1" type="password" placeholder="contraseña" required onChange={(e) => setPass(e.target.value)}/>
          </div>
          <div className="examenesItem" style={{width:"100%"}}></div>
          <button className="examenesButton bigButton alignButton" onClick={(e) => Update(e)}>
            <FaEdit className="reportShowIcon"/> Guardar Cambios
          </button>
        </form>
      </div>
    </div>
  );
}
