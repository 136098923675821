import "./questionary.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

export default function SecondStep() {

    const handleSave = () => {
        var checkRadio23 = document.querySelectorAll('input[name="preg23"]:checked');
        var val23 = [];
        for (var i = 0; i < checkRadio23.length; i++) {
            if (checkRadio23[i].checked == true) {
                val23.push(checkRadio23[i].value);
            }
        }
        localStorage.setItem('calculos_actual', val23);

        var checkRadio1 = document.querySelectorAll('input[name="preg1"]:checked');
        var val1;
        for (var i = 0; i < checkRadio1.length; i++) {
            if (checkRadio1[i].checked == true) {
                val1 = checkRadio1[i].value;
                break;
            }
        }
        localStorage.setItem('temp_altas_trabajo', val1);

        var checkRadio2 = document.querySelectorAll('input[name="preg2"]:checked');
        var val2;
        for (var i = 0; i < checkRadio2.length; i++) {
            if (checkRadio2[i].checked == true) {
                val2 = checkRadio2[i].value;
                break;
            }
        }
        localStorage.setItem('sed', val2);

        var checkRadio3 = document.querySelectorAll('input[name="preg3"]:checked');
        var val3;
        for (var i = 0; i < checkRadio3.length; i++) {
            if (checkRadio3[i].checked == true) {
                val3 = checkRadio3[i].value;
                break;
            }
        }
        localStorage.setItem('agua_por_dia', val3);

        var checkRadio36 = document.querySelectorAll('input[name="preg36"]:checked');
        var val36;
        for (var i = 0; i < checkRadio36.length; i++) {
            if (checkRadio36[i].checked == true) {
                val36 = checkRadio36[i].value;
                break;
            }
        }
        localStorage.setItem('horas_sin_agua', val36);

        var checkRadio4 = document.querySelectorAll('input[name="preg4"]:checked');
        var val4;
        for (var i = 0; i < checkRadio4.length; i++) {
            if (checkRadio4[i].checked == true) {
                val4 = checkRadio4[i].value;
                break;
            }
        }
        localStorage.setItem('color_orina', val4);
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {
            if(localStorage.hasOwnProperty('calculos_actual')){
                var checkRadio23 = document.querySelectorAll('input[name="preg23"]');
                var preg23 = localStorage.getItem('calculos_actual');
                preg23 = preg23.split(",");
                for (var i = 0; i < checkRadio23.length; i++) {
                    for (let x = 0; x < preg23.length; x++) {
                        if (checkRadio23[i].value == preg23[x]) {
                            checkRadio23[i].checked = true;
                        }
                    } 
                }
            }
            
            if(localStorage.hasOwnProperty('temp_altas_trabajo')){
                var checkRadio1 = document.querySelectorAll('input[name="preg1"]');
                for (var i = 0; i < checkRadio1.length; i++) {
                    if (checkRadio1[i].value == (localStorage.getItem('temp_altas_trabajo'))) {
                        checkRadio1[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('sed')){
                var checkRadio2 = document.querySelectorAll('input[name="preg2"]');
                for (var i = 0; i < checkRadio2.length; i++) {
                    if (checkRadio2[i].value == (localStorage.getItem('sed'))) {
                        checkRadio2[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('agua_por_dia')){
                var checkRadio3 = document.querySelectorAll('input[name="preg3"]');
                for (var i = 0; i < checkRadio3.length; i++) {
                    if (checkRadio3[i].value == (localStorage.getItem('agua_por_dia'))) {
                        checkRadio3[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('horas_sin_agua')){
                var checkRadio36 = document.querySelectorAll('input[name="preg36"]');
                for (var i = 0; i < checkRadio36.length; i++) {
                    if (checkRadio36[i].value == (localStorage.getItem('horas_sin_agua'))) {
                        checkRadio36[i].checked = true;
                        break;
                    }
                }
            }

            if(localStorage.hasOwnProperty('color_orina')){
                var checkRadio4 = document.querySelectorAll('input[name="preg4"]');
                for (var i = 0; i < checkRadio4.length; i++) {
                    if (checkRadio4[i].value == (localStorage.getItem('color_orina'))) {
                        checkRadio4[i].checked = true;
                        break;
                    }
                }
            }            
        }
        validate();
        valCondiciones();
    }

    useEffect( () =>  {
        handleLoad();
        window.scrollTo(0, 0);
    }, []);

    const validate = () => {
        var checkRadio23 = document.querySelectorAll('input[name="preg23"]:checked');
        if (checkRadio23.length == 0) {
            document.getElementById('val23').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val23').innerHTML = '';
            handleSave();
        }

        var checkRadio1 = document.querySelectorAll('input[name="preg1"]:checked');
        if (checkRadio1.length == 0) {
            document.getElementById('val1').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val1').innerHTML = '';
            handleSave();
        }

        var checkRadio2 = document.querySelectorAll('input[name="preg2"]:checked');
        if (checkRadio2.length == 0) {
            document.getElementById('val2').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val2').innerHTML = '';
            handleSave();
        }

        var checkRadio3 = document.querySelectorAll('input[name="preg3"]:checked');
        if (checkRadio3.length == 0) {
            document.getElementById('val3').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val3').innerHTML = '';
            handleSave();
        }

        var checkRadio36 = document.querySelectorAll('input[name="preg36"]:checked');
        if (checkRadio36.length == 0) {
            document.getElementById('val36').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val36').innerHTML = '';
            handleSave();
        }

        var checkRadio4 = document.querySelectorAll('input[name="preg4"]:checked');
        if (checkRadio4.length == 0) {
            document.getElementById('val4').innerHTML = '*Obligatorio';
        } else {
            document.getElementById('val4').innerHTML = '';
            handleSave();
        }
    }

    const valCondiciones = () => {
        var elements = document.querySelectorAll('[id=preg23]');
        if(localStorage.hasOwnProperty('calculos_actual')){
            if (localStorage.getItem('calculos_actual').includes("No")){
                for(var i = 0; i < 3; i++) {
                    elements[i].disabled = true;
                }
            } else {
                for(var i = 0; i < 3; i++) {
                    elements[i].disabled = false;
                }
            }
        }
    }

    return (
        <div className="container">
            <div className="questionForm">
                {/* <div className="questionItem">
                    <p className="encabezado">1. Ha tenido un cólico renal (dolor al eliminar un cálculo renal) o ha sido operado de cálculos en los últimos seis meses?
                    <i id="val23" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg23" id="preg23" className="pregunta" value="Si" required onChange={(e) => {validate();}}/>
                        <label for="a">Sí</label> <br></br>
                        <input type="radio" name="preg23" id="preg23" className="pregunta" value="No" onChange={(e) => {validate();}}/>
                        <label for="b">No</label>
                    </div>
                </div> */}
                <div className="questionItem">
                    <p className="encabezado">1. Ha tenido alguna de las siguientes condiciones:
                    <i id="val23" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="checkbox" name="preg23" id="preg23" className="pregunta" value="Si-a" required onChange={(e) => {validate();}}/>
                        <label for="a">Ha tenido cólico renal (dolor al eliminar un cálculo renal) o ha eliminado una piedra por la orina en los últimos 12 meses.</label> <br></br>
                        <input type="checkbox" name="preg23" id="preg23" className="pregunta" value="Si-b" onChange={(e) => {validate();}}/>
                        <label for="b">Tiene un estudio de imágenes (ecografía o scanner) que muestre la presencia de un cálculo en sus riñones en los últimos 12 meses.</label><br></br>
                        <input type="checkbox" name="preg23" id="preg23" className="pregunta" value="Si-c" onChange={(e) => {validate();}}/>
                        <label for="c">Ha sido operado de cálculos renales en los últimos 12 meses.</label><br></br>
                        <input type="checkbox" name="preg23" id="preg23" className="pregunta" value="No" onChange={(e) => {validate(); valCondiciones();}}/>
                        <label for="d">No he tenido ninguna de estas condiciones en los últimos 12 meses.</label><br></br>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">2. En su trabajo, ¿Se encuentra expuesto a temperaturas mayores a la ambiental? <i id="val1" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg1" id="preg1" className="pregunta" value="Si" required onChange={(e) => {validate();}}/> 
                        <label for="si">Sí</label> <br></br>
                        <input type="radio" name="preg1" id="preg1" className="pregunta" value="No" onChange={(e) => {validate();}}/> 
                        <label for="no">No</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">3. Durante el día, ¿Tiene sensación de sed? <i id="val2" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg2" id="preg2" className="pregunta" value="Si" required onChange={(e) => {validate();}}/> 
                        <label for="si">Sí</label> <br></br>
                        <input type="radio" name="preg2" id="preg2" className="pregunta" value="No" onChange={(e) => {validate();}}/> 
                        <label for="no">No</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">4. ¿Pasa más de 4 horas sin beber agua durante el trabajo? No considerar bebidas. <i id="val36" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg36" id="preg36" className="pregunta" value="Si" required onChange={(e) => {validate();}}/> 
                        <label for="si">Sí</label> <br></br>
                        <input type="radio" name="preg36" id="preg36" className="pregunta" value="No" onChange={(e) => {validate();}}/> 
                        <label for="no">No</label>
                    </div>
                </div>      
                <div className="questionItem">
                    <p className="encabezado">5. ¿Cuánta agua alcanza a beber en el día? <i id="val3" className="validation"></i></p>
                    <div className="questionaryRadio">
                        <input type="radio" name="preg3" id="preg3" className="pregunta" value="Menos de medio litro (<500cc)" required onChange={(e) => {validate();}}/> 
                        <label for="poco">Menos de medio litro (&lt;500cc)</label> <br></br>
                        <input type="radio" name="preg3" id="preg3" className="pregunta" value="1 litro (1000cc)" required onChange={(e) => {validate();}}/> 
                        <label for="medio">1 litro (1000cc)</label> <br></br>
                        <input type="radio" name="preg3" id="preg3" className="pregunta" value="Mas de 1 litro (>1000cc)" required onChange={(e) => {validate();}}/> 
                        <label for="bastante">Más de 1 litro (&gt;1000cc)</label>
                    </div>
                </div>  
                <div className="questionItem">
                <p className="encabezado">6. ¿Con cuál de los siguientes colores identificaría su orina la mayor parte del día? <i id="val4" className="validation"></i></p>
                    <img className="imgOrina" src={require("../../img/pregunta4.jpg")}></img>
                    <div className="questionaryRadio orina">
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="1" required  onChange={(e) => {validate();}}/> 
                    <label for="a">1</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="2" onChange={(e) => {validate();}}/> 
                    <label for="b">2</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="3" onChange={(e) => {validate();}}/> 
                    <label for="c">3</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="4" onChange={(e) => {validate();}}/> 
                    <label for="d">4</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="5" onChange={(e) => {validate();}}/> 
                    <label for="e">5</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="6" onChange={(e) => {validate();}}/> 
                    <label for="e">6</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="7" onChange={(e) => {validate();}}/> 
                    <label for="e">7</label>
                    <input type="radio" name="preg4" id="preg4" className="pregunta" value="8" onChange={(e) => {validate();}}/> 
                    <label for="e">8</label>
                    </div> 
                </div>
            </div>
        </div>
    );
}