import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaArrowAltCircleLeft, FaEdit} from "react-icons/fa";
import { SendRounded} from "@material-ui/icons";

export default function EditExamCalculos() {
  const [rut, setRut] = useState('');
  const [fecha, setFecha] = useState('');
  const [opcion, setOpcion] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  let id = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [archivo, setArchivo] = useState('');

  //! Desbloquear el validador de Rut, bloqueado para pruebas

  useEffect(() => {
    //Clear();
    getExam();
  }, []);

  // useEffect(() => {
  //   validateForm();
  // }, [fecha, opcion]);

  const getExam = async () => {
    try {
      const response = await Axios.get(`/core/laboratories-tests/`+id.id, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Token " + token,
        },
      });
      const obj = response.data;

      document.getElementById("rut").value = obj.dni;
      document.getElementById("rut").disabled = true;

      document.getElementById("fecha").value = obj.date;

      const filesDiv = document.getElementById("files");
      obj.files.forEach((file) => {
        const button = document.createElement("button");
        button.textContent = "x";
        button.classList.add("fileButton");
        button.setAttribute("id", file.id);
        button.addEventListener("click", () => DeleteFile(file.id));
        filesDiv.appendChild(button);

        const label = document.createElement("label");
        const link = document.createElement("a");
        link.href = file.file;
        link.textContent = file.name;
        link.target = "_blank";
        label.appendChild(link);
        filesDiv.appendChild(label);      

        const br = document.createElement("br");
        filesDiv.appendChild(br);
      });

      var answer = obj.values[0].answer;
      const radioButtons = document.getElementsByName("composicion");
      console.log(radioButtons);
      for (let i = 0; i < radioButtons.length; i++) {
          console.log(radioButtons[i].value + " / " + answer);
        if (radioButtons[i].value == answer) {
          radioButtons[i].checked = true;
        
        } else {
          radioButtons[i].checked = false;
        }
      }      
    } catch (error) {
      console.error(error);
    }
    //Validation();
  };

  /*const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;

    let c = rut.replace("-", "");
    let b = c.charAt(c.length-1);
    let a = c.slice(0,c.length-1);
    document.querySelector('#rut').value = a + "-" + b;

    let rut2 = rut.replace("-", "");
    let n = rut2.charAt(rut2.length-1);
    let rut3 = rut2.slice(0,rut2.length-1);
    
    var M=0,S=1;
    for(;rut3;rut3=Math.floor(rut3/10))
    {
      S=(S+rut3%10*(9-M++%6))%11;
    }
    
    let result = S?S-1:'k';

    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
        setRut(e.target.value);
    }
  }*/

  const validateForm = () => {
    if ( document.getElementById("fecha").value.trim() !== '' && opcion.trim() !== '' ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const Clear = () => {
    const form = document.getElementById('examenes');
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      if (element.type !== 'button') {
        element.value = '';
      }
    }
    const opciones = document.getElementsByName("composicion");
    for (let i = 0; i < opciones.length; i++) {
      if (opciones[i].checked) {
        opciones[i].checked = false;
        break;
      }
    }

    const filesDiv = document.getElementById("files");
    filesDiv.innerHTML = "";

    setIsSubmitDisabled(true);
  }

  const Validation = async (e) => {
    //e.preventDefault();
    let isValid = true;
    const fecha = document.getElementById("fecha").value;
    if (fecha === "") {
      alert("Por favor ingresa una Fecha");
      isValid = false;
    }

    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
      isValid = false;
    }

    const opciones = document.getElementsByName("composicion");
    let seleccionado = false;
    for (let i = 0; i < opciones.length; i++) {
      if (opciones[i].checked) {          
        seleccionado = true;
        break;
      }
    }

    if (!seleccionado) {
      alert("Por favor selecciona una opción");
      isValid = false;
    }
      
    if (isValid) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
  };


  let formData = new FormData();
  const fileInput = document.querySelector('input[type="file"]');
  const Submit = async (e) => {
    e.preventDefault();
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      formData.append('dni', document.getElementById("rut").value);
      formData.append('date', document.getElementById("fecha").value);

      const elementosRadio = document.querySelectorAll('input[name="composicion"]');
      let valorSeleccionado;
      elementosRadio.forEach((elemento) => {
        if (elemento.checked) {
          valorSeleccionado = elemento.value;
        }
      });

      const answers = [{
        question: 'composition',
        answer: valorSeleccionado,
      }];

      formData.append(`answers[0]question`, 'composition');
      formData.append(`answers[0]answer`, valorSeleccionado);

      await Axios.patch('/core/laboratories-tests/'+id.id+'/kidney_stone_comp_update/', formData, {
        headers: { 
          "content-type": "multipart/form-data",
          'Authorization': "Token " + `${token}`
        },
      }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('Se ha actualizado correctamente.');
        }   
      }).catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });

      let formData2 = new FormData();
      const fileInput = document.querySelector('input[type="file"]');
      const files = fileInput.files;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formData2.append(`exams_files[${i}]file`, file);
        }

        await Axios.post('/core/laboratories-tests/'+id.id+'/files/', formData2, {
          headers: { 
            "content-type": "multipart/form-data",
            'Authorization': "Token " + `${token}`
          },
        }).then((response) => {
          if (response.status === 201 || response.status === 200) {
            alert('Se ha actualizado correctamente.');
            Clear();
            getExam();
          }   
        }).catch(function (error) {
          if (error.response) {
            alert('Error ' + error.response.status);
          }
        });
      }
    }
  };

  const Enviar = (e) => {
    e.preventDefault();
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      Axios.post('/core/laboratories-tests/' + id.id + '/send/', {}, {
        headers: {
          'Authorization': "Token " + token
        }
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('El examen fue enviado correctamente.');
          navigate("/laboratorios/examenes");
        }
      })
      .catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });
    }
  };
  
  const DeleteFile = async (idFile) => {
    if (window.confirm("¿Está seguro de que quiere eliminar?")) {
      try {
        const response = await Axios.delete(`/core/laboratories-tests/${id.id}/files/${idFile}`, {
          headers: {
            Authorization: "Token " + token,
          },
        });
        Clear();
        getExam();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios/examenes"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Modificación de Composición de Cálculo </h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form id="examenes" className="examenesForm">
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="13" /*onChange= {RUTValidation}*//>
          </div>
          <div className="examenesItem">
            <label>Fecha</label>
            <input id="fecha" type="date"  required onChange={(e) => setFecha(e.target.value)}/>
          </div>

          <div className="examenesItem" style={{width: "100%"}}></div>          
          
          <div className="questionItem">
            <label>Debe seleccionar las opciones que correspondan:</label>
            <div className="questionaryRadio">
              <input id="otro" name="composicion" type="radio" className="pregunta" value="Otro" required onChange={handleOpcionChange}/>
              <label>Otro</label><br></br>
              <input id="oxalatoCalcio" name="composicion" type="radio" className="pregunta" value="Contiene más de 90% de oxalato de calcio" onChange={handleOpcionChange}/>
              <label>Contiene más de 90% de oxalato de calcio</label><br></br>
              <input id="acidoUrico" name="composicion" type="radio" className="pregunta" value="Contiene más de 15% de ácido úrico" onChange={handleOpcionChange}/>
              <label>Contiene más de 15% de ácido úrico</label><br></br>
              <input id="brushita" name="composicion" type="radio" className="pregunta" value="Contiene más de 10% de calcio dihidratado(brushita)" onChange={handleOpcionChange}/>
              <label>Contiene más de 10% de calcio dihidratado(brushita)</label><br></br>
              <input id="carbapatita" name="composicion" type="radio" className="pregunta" value="Contiene más de 10% de fosato de calcio(carbapatita)" onChange={handleOpcionChange}/>
              <label>Contiene más de 10% de fosato de calcio(carbapatita)</label><br></br>
            </div>
          </div>
          <div className="examenesItem">
            <label>Selecciona los archivos</label>
            <div id="files"></div>
            <input id="archivo" type="file" style={{width: "450px", backgroundColor: "rgba(255, 255, 255, 1)"}}  multiple onChange={(e) => setArchivo(e.target.value)}/>
          </div>

          <div className="examenesItem" style={{width: "100%"}}></div>
          
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Submit(e)} /*disabled={isSubmitDisabled}*/>
            <FaEdit className="reportShowIcon"/>Actualizar exámen
          </button>
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Enviar(e)} /*disabled={isSubmitDisabled}*/>
            <SendRounded className="reportShowIcon"/>Enviar exámen
          </button>
        </form>
      </div>
    </div>
  );
}
