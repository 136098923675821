import "./readLab.css";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaRegPlusSquare, FaEdit} from "react-icons/fa";

export default function ReadLab() {
  
  const [data, setData] = useState([]);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  useEffect(() => {
    getLabs();
  }, []);

  const getLabs = async () => {
    await Axios.get('/users/labs/').then((response) => {     
      const obj = response.data;       
      setData(obj);
    });
  };

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: true,
    displayRowCheckbox:false,
    filter: false,
    download: false,
    print: false
  };
  
  const columns = [
    { name: "id", label: "ID", options: 
    {
      display: false,
    } },
    { name: "email", label: "Email" },
    {
      name: "name",
      label: "Nombre",
    },    
    {
      name: "extra_data",
      label: "Rut",
      options: {
        customBodyRender: (dataIndex, rowIndex) => {
          let rut = dataIndex.dni;
          return (rut);
        }
      }
    },
    {
      name: "extra_data",
      label: "Teléfono",
      options: {
        customBodyRender: (dataIndex, rowIndex) => {
          let tel = dataIndex.phone;
          return (tel);
        }
      }
    },
    {
      name: "Acciones",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, rowIndex) => {
          return (
            <>
              <Link to={"/laboratorios/editarLaboratorio/" + rowIndex.rowData[0]} style={{textDecoration:"none"}}>
                <button className="readLabEdit smallButton alignButton"><FaEdit className="reportShowIcon"/> Editar</button>
              </Link>
            </>
          );
        }
      },
    },
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="report">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Laboratorios</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <Link to={"/laboratorios/nuevoLaboratorio"} style={{textDecoration:"none"}}>
          <button className="newExamLab smallButton alignButton">
            <FaRegPlusSquare className="reportShowIcon"/> Ingresar Nuevo Laboratorio
          </button>
        </Link>
        <MUIDataTable
          data={data}
          columns={columns}
          options = {options}
        />
      </div>
    </div>
  );
}
