import React, { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { FaArrowAltCircleLeft, FaUserPlus} from "react-icons/fa";

export default function CreateUser() {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [rut, setRut] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  useEffect(() => {
    validateForm();
  }, [email, rut, nombre, telefono, pass1, pass2]);

  const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;

    let c = rut.replace("-", "");
    let b = c.charAt(c.length-1);
    let a = c.slice(0,c.length-1);
    document.querySelector('#rut').value = a + "-" + b;

    let rut2 = rut.replace("-", "");
    let n = rut2.charAt(rut2.length-1);
    let rut3 = rut2.slice(0,rut2.length-1);
    
    var M=0,S=1;
    for(;rut3;rut3=Math.floor(rut3/10))
    {
      S=(S+rut3%10*(9-M++%6))%11;
    }
    
    let result = S?S-1:'k';

    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
      setRut(e.target.value);
    }
  }
  
  const validateForm = () => {
    if (
      email.trim() !== '' &&
      rut.trim() !== '' &&
      nombre.trim() !== '' &&
      pass1.trim() !== '' &&
      pass2.trim() !== '' &&
      pass1 === pass2
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const Clear = () => {
    document.getElementById('email').value = '';
    document.getElementById('nombre').value = '';
    document.getElementById('rut').value = '';
    document.getElementById('telefono').value = 0;
    document.getElementById('pass1').value = '';
    document.getElementById('pass2').value = '';
    setIsSubmitDisabled(true);
  }

  const Submit = async (e) => {
    e.preventDefault();
    await Axios.post('/users/doctors/', {
      name: nombre,  
      email: email,
      password: pass1,
      extra_data: {
        dni: rut,
        address: "string",
        phone: telefono,
      }
    }).then((response) => {
      if (response.status == "201") {
        alert('El usuario se ha creado correctamente.');
        Clear();
      }   
    }).catch(function (error) {
      if (error.response) {
        // if(error.response.status == "400"){
        //   alert("El email introducido ya está en uso.")
        // }
        console.log(error.response);
      }
    });
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/usuarios"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Crear Usuario</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form className="examenesForm">
          <div className="examenesItem">
            <label>Email</label>
            <input id="email" type="email" placeholder="ejemplo@email.cl" required onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange= {RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Nombre</label>
            <input id="nombre" type="text" placeholder="John Smith" required onChange={(e) => setNombre(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Teléfono</label>
            <input id="telefono" type="number" placeholder="012345678" onChange={(e) => setTelefono(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Contraseña</label>
            <input id="pass1" type="password" placeholder="Ingresa una contraseña" required onChange={(e) => setPass1(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Repetir Contraseña</label>
            <input id="pass2" type="password" placeholder="Repite la contraseña" required onChange={(e) => setPass2(e.target.value)}/>
          </div>
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Submit(e)} disabled={isSubmitDisabled}>
            <FaUserPlus className="reportShowIcon"/>Crear Usuario
          </button>
        </form>
      </div>
    </div>
  );
}
