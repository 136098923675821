import "./prediction.css";
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

export default function FirstStep() {

    useEffect( () =>  {
        Paises();
        window.scrollTo(0, 0);
    }, []);
    
    var RegionesYcomunas = {
        "regiones": [{
                "NombreRegion": "Región de Arica y Parinacota",
        },
            {
                "NombreRegion": "Región de Tarapacá",
        },
            {
                "NombreRegion": "Región de Antofagasta",
        },
            {
                "NombreRegion": "Región de Atacama",
        },
            {
                "NombreRegion": "Región de Coquimbo",
        },
            {
                "NombreRegion": "Región de Valparaíso",
        },
            {
                "NombreRegion": "Región de B.O’Higgins",
        },
            {
                "NombreRegion": "Región del Maule",
        },
            {
                "NombreRegion": "Región del Biobío",
        },
            {
                "NombreRegion": "Región de la Araucanía",
        },
            {
                "NombreRegion": "Región de Los Ríos",
        },
            {
                "NombreRegion": "Región de Los Lagos",
        },
            {
                "NombreRegion": "Región de Aisén",
        },
            {
                "NombreRegion": "Región de Magallanes",
        },
            {
                "NombreRegion": "Región Metropolitana",
        },
        {
            "NombreRegion": "Región de Ñuble",
        }]
    }
    
    var Nacionalidades = {
        "nacionalidad": [{
            "NombreNacion": "Argentina",
        },
        {
            "NombreNacion": "Bolivia",
        },
        {
            "NombreNacion": "Brasil",
        },
        {
            "NombreNacion": "Canadá",
        },
        {
            "NombreNacion": "Chile",
        },
        {
            "NombreNacion": "Colombia",
        },
        {
            "NombreNacion": "Ecuador",
        },
        {
            "NombreNacion": "Estados Unidos",
        },
        {
            "NombreNacion": "México",
        },
        {
            "NombreNacion": "Paraguay",
        },
        {
            "NombreNacion": "Perú",
        },
        {
            "NombreNacion": "Uruguay",
        },
        {
            "NombreNacion": "Venezuela",
        },
        {
            "NombreNacion": "África",
        },
        {
            "NombreNacion": "Asia",
        },
        {
            "NombreNacion": "Europa",
        },
        {
            "NombreNacion": "Oceanía",
        },
        {
            "NombreNacion": "Otro",
        
        }]
    }

    const handleLoad = () => {
        if (localStorage.length > 0) {

            if(localStorage.hasOwnProperty('edad')){
                document.getElementById('edad').value = localStorage.getItem('edad');
            }

            if(localStorage.hasOwnProperty('sexo')){
                if(localStorage.getItem('sexo') == "Masculino"){
                    document.getElementById("masculino").checked = true;
                    document.getElementById("femenino").checked = false;
                }else{
                    document.getElementById("masculino").checked = false;
                    document.getElementById("femenino").checked = true;
                } 
            }
            
            if(localStorage.hasOwnProperty('altura')){
                document.getElementById('altura').value = localStorage.getItem('altura');
            }
            
            if(localStorage.hasOwnProperty('peso')){
                document.getElementById('peso').value = localStorage.getItem('peso');
            }
            
            if(localStorage.hasOwnProperty('raza')){
                var checkboxes = document.querySelectorAll('input[name="race"]');
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].value == (localStorage.getItem('raza')) || checkboxes[i].value.includes("Otro")) {
                        checkboxes[i].checked = true;
                        break;
                    }
                }

                if (localStorage.getItem('raza').includes("Otro"))
                {
                    let text = localStorage.getItem('raza'); 
                    document.getElementById('otro').value = text.replace("Otro:", "");
                }
            }

            if(localStorage.hasOwnProperty('nacionalidad')){
                document.getElementById('nacionalidad').value = localStorage.getItem('nacionalidad');
            }
            
            if(localStorage.hasOwnProperty('residencia')){
                document.getElementById('residencia').value = localStorage.getItem('residencia');
            }

            if(localStorage.hasOwnProperty('region_chile')){
                document.getElementById('region').value = localStorage.getItem('region_chile');

                if (localStorage.getItem('residencia') == "Chile"){
                    document.getElementById("region").disabled = false;
                } else {
                    document.getElementById('region').value = "sin-region";
                    document.getElementById("region").disabled = true;
                }
            }
        }
    }

    const Paises = () => {
        var iNacion = 0;
        var htmlNacionalidad = '<option value="sin-nacionalidad">Seleccione nacionalidad</option>';
        
        $.each(Nacionalidades.nacionalidad, function () {
            htmlNacionalidad = htmlNacionalidad + '<option value="' + Nacionalidades.nacionalidad[iNacion].NombreNacion + '">' + Nacionalidades.nacionalidad[iNacion].NombreNacion + '</option>';
            iNacion++;
        });

        $('#nacionalidad').html(htmlNacionalidad);
        $('#residencia').html(htmlNacionalidad);

        Regiones();
    }

    const Regiones = () => {
        var iRegion = 0;
        var htmlRegion = '<option value="sin-region">Seleccione región</option>';
    
        $.each(RegionesYcomunas.regiones, function () {
            htmlRegion = htmlRegion + '<option value="' + RegionesYcomunas.regiones[iRegion].NombreRegion + '">' + RegionesYcomunas.regiones[iRegion].NombreRegion + '</option>';
            iRegion++;
        });
        $('#region').html(htmlRegion);
        // document.getElementById("region").disabled = true;

        handleLoad();
    }

    const handleSave = () => {

        var select = document.getElementById('nacionalidad');
		var Nacionalidad = select.options[select.selectedIndex].value;

        var select2 = document.getElementById('residencia');
		var Residencia = select2.options[select2.selectedIndex].value;

        var select3 = document.getElementById('region');
        var Region = select3.options[select3.selectedIndex].value;
        
        localStorage.setItem('edad',  document.getElementById('edad').value);

        if(document.getElementById('masculino').checked){
            localStorage.setItem('sexo', "Masculino");
        }else if (document.getElementById('femenino').checked){
            localStorage.setItem('sexo', "Femenino");
        } 

        localStorage.setItem('altura', document.getElementById('altura').value);
        localStorage.setItem('peso', document.getElementById('peso').value);
        
        var checkboxes = document.querySelectorAll('input[name="race"]');
        var raza;
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked == true) {
                raza = checkboxes[i].value;
                break;
            }
        }

        if (raza != "Otro") {
            localStorage.setItem('raza', raza);
        } else {
            localStorage.setItem('raza', raza +":" + document.getElementById('otro').value);
        }

        localStorage.setItem('nacionalidad', Nacionalidad);
        localStorage.setItem('residencia', Residencia);
        if (localStorage.getItem('residencia') != "Chile"){
            localStorage.setItem('region_chile', "sin-region");
        } else {
            localStorage.setItem('region_chile', Region);
        }
    };

    const validateCountry = () => {
        if (document.getElementById("residencia").value == "Chile"){
            document.getElementById("region").disabled = false;
        } else {
            //$("#region").empty();
            document.getElementById("region").disabled = true;
        }
    }

    const ValEdad = (e) => {
        e.preventDefault();
        var edad = document.getElementById('edad').value;
        if (edad.trim().length == 0 || Number.isInteger(edad)) {
            document.getElementById('valEdad').innerHTML = '*Ingrese un número válido';
        } else if(edad < 1 || edad > 150) {
            document.getElementById('valEdad').innerHTML = '*Debe ser de 1 a 150.';
        } else {
            document.getElementById('valEdad').innerHTML = '';
            handleSave();
        }
    }

    const ValAltura = (e) => {
        e.preventDefault();
        var altura = document.getElementById('altura').value;
        if (altura.trim().length == 0 || Number.isInteger(altura)) {
            document.getElementById('valAltura').innerHTML = '*Ingrese un número válido';
        } else if(altura < 0.80 || altura > 2.50) {
            document.getElementById('valAltura').innerHTML = '*Debe ser de 0.80 a 2.50.';
        } else {
            document.getElementById('valAltura').innerHTML = '';
            handleSave();
        }
    }

    const ValPeso = (e) => {
        e.preventDefault();
        var peso = document.getElementById('peso').value;
        if (peso.trim().length == 0 || Number.isInteger(peso)) {
            document.getElementById('valPeso').innerHTML = '*Ingrese un número válido';
        } else if(peso < 20.0 || peso > 300.0) {
            document.getElementById('valPeso').innerHTML = '*Debe ser de 20.0 a 300.0.';
        } else {
            document.getElementById('valPeso').innerHTML = '';
            handleSave();
        }
    }

    const ValOtro = (e) => {
        e.preventDefault();
        
        var checkboxes = document.querySelectorAll('input[name="race"]');
        var raza;
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked == true) {
                raza = checkboxes[i].value;
                break;
            }
        }

        if(raza == "Otro"){
            var otro = document.getElementById('otro').value;
            if (otro.trim().length == 0 || Number.isInteger(otro) == true) {
                document.getElementById('valOtro').innerHTML = '*Ingrese un texto válido';
            } else {
                document.getElementById('valOtro').innerHTML = '';
                handleSave();
            }
        }
    }
    

    return (
        <div className="container">
            {/* <div className="subTitle">
                <h2 className="subText">Datos Generales</h2>
            </div> */}
            <div className="questionForm">
                <div className="questionItem">
                    <p className="encabezado">Edad <i id="valEdad" className="validation"></i> </p> 
                    <input 
                    id="edad" 
                    type="number" 
                    placeholder="34" 
                    min="1" 
                    max="150" 
                    required 
                    onChange={(e) => ValEdad(e)} 
                    />
                </div>
                <div className="questionItem">
                    <p className="encabezado">Sexo</p> 
                    <div className="predictionRadio">
                        <input type="radio" name="gender" id="masculino" value="Masculino" defaultChecked={true} onChange={(e) => {handleSave()}}/> 
                        <label for="masculino">Masculino</label> <br></br>
                        <input type="radio" name="gender" id="femenino" value="Femenino" onChange={(e) => {handleSave()}}/> 
                        <label for="femenino">Femenino</label>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">Altura(Mt) <i id="valAltura" className="validation"></i> </p>
                    <input 
                    id="altura" 
                    type="number" 
                    placeholder="1.69" 
                    min="0.80" 
                    max="2.50" 
                    step="0.1" 
                    required 
                    onChange={(e) => ValAltura(e)}
                    />
                </div>
                <div className="questionItem">
                    <p className="encabezado">Peso(kg) <i id="valPeso" className="validation"></i></p>
                    <input 
                    id="peso" 
                    type="number" 
                    placeholder="85" 
                    min="20.0" 
                    max="300.0" 
                    step="0.1" 
                    required 
                    onChange={(e) => ValPeso(e)}
                    />
                </div>
                <div className="questionItem">
                    <p className="encabezado">Raza <i id="valOtro" className="validation"></i></p>
                    <div className="predictionRadio">
                        <input type="radio" name="race" id="raza" className="pregunta" value="Anglosajon" checked required onChange={(e) => handleSave(e)}/>
                        <label for="anglosajón">Anglosajón</label> <br></br>
                        <input type="radio" name="race" id="raza" className="pregunta" value="Afroamericano" onChange={(e) => handleSave(e)}/>
                        <label for="afroamericano">Afroamericano</label> <br></br>
                        <input type="radio" name="race" id="raza" className="pregunta" value="Latino" onChange={(e) => handleSave(e)}/>
                        <label for="latino">Latino</label> <br></br>
                        <input type="radio" name="race" id="raza" className="pregunta" value="Asiatico" onChange={(e) => handleSave(e)}/>
                        <label for="asiatico">Asiático</label> <br></br>
                        <input type="radio" name="race" id="raza" className="pregunta" value="Indio-nativo" onChange={(e) => handleSave(e)}/>
                        <label for="indionativo">Indio-nativo</label> <br></br>
                        <input type="radio" name="race" id="raza" className="pregunta" value="Otro" onChange={(e) => handleSave(e)} />
                        <label for="otro">Otro</label> <input id="otro" type="text" className="inputOtros" onChange={(e) => ValOtro(e)}/>
                    </div>
                </div>
                <div className="questionItem">
                    <p className="encabezado">Nacionalidad </p>
                    <select id="nacionalidad" className="UserSelect" onChange={handleSave}></select>
                </div>
                <div className="questionItem">
                    <p className="encabezado">País / Continente de residencia </p>
                    <select id="residencia" className="UserSelect" onChange={(e) => {handleSave(); validateCountry()}}></select>
                </div>
                <div className="questionItem">
                    <p className="encabezado">Región (Si reside en Chile)</p>
                    <select id="region" className="UserSelect" onChange={handleSave}></select>
                </div>
            </div>
        </div>
    );
}