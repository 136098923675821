import React, { useState, useEffect } from 'react';


export default function Retorno() {

  return (
    <div className="container">
      <div className="completed">
        <h3>Su comentario fue enviado con éxito.</h3>
        <h2>¡Muchas gracias por utilizar iStone!</h2>
      </div>
    </div>
  );
}
