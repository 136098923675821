import "./examOrina.css";
import React, { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft, FaPlusCircle} from "react-icons/fa";
import { SendRounded} from "@material-ui/icons";

export default function ExamOrina() {
  const [showPart, setShowPart] = useState(false);

  const [rut, setRut] = useState('');
  const [fecha, setFecha] = useState('');
  const [opcion, setOpcion] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  var ph;
  var densidad

  //var urea;
  var creatinina;
  var acido_urico;
  var sodio;
  var potasio;
  var cloro;
  var calcio;
  var volumen;
  var magnesio;
  var oxalato;
  var ph_orina24;
  var fosforo;
  var nitrogeno_ureico;
  var sulfato;
  var amonio;

  useEffect( () =>  {
    const token = localStorage.getItem('token');

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== 'token') {
        localStorage.removeItem(key);
      }
    }

    if (token) {
      localStorage.setItem('token', token);
    }
  }, []);

  useEffect(() => {
    validateForm();
  }, [rut, fecha, opcion]);

  const RUTValidation=(e)=>{
    e.preventDefault();
    let rut = document.querySelector('#rut').value;

    let c = rut.replace("-", "");
    let b = c.charAt(c.length-1);
    let a = c.slice(0,c.length-1);
    document.querySelector('#rut').value = a + "-" + b;

    let rut2 = rut.replace("-", "");
    let n = rut2.charAt(rut2.length-1);
    let rut3 = rut2.slice(0,rut2.length-1);
    
    var M=0,S=1;
    for(;rut3;rut3=Math.floor(rut3/10))
    {
      S=(S+rut3%10*(9-M++%6))%11;
    }
    
    let result = S?S-1:'k';

    if(result != n)
    {
      document.getElementById('validation').innerHTML = '¡Rut no válido!';
    } else {
      document.getElementById('validation').innerHTML = '';
      setRut(e.target.value);
    }
  }

  const validateForm = () => {
    if (
      rut.trim() !== '' &&
      fecha.trim() !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const Clear = () => {
    const token = localStorage.getItem('token');

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== 'token') {
        localStorage.removeItem(key);
      }
    }

    if (token) {
      localStorage.setItem('token', token);
    }

    const form = document.getElementById('examenes');
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      if (element.type !== 'button') {
        element.value = '';
      }
    }

    document.getElementById("tipo").value = "1";
    document.getElementById("aspecto").value = "Transparente";
    document.getElementById("hematies").value = "No se observan";
    document.getElementById("cristales").value = "No se observan";

    setShowPart(false);
    setIsSubmitDisabled(true);
  }

  const handleSelect = (event) => {
    const value = event.target.value;
    setShowPart(value === "2");
  };
  
  const Conditional = async (e) => {
    e.preventDefault();
    const rut = document.getElementById("rut").value;
    if (rut.trim() === "") {
      alert("Por favor ingresa un RUT");
    } else {
      const fecha = document.getElementById("fecha").value;
      if (fecha === "") {
        alert("Por favor ingresa una Fecha");
      } else {
        const currentDate = new Date().toISOString().split("T")[0];
        if (fecha > currentDate) {
          alert("La fecha no puede ser posterior a la fecha actual");
        } else {
          if(document.getElementById("tipo").value == "2") {
            if(localStorage.getItem('acido_urico24') !== null && localStorage.getItem('calcio24') !== null && localStorage.getItem('volumen24') !== null && localStorage.getItem('magnesio24') !== null && localStorage.getItem('sodio24') !== null && localStorage.getItem('creatinina24') !== null){
              if(localStorage.getItem('acido_urico24').trim() !== "" && localStorage.getItem('calcio24').trim() !== "" && localStorage.getItem('volumen24').trim() !== "" && localStorage.getItem('magnesio24').trim() !== "" && localStorage.getItem('sodio24').trim() !== "" && localStorage.getItem('creatinina24').trim() !== ""){
                if (localStorage.getItem('oxalato24').trim() === "") {
                  localStorage.removeItem('oxalato24');
                }
                if (localStorage.getItem('ph_orina24').trim() === "") {
                  localStorage.removeItem('ph_orina24');
                }
                if (localStorage.getItem('fosforo24').trim() === "") {
                  localStorage.removeItem('fosforo24');
                }
                if (localStorage.getItem('nitrogeno_ureico24').trim() === "") {
                  localStorage.removeItem('nitrogeno_ureico24');
                }
                if (localStorage.getItem('sulfato24').trim() === "") {
                  localStorage.removeItem('sulfato24');
                }
                if (localStorage.getItem('cloro24').trim() === "") {
                  localStorage.removeItem('cloro24');
                }
                if (localStorage.getItem('amonio24').trim() === "") {
                  localStorage.removeItem('amonio24');
                }
                if (localStorage.getItem('potasio24').trim() === "") {
                  localStorage.removeItem('potasio24');
                }

                BotonGuardar24(e);
              } else {
                alert("Hay valores sin rellenar.");
              }
            } else {
                alert("Hay valores sin rellenar.");
            }
          } else if(document.getElementById("tipo").value == "1"){
            if(localStorage.getItem('ph') !== null && localStorage.getItem('densidad')){
              if(localStorage.getItem('ph').trim() !== "" && localStorage.getItem('densidad').trim() !== ""){
                BotonGuardar(e);
              } else {
                alert("Hay valores sin rellenar.");
              }
            } else {
                alert("Hay valores sin rellenar.");
            }
          }
        }
      }
    }
  };

  const Validation1 = async (e) => {
    let isValid = true;
    densidad = document.getElementById('densidad').value;
    if (densidad.trim().length == 0 || isNaN(densidad)) {
      document.getElementById('valDensidad').innerHTML = '*Ingrese un número válido';
      isValid = false;
    } else if(densidad < 1000 || densidad > 1200) {
      document.getElementById('valDensidad').innerHTML = '*Debe ser de 1000 a 1200.';
      isValid = false;
    } else {
      document.getElementById('valDensidad').innerHTML = '';
    }

    ph = document.getElementById('ph').value;
    if (ph.trim().length == 0 || isNaN(ph)) {
      document.getElementById('valPh').innerHTML = '*Ingrese un número válido';
      isValid = false;
    } else if(ph < 4.0 || ph > 9.0) {
      document.getElementById('valPh').innerHTML =  '*Debe ser de 4.0 a 9.0.';
      isValid = false;
    } else {
      document.getElementById('valPh').innerHTML = '';
    }

    if (isValid) {
      handleSave1();
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
    
  }

  const Validation24 = async (e) => {
    let isValid = true;
    acido_urico = document.getElementById('acido_urico24').value;
    if (acido_urico.trim().length == 0 || isNaN(acido_urico)) {
        document.getElementById('valAcido24').innerHTML = '*Ingrese un número válido';
        isValid = false;
    } else if(acido_urico < 0.00 || acido_urico > 5.00) {
        document.getElementById('valAcido24').innerHTML = '*Debe ser de 0.00 a 5.00.';
        isValid = false;
    } else {
        document.getElementById('valAcido24').innerHTML = '';
    }

    calcio = document.getElementById('calcio24').value;
    if (calcio.trim().length == 0 || isNaN(calcio)) {
        document.getElementById('valCalcio24').innerHTML = '*Ingrese un número válido';
        isValid = false;
    } else if(calcio < 0 || calcio > 500) {
        document.getElementById('valCalcio24').innerHTML = '*Debe ser de 0 a 500.';
        isValid = false;
    } else {
        document.getElementById('valCalcio24').innerHTML = '';
    }

    volumen = document.getElementById('volumen24').value;
    if (volumen.trim().length == 0 || isNaN(volumen)) {
        document.getElementById('valVolumen24').innerHTML = '*Ingrese un número válido';
        isValid = false;
    } else if(volumen < 0.00 || volumen > 5.00) {
        document.getElementById('valVolumen24').innerHTML = '*Debe ser de 0.00 a 5.00.';
        isValid = false;
    } else {
        document.getElementById('valVolumen24').innerHTML = '';
    }

    magnesio = document.getElementById('magnesio24').value;
    if (magnesio.trim().length == 0 || isNaN(magnesio)) {
        document.getElementById('valMagnesio24').innerHTML = '*Ingrese un número válido';
        isValid = false;
    } else if(magnesio < 0 || magnesio > 300) {
        document.getElementById('valMagnesio24').innerHTML = '*Debe ser de 0 a 300.';
        isValid = false;
    } else {
        document.getElementById('valMagnesio24').innerHTML = '';
    }

    sodio = document.getElementById('sodio24').value;
    if (sodio.trim().length == 0 || isNaN(sodio)) {
        document.getElementById('valSodio24').innerHTML = '*Ingrese un número válido';
    } else if(sodio < 0 || sodio > 300) {
        document.getElementById('valSodio24').innerHTML = '*Debe ser de 0 a 300.';
    } else {
        document.getElementById('valSodio24').innerHTML = '';
    }

    creatinina = document.getElementById('creatinina24').value;
    if (creatinina.trim().length == 0 || isNaN(creatinina)) {
        document.getElementById('valCreatinina24').innerHTML = '*Ingrese un número válido';
        isValid = false;
    } else if(creatinina < 0.00 || creatinina > 5.00) {
        document.getElementById('valCreatinina24').innerHTML = '*Debe ser de 0.00 a 5.00.';
        isValid = false;
    } else {
        document.getElementById('valCreatinina24').innerHTML = '';
    }

  //OPCIONALES -------------------------------------------------------------------------------------
    oxalato = document.getElementById('oxalato24').value;
    if (isNaN(oxalato)) {
      document.getElementById('valOxalato24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
      isValid = false;
    } else if(oxalato < 0 || oxalato > 100) {
      document.getElementById('valOxalato24').innerHTML = '*Debe ser de 0 a 100.';
      isValid = false;
    } else {
      document.getElementById('valOxalato24').innerHTML = '';
    }

    ph_orina24 = document.getElementById('ph_orina24').value;
    if (ph_orina24 !== "" && (isNaN(ph_orina24) || ph_orina24 < 4.00 || ph_orina24 > 9.00)) {
      document.getElementById('valphOrina24').innerHTML = '*Ingrese un número válido entre 4.00 y 9.00, o deje el campo vacío';
      isValid = false;
    } else {
      document.getElementById('valphOrina24').innerHTML = '';
    }

    fosforo = document.getElementById('fosforo24').value;
    if (isNaN(fosforo)) {
        document.getElementById('valFosforo24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(fosforo < 0.00 || fosforo > 5.00) {
        document.getElementById('valFosforo24').innerHTML = '*Debe ser de 0.00 a 5.00.';
        isValid = false;
    } else {
        document.getElementById('valFosforo24').innerHTML = '';
    }

    nitrogeno_ureico = document.getElementById('nitrogeno24').value;
    if (isNaN(nitrogeno_ureico)) {
        document.getElementById('valNitrogeno24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(nitrogeno_ureico < 0.00 || nitrogeno_ureico > 20.00) {
        document.getElementById('valNitrogeno24').innerHTML = '*Debe ser de 0.00 a 20.00.';
        isValid = false;
    } else {
        document.getElementById('valNitrogeno24').innerHTML = '';
    }

    sulfato = document.getElementById('sulfato24').value;
    if (isNaN(sulfato)) {
        document.getElementById('valSulfato24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(sulfato < 0 || sulfato > 300) {
        document.getElementById('valSulfato24').innerHTML = '*Debe ser de 0 a 300.';
        isValid = false;
    } else {
        document.getElementById('valSulfato24').innerHTML = '';
    }

    cloro = document.getElementById('cloro24').value;
    if (isNaN(cloro)) {
        document.getElementById('valCloro24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(cloro < 0 || cloro > 300) {
        document.getElementById('valCloro24').innerHTML = '*Debe ser de 0 a 300.';
        isValid = false;
    } else {
        document.getElementById('valCloro24').innerHTML = '';
    }

    amonio = document.getElementById('amonio24').value;
    if (isNaN(amonio)) {
        document.getElementById('valAmonio24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(amonio < 0 || amonio > 300) {
        document.getElementById('valAmonio24').innerHTML = '*Debe ser de 0 a 300.';
        isValid = false;
    } else {
        document.getElementById('valAmonio24').innerHTML = '';
    }

    potasio = document.getElementById('potasio24').value;
    if (isNaN(potasio)) {
        document.getElementById('valPotasio24').innerHTML = '*Ingrese un número válido o deje el campo vacío';
        isValid = false;
    } else if(potasio < 0 || potasio > 300) {
        document.getElementById('valPotasio24').innerHTML = '*Debe ser de 0 a 300.';
        isValid = false;
    } else {
        document.getElementById('valPotasio24').innerHTML = '';
    }

    if (isValid) {
      handleSave24();
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }

  const handleSave1 = () => {
    if(densidad >= 0){
      localStorage.setItem('densidad',  densidad);
    }

    if(ph >= 0){
      localStorage.setItem('ph',  ph);
    }
    
  }

  const handleSave24 = () => {
    if(acido_urico >= 0){
      localStorage.setItem('acido_urico24',  acido_urico);
    }

    if(calcio >= 0){
      localStorage.setItem('calcio24',  calcio);
  }

    if(volumen >= 0){
      localStorage.setItem('volumen24',  volumen);
    }

    if(magnesio >= 0){
      localStorage.setItem('magnesio24',  magnesio);
    }

    if(sodio >= 0){
      localStorage.setItem('sodio24',  sodio);
    }

    if(creatinina >= 0){
      localStorage.setItem('creatinina24',  creatinina);
    }

    if(oxalato >= 0){
      localStorage.setItem('oxalato24',  oxalato);
    }

    if(ph_orina24 >= 0){
      localStorage.setItem('ph_orina24',  ph_orina24);
    }

    if(fosforo >= 0){
      localStorage.setItem('fosforo24',  fosforo);
    }

    if(nitrogeno_ureico >= 0){
      localStorage.setItem('nitrogeno_ureico24',  nitrogeno_ureico);
    }

    if(sulfato >= 0){
      localStorage.setItem('sulfato24',  sulfato);
    }

    if(cloro >= 0){
      localStorage.setItem('cloro24',  cloro);
    }

    if(amonio >= 0){
      localStorage.setItem('amonio24',  amonio);
    }

    if(potasio >= 0){
      localStorage.setItem('potasio24',  potasio);
    }
  }

  var new_laboratory;
  const Submit1 = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append('dni', document.getElementById("rut").value);
    formData.append('date', document.getElementById("fecha").value);

    const answers = [
      {
        question: 'aspecto',
        answer: document.getElementById("aspecto").value
      },
      {
        question: 'densidad',
        answer: localStorage.getItem('densidad'),
      },
      {
        question: 'ph',
        answer: localStorage.getItem('ph'),
      },
      {
        question: 'hematies',
        answer: document.getElementById("hematies").value,
      },
      {
        question: 'cristales',
        answer: document.getElementById("cristales").value,
      }
    ];
    
    answers.forEach((answer, i) => {
      formData.append(`answers[${i}]question`, answer.question);
      formData.append(`answers[${i}]answer`, answer.answer);
    });

    await Axios.post('/core/laboratories-tests/urine_test/', formData, 
    {
      headers: { 
        "content-type": "multipart/form-data",
        'Authorization': "Token " + `${token}`
      },
    }).then((response) => {
      //console.log(response.status);
      if (response.status === 201 || response.status === 200) {
        alert('Se ha creado correctamente.');
        new_laboratory = response.data.new_laboratory;
        // SubmitFile(new_laboratory);
        // Clear();
      }   
    }).catch(function (error) {
      if (error.response) {
        alert('Error ' + error.response.status);
      }
    });
  };

  const Submit24 = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    
    formData.append('dni', document.getElementById("rut").value);
    formData.append('date', document.getElementById("fecha").value);

    const answers = [
      {
        question: "acido_urico",
        answer: localStorage.getItem("acido_urico24") || null,
      },
      {
        question: "calcio",
        answer: localStorage.getItem("calcio24") || null,
      },
      {
        question: "volumen_orina",
        answer: localStorage.getItem("volumen24") || null,
      },
      {
        question: "magnesio",
        answer: localStorage.getItem("magnesio24") || null,
      },
      {
        question: "sodio",
        answer: localStorage.getItem("sodio24") || null,
      },
      {
        question: "creatinina",
        answer: localStorage.getItem("creatinina24") || null,
      },
      {
        question: "Oxalato",
        answer: localStorage.getItem("oxalato24") || null,
      },
      {
        question: "ph_orina",
        answer: localStorage.getItem("ph_orina24") || null,
      },
      {
        question: "fosforo",
        answer: localStorage.getItem("fosforo24") || null,
      },
      {
        question: "nitrogeno",
        answer: localStorage.getItem("nitrogeno_ureico24") || null,
      },
      {
        question: "sulfato",
        answer: localStorage.getItem("sulfato24") || null,
      },
      {
        question: "cloro",
        answer: localStorage.getItem("cloro24") || null,
      },
      {
        question: "amonio",
        answer: localStorage.getItem("amonio24") || null,
      },
      {
        question: "potasio",
        answer: localStorage.getItem("potasio24") || null,
      },
    ];
    

    answers.forEach((answer, i) => {
      formData.append(`answers[${i}]question`, answer.question);
      formData.append(`answers[${i}]answer`, answer.answer);
    });

    await Axios.post('/core/laboratories-tests/urine_24_test/', formData, 
    {
      headers: { 
        "content-type": "multipart/form-data",
        'Authorization': "Token " + `${token}`
    },
    }).then((response) => {
      if (response.status === 201 || response.status === 200) {
        alert('Se ha creado correctamente.');
        new_laboratory = response.data.new_laboratory;
        //SubmitFile(new_laboratory);
        //Clear();
      }   
    }).catch(function (error) {
      if (error.response) {
        alert('Error ' + error.response.status);
        //console.log(error.response.status);
      }
    });
  };

  const SubmitFile = async (new_laboratory) => {
    let formData2 = new FormData();
    const fileInput = document.querySelector('input[type="file"]');
    const files = fileInput.files;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData2.append(`exams_files[${i}]file`, file);
      }

      await Axios.post('/core/laboratories-tests/'+new_laboratory+'/files/', formData2, {
        headers: { 
          "content-type": "multipart/form-data",
          'Authorization': "Token " + `${token}`
        },
      }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('Los archivos se han subido correctamente.');
        }   
      }).catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });
    }
  }

  const Send = (new_laboratory) => {
      Axios.post('/core/laboratories-tests/' + new_laboratory + '/send/', {}, {
        headers: {
          'Authorization': "Token " + token
        }
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          alert('El examen fue enviado correctamente.');
          navigate("/laboratorios/examenes");
        }
      })
      .catch(function (error) {
        if (error.response) {
          alert('Error ' + error.response.status);
        }
      });
  };
  
  const BotonGuardar = async (e) => {
    e.preventDefault();
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      await Submit1(e);
      await SubmitFile(new_laboratory);
      Clear();
    }

  };

  const BotonGuardar24 = async (e) => {
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      e.preventDefault();
      await Submit24(e);
      await SubmitFile(new_laboratory);
      Clear();
    }
  };

  const BotonEnviar = async (e) => {
    e.preventDefault();
    var fecha = document.getElementById("fecha").value;
    const currentDate = new Date().toISOString().split("T")[0];
    if (fecha > currentDate) {
      alert("La fecha no puede ser posterior a la fecha actual");
    } else {
      if(document.getElementById("tipo").value == "2"){
        await Submit24(e);
        await SubmitFile(new_laboratory);
        await Send(new_laboratory);
      } else {
        await Submit1(e);
        await SubmitFile(new_laboratory);
        await Send(new_laboratory);
      } 
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="examenes">
        <div className="reportTitleContainer">
          <Link to={"/laboratorios/examenes"}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="title">Ingreso de Exámen de Orina</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <form className="examenesForm" id="examenes">
          <div className="examenesItem">
            <label>Rut  <sub id="validation"></sub> </label> 
            <input id="rut" type="text" placeholder="12345678-9" minlength="0" maxLength="10" onChange={RUTValidation}/>
          </div>
          <div className="examenesItem">
            <label>Fecha</label>
            <input id="fecha" type="date"  required onChange={(e) => setFecha(e.target.value)}/>
          </div>
          <div className="examenesItem">
            <label>Tipo</label>
            <select name="tipo" id="tipo" onChange={handleSelect}>
              <option className="ExamSelect" value="1">Normal</option>
              <option className="ExamSelect" value="2">24 Horas</option>
            </select>
          </div>
          

        {!showPart && (
          <div style={{display:"contents"}}>
            <div className="examenesItem separador">Obligatorios</div>
            <div className="examenesItem">
              <label>Aspecto <i id="valAspecto" className="validation"></i></label>
              <select id="aspecto">
                <option value="Transparente">Transparente</option>
                <option value="Ligeramente Turbio">Ligeramente Turbio</option>
                <option value="Turbio">Turbio</option>
                <option value="No se sabe">No se sabe</option>
              </select>
            </div>
            <div className="examenesItem">
              <label>Densidad <i id="valDensidad" className="validation"></i></label> 
              <input id="densidad" type="number" placeholder="1015" min="1000" max="1200"  required onChange={Validation1}/>
            </div>
            <div className="examenesItem">
              <label>pH <i id="valPh" className="validation"></i></label> 
              <input id="ph" type="number" placeholder="6.8" min="4.0" max="9.0" step="0.1" required onChange={Validation1}/>
            </div>
            <div className="examenesItem">
              <label>Hematies (Glóbulos Rojos)<i id="valHematies" className="validation"></i></label>
              <select id="hematies">
                <option value="No se observan">No se observan </option>
                <option value="Escasos">Escasos</option>
                <option value="Regulares">Regulares</option>
                <option value="Abundantes">Abundantes</option>
              </select>
            </div>
            <div className="examenesItem">
              <label>Cristales <i id="valCristales" className="validation"></i></label>
              <select id="cristales">
                <option value="No se observan">No se observan </option>
                <option value="Escasos">Escasos</option>
                <option value="Regulares">Regulares</option>
                <option value="Abundantes">Abundantes</option>
              </select>
            </div>
          </div>
        )}

        {showPart && (
          <div style={{display:"contents"}}>
            <div className="examenesItem separador">Obligatorios</div>
            <div className="examenesItem">
              <label>Acido Úrico <i id="valAcido24" className="validation"></i></label>
              <input id="acido_urico24" type="number" placeholder="3.41" min="0" max="5.00" step="0.1" required onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Calcio <i id="valCalcio24" className="validation"></i></label>
              <input id="calcio24" type="number" placeholder="324" min="0" max="500" step="1" required onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Volumen Orina <i id="valVolumen24" className="validation"></i></label>
              <input id="volumen24" type="number" placeholder="2.50" min="0.00" max="5.00" step="0.1" required onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Magnesio <i id="valMagnesio24" className="validation"></i></label>
              <input id="magnesio24" type="number" placeholder="230" min="0" max="300" step="1" required onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Sodio <i id="valSodio24" className="validation"></i></label>
              <input id="sodio24" type="number" placeholder="137" min="0" max="300" required onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Creatinina <i id="valCreatinina24" className="validation"></i></label>
              <input id="creatinina24" type="number" placeholder="0.98" min="0.00" max="5.00" step="0.1" required onChange={Validation24}/>
            </div>

            <div className="examenesItem separador">Opcionales</div>

            <div className="examenesItem" >
              <label>Oxalato <i id="valOxalato24" className="validation"></i></label>
              <input id="oxalato24" type="number" placeholder="45" min="0" max="100" step="1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Ph. Orina <i id="valphOrina24" className="validation"></i></label>
              <input id="ph_orina24" type="number" placeholder="4.8" min="4.0" max="9.0" step="0.1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Fosforo <i id="valFosforo24" className="validation"></i></label>
              <input id="fosforo24" type="number" placeholder="2.41" min="0" max="5.0" step="0.1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Nitrógeno Ureico <i id="valNitrogeno24" className="validation"></i></label>
              <input id="nitrogeno24" type="number" placeholder="18.5" min="0" max="20.0" step="0.1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Sulfato <i id="valSulfato24" className="validation"></i></label>
              <input id="sulfato24" type="number" placeholder="142" min="0" max="300" step="1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Cloro <i id="valCloro24" className="validation"></i></label>
              <input id="cloro24" type="number" placeholder="247" min="0" max="300" step="1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Amonio <i id="valAmonio24" className="validation"></i></label>
              <input id="amonio24" type="number" placeholder="175" min="0" max="300" step="1" onChange={Validation24}/>
            </div>
            <div className="examenesItem">
              <label>Potasio <i id="valPotasio24" className="validation"></i></label>
              <input id="potasio24" type="number" placeholder="150" min="0" max="300" step="1" onChange={Validation24}/>
            </div>
          </div>
        )}
          <div className="examenesItem" style={{width: "100%"}}></div>
      
          <div className="examenesItem">
            <label>Selecciona los archivos</label>
            <input id="archivo" type="file" style={{width: "450px", backgroundColor: "rgba(255, 255, 255, 1)"}} multiple />
          </div>
          <div className="examenesItem" style={{width: "100%"}}></div>
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => Conditional(e)} disabled={isSubmitDisabled}>
            <FaPlusCircle className="reportShowIcon" />Guardar exámen
          </button>
          <button id="submit" className="examenesButton bigButton alignButton" onClick={(e) => BotonEnviar(e)} disabled={isSubmitDisabled}>
            <SendRounded className="reportShowIcon"/>Enviar exámen
          </button>
        </form>
      </div>
    </div>
  );
}
