import "./readExam.css";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaPlusCircle, FaEdit, FaFileDownload,FaEye} from "react-icons/fa";

export default function ReadExam() {
  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);

  useEffect(() => {
    getExams();
  }, []);

  const getExams = async () => {
    await Axios.get('/core/laboratories-tests/', {
      headers: {
        Authorization: "Token "+ `${token}`,
      }
    }).then((response) => {  
      const obj = response.data;       
      setData(obj);      
    });
  };

  const downloadExcel = async () => {
    try {
      const response = await Axios.get('/core/laboratories-tests/spreadsheet/', {
        responseType: 'blob',
        headers: {
          'Authorization': 'Token ' + token,
        },
      });
  
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Registros de Exámenes.xlsx';
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error(error);
    }
  };

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: true,
    displayRowCheckbox:false,
    filter: false,
    download: false,
    print: false
  };
  
  const columns = [
    { name: "id", label: "ID", options: { 
      display: false,
    }},
    { name: "dni", label: "Rut"},
    {
      name: "date",
      label: "Fecha",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const fechaObjeto = new Date(value);
          const dia = fechaObjeto.getUTCDate();
          const mes = fechaObjeto.getUTCMonth() + 1;
          const anio = fechaObjeto.getUTCFullYear();
          const fechaInvertida = `${dia}/${mes}/${anio}`;
          return fechaInvertida;
        },
      },
    },
    { name: "exam_type", label: "Tipo"},
    {
      name: "is_sent",
      label: "Estado",
      options: {
        customBodyRender: (dataIndex, rowIndex) => {
          if(dataIndex === false) {
            return (
              <button 
                style={{backgroundColor:"gold", color: "white", borderRadius:"10px", padding:"5px", width:"100px", height:"30px"}}
              > 
                <b>PENDIENTE</b>
              </button> 
            );
          } else {
            return (
              <button 
              style={{backgroundColor:"#095", color: "white", borderRadius:"10px", padding:"5px", width:"100px", height:"30px"}}
              > 
                <b>ENVIADO </b>
              </button> 
            );
          }
        }
      }
    },
    {
      name: "ACCIONES",
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({ style: { width: "200px" } }),
        customBodyRender: (dataIndex, rowIndex) => {
          const isSent = rowIndex.rowData[4];
          const examType = rowIndex.rowData[3];
    
          if (!isSent) {
            if (examType === "COMPOSICION") {
              return (
                <div style={{display: "flex" }}>
                  <Link to={"/laboratorios/examenes/composicion/" + rowIndex.rowData[0] + "/"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEdit className="reportShowIcon" /> Editar</button>
                  </Link>
                  <Link to={"/laboratorios/examenes/composicion/" + rowIndex.rowData[0] + "/ver"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEye className="reportShowIcon" /> Ver</button>
                  </Link>
                </div>
              );
            } else if (examType === "ORINA" || examType === "ORINA 24 HORAS") {
              return (
                <div style={{display: "flex" }}>
                  <Link to={"/laboratorios/examenes/orina/" + rowIndex.rowData[0] + "/"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEdit className="reportShowIcon" /> Editar</button>
                  </Link>
                  <Link to={"/laboratorios/examenes/orina/" + rowIndex.rowData[0] + "/ver"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEye className="reportShowIcon" /> Ver</button>
                  </Link>
                </div>
              );
            }
          } else {
            if (examType === "COMPOSICION") {
              return (
                <div style={{display: "flex" }}>
                  <Link to={"/laboratorios/examenes/composicion/" + rowIndex.rowData[0] + "/ver"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEye className="reportShowIcon" /> Ver</button>
                  </Link>
                </div>
              );
            } else if (examType === "ORINA" || examType === "ORINA 24 HORAS") {
              return (
                <div style={{display:"flex"}}>
                  <Link to={"/laboratorios/examenes/orina/" + rowIndex.rowData[0] + "/ver"} style={{ textDecoration: "none" }}>
                    <button className="readLabEdit smallButton alignButton"><FaEye className="reportShowIcon" /> Ver</button>
                  </Link>
                </div>
              );
            }
          }
        },
      },
    }
  ];


  return (
    <div className="container">
      <Sidebar />
      <div className="report">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Exámenes</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <div style={{ display: "flex" }}>
          <Link to={"/laboratorios/examenes/nuevaComposicion"} style={{ textDecoration: "none" }}>
            <button className="newExamLab smallButton alignButton">
              <FaPlusCircle className="reportShowIcon" /> Ingresar Nueva Composición
            </button>
          </Link>
          <Link to={"/laboratorios/examenes/nuevoExamen"} style={{ textDecoration: "none", marginLeft: "45px" }}>
            <button className="newExamLab smallButton alignButton">
              <FaPlusCircle className="reportShowIcon" /> Ingresar Nuevo Exámen de Orina
            </button>
          </Link>
          <button className="newExamLab bigButton alignButton" style={{ marginLeft: "auto" }} onClick={downloadExcel}>
            <FaFileDownload className="reportShowIcon" /> Registro de Exámenes Enviados
          </button>
        </div>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{
            ...options,
            setTableProps: () => ({
              style: { textAlign: 'left' }
            })
          }}
        />
      </div>
    </div>
  );
}
