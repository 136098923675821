import "./topbar.css";
import { Link, useLocation  } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/Context";
import Logo from '../../img/iStone.png';
import { FaIdCard, FaKey, FaDoorClosed } from "react-icons/fa";

export default function Topbar() {
  const { user, dispatch } = useContext(Context);
  const location = useLocation();
  const pathname = location.pathname;
  // console.log(pathname);
  
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  if(pathname !== "/encuesta" && pathname !== "/encuesta/completada" && pathname !== "/prediccion" && pathname !== "/prediccion/completada"){
    return (
      <div className="topbar">
        <div className="topbarWrapper">
          <div className="topLeft">
            <span className="logo"><img src={Logo} alt="iStone Logo" /></span>
          </div>
          <div className="topRight">
          {
            user? (
              <nav id="nav">
                <ul>
                  <li>
                    {/* <label></label> */}
                    <label className="topUser"><FaIdCard className="iconProfile"/> <a className="name">{user.name}</a></label>
                    <ul>
                      <li>
                        <Link to="/cambiarContrasena" className="textBar"> 
                        <FaKey className="iconProfile"/> Cambiar Contraseña</Link>
                      </li>
                      <li onClick={handleLogout} className="textBar"> 
                        <FaDoorClosed className="iconProfile"/> {user && "Cerrar Sesión"}
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            ) : (
              <nav id="nav"></nav>
            )
          }
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="topbar">
        <div className="topbarWrapper">
          <div className="topLeft">
            <span className="logo"><img src={Logo} alt="iStone Logo" /></span>
          </div>
          <div className="topRight">
            <nav id="nav"></nav>
          </div>
        </div>
      </div>
    );
  }

  
}
