import "./readReport.css";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from '../../../api/axios';
import Sidebar from "../../../components/sidebar/Sidebar";
import { FaExclamationCircle, FaFileMedical, FaComments } from "react-icons/fa";
import { CancelScheduleSend, Pageview } from "@material-ui/icons";

export default function ReadReport() {
  const [data, setData] = useState([]);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  useEffect(() => {
    getDoctors();
  }, []);
  
  const getDoctors = async () => {
    await Axios.get('/predictions/patients/').then((response) => {     
      const obj = response.data; 
      setData(obj);
    });
  };

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: true,
    displayRowCheckbox:false,
    filterType: "dropdown",
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    setCellProps: () => {
      return {
        style: {
          textAlign: "center !important" ,
        },
      };
    },
  };
  
  const columns = [
    { name: "id", label: "ID", options: 
    {
      display: false,
    } },
    { name: "probability", label: "Prob.",
      options: {
        setCellProps: () => ({ style: { width: "50px" } }),
        customBodyRender: (dataIndex, rowIndex) => {
          let prob = (dataIndex*100).toFixed(2) + "%";
          return (prob);
        }
      }
    },
    {
      name: "date",
      label: "Fecha",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const fechaObjeto = new Date(value);
          const dia = fechaObjeto.getUTCDate();
          const mes = fechaObjeto.getUTCMonth() + 1;
          const anio = fechaObjeto.getUTCFullYear();
          const fechaInvertida = `${dia}/${mes}/${anio}`;
          return fechaInvertida;
        },
      },
    },
    { name: "name", label: "Nombre",
      options:{
        setCellProps: () => ({ style: { width: "30%" } }),
      } 
    },
    {
      name: "dni",
      label: "Rut",
      options: {
        setCellProps: () => ({ style: { width: "120px" } }),
        customBodyRender: (dataIndex, rowIndex) => {
          let rut = dataIndex;
          return (rut);
        }
      }
    },
    { name: "color", label: "RIESGO",
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({ style: { width: "50px" } }),
        customBodyRender: (dataIndex, rowIndex) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FaExclamationCircle 
                className="reportShowIcon" 
                style={{color: dataIndex}}
              > 
                {/* &nbsp; &nbsp; &nbsp;  */}
              </FaExclamationCircle>
            </div>
          );
        }
      } 
    },
    {
      name: "is_sent_report",
      label: "Informe no Enviado",
      options: {
        setCellProps: () => ({ style: { width: "120px" } }),
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex === false) {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CancelScheduleSend
                  style={{ color: "#29aae1", fontSize: "2rem" }}
                />
              </div>
            );
          }
        }
      }
    },
    { name: "detalle", 
      label: "Detalle", 
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, rowIndex) => {
          return (
            <>
              <Link to={"/pacientes/" + rowIndex.rowData[0]} style={{textDecoration:"none"}}>
                <button className="readUserEdit smallButton alignButton">
                  <Pageview className="reportShowIcon"/>Detalle
                </button>
              </Link>
            </>
          );
        }
      },
    },
  ];

  return (
    <div className="container">
      <Sidebar />
      <div className="report">
        <div className="reportTitleContainer">
          <div>&nbsp; &nbsp; &nbsp; </div>
          <h1 className="title">Pacientes</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <MUIDataTable
          data={data}
          columns={columns}
          options = {options}
        />
      </div>
    </div>
  );
}
