import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "./summary.css";
import Sidebar from "../../../components/sidebar/Sidebar";
import Axios from '../../../api/axios';
import { FaArrowAltCircleLeft, FaUserAlt, FaAddressCard} from "react-icons/fa";
import {
  LocalDrink,
} from "@material-ui/icons";
import Swal from 'sweetalert2';

export default function Summary() {
  let id = useParams();
  const [data, setData] = useState({});
  const [composicion, setComposicion] = useState({});
  const [orina, setOrina] = useState({});
  const [orina24, setOrina24] = useState({});
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    getExams();
    getPaciente();
  }, []);

  const getPaciente = async () => {
    await Axios.get('/predictions/patients/'+id.id).then((response) => {     
      const obj = response.data; 
      setData(obj);
    });
  };
  
  const getExams = async () => {
    setIsLoading(true);
  
    try {
      const response = await Axios.get('/predictions/patients/'+id.id+'/exams');
      const obj = response.data;
  
      //console.log(obj);

      setComposicion(obj.data.COMPOSICION);
      setOrina(obj.data.ORINA);
      setOrina24(obj.data["ORINA 24 HORAS"]);
      console.log(orina24);
      setFiles(obj.files);
    } catch (error) {
      //console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createLinks = () => {
    const orina = [];
    const orina24 = [];
    const composicion = [];
  
    for (const key in files) {
      const fileArray = files[key];
  
      for (const fileObj of fileArray) {
        const { date, links } = fileObj;
  
        if (links.length > 0) {
          const linkElements = links.map((linkObj) => {
            const { id, name, file } = linkObj;
            return (
              <li key={id}>
                <a href={file} target="_blank" rel="noopener noreferrer">
                  {name}
                </a>
              </li>
            );
          });
  
          if (key === "ORINA") {
            orina.push(
              <details key={date}>
                <summary>{date}</summary>
                <ul>{linkElements}</ul>
              </details>
            );
            orina.push(<br key={`br-${date}`} />);
          } else if (key === "COMPOSICION") {
            composicion.push(
              <details key={date}>
                <summary>{date}</summary>
                <ul>{linkElements}</ul>
              </details>
            );
            composicion.push(<br key={`br-${date}`} />);
          } else if (key === "ORINA 24 HORAS") {
            orina24.push(
              <details key={date}>
                <summary>{date}</summary>
                <ul>{linkElements}</ul>
              </details>
            );
            orina24.push(<br key={`br-${date}`} />);
          }
        }
      }
    }
  
    return { orina, orina24, composicion };
  };
    

  const showInfoElements = orina
  ? Object.entries(orina).map(([key, value]) => {
      if (key !== "date") {
        return (
          <div className="summaryShowInfo" key={key}>
            <span className="summaryShowInfoTitle">{key} :</span>
            <span className="summaryShowInfoText">{value}</span>
          </div>
        );
      }
      return null;
    })
  : null;

const showInfoElements2 = orina24
  ? Object.entries(orina24).map(([key, value]) => {
      if (key !== "date") {
        return (
          <div className="summaryShowInfo" key={key}>
            <span className="summaryShowInfoTitle">{key} :</span>
            <span className="summaryShowInfoText">
              {value !== null && value !== undefined ? value : "dato no ingresado"}
            </span>
          </div>
        );
      }
      return null;
    })
  : null;

  return (
    <div className="container">
      <Sidebar />
      <div className="summary">
        <div className="reportTitleContainer">
          <Link to={"/pacientes/"+id.id}><FaArrowAltCircleLeft className="iconBack"/></Link>
          <h1 className="summaryTitle title">Resumen</h1>
          <div>&nbsp; &nbsp; &nbsp; </div>
        </div>
        <div className="summaryContainer">
          <div className="summaryShow">
            <div className="summaryShowBottom">
              <div>
                <span className="summaryShowTitle">Datos Personales</span>
                <div className="summaryShowInfo">
                  <FaUserAlt className="reportShowIcon" />
                  <span className="summaryShowInfoTitle">Paciente: </span>
                  <span className="summaryShowInfoText">{data.name}</span>
                </div>
                <div className="summaryShowInfo">
                  <FaAddressCard className="reportShowIcon" />
                  <span className="summaryShowInfoTitle">Rut: </span>
                  <span className="summaryShowInfoText">{data.rut}</span>
                </div>
              </div>

              <span className="summaryShowTitle">Orina Normal</span>
              {isLoading ? (
                  <div>Cargando datos...</div>
                ) : (
                  orina && typeof orina === 'object' && Object.keys(orina).length > 0 ? (
                    <div>
                      <div className="summaryShowInfo">
                        <span className="summaryShowInfoTitle">Fecha Ingreso:</span>
                        <span className="summaryShowInfoText">{orina.date}</span>
                      </div>
                      {showInfoElements}
                    </div>
                  ) : (
                    <div>No hay datos de orina.</div>
                  )
                )
              }
              <br></br>
              <span className="summaryShowTitle">Orina 24 Horas</span>
              {isLoading ? (
                  <div>Cargando datos...</div>
                ) : (
                  orina24 && typeof orina24 === 'object' && Object.keys(orina24).length > 0 ? (
                    <div>
                      <div className="summaryShowInfo">
                        <span className="summaryShowInfoTitle">Fecha Ingreso:</span>
                        <span className="summaryShowInfoText">{orina24.date}</span>
                      </div>
                      {showInfoElements2}
                    </div>
                  ) : (
                    <div>No hay datos de orina.</div>
                  )
                )
              }
              <br></br>
              <span className="summaryShowTitle">Composición</span>
              {isLoading ? (
                <div>Cargando datos...</div>
                ) : (
                  composicion && typeof composicion === 'object' && Object.keys(composicion).length > 0 ? (
                    <div>
                      <div className="summaryShowInfo">
                        <span className="summaryShowInfoTitle">Fecha ingreso: </span>
                        <span className="summaryShowInfoText">{composicion.date}</span>
                      </div>
                      <div className="summaryShowInfo">
                        <span className="summaryShowInfoText">{composicion.composition}</span>
                      </div>
                    </div>
                  ) : (
                    <div>No hay datos de composición.</div>
                  )
                )}

            </div>
          </div>
          <div className="summaryUpdate">
            <article className="main">
              <div className="summary2Update">
                <span className="themeName"><LocalDrink className="reportShowIcon"/> Orina</span>
                <div className="summary2UpdateForm">
                  <div className="summary2UpdateLeft">
                    <div className="summary2UpdateItem" id="orina">
                      {isLoading ? (
                        <div>Cargando datos...</div>
                      ) : (
                        files && typeof files === 'object' && Object.keys(files).length > 0 ? (
                        <div>
                          {createLinks().orina}
                        </div>
                        ) : (
                          <div>No hay examenes subidos.</div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <br></br>
            <article className="main">
              <div className="summary2Update">
                <span className="themeName"><LocalDrink className="reportShowIcon"/> Orina 24 Horas</span>
                <div className="summary2UpdateForm">
                  <div className="summary2UpdateLeft">
                    <div className="summary2UpdateItem" id="orina">
                      {isLoading ? (
                        <div>Cargando datos...</div>
                      ) : (
                        files && typeof files === 'object' && Object.keys(files).length > 0 ? (
                        <div>
                          {createLinks().orina24}
                        </div>
                        ) : (
                          <div>No hay examenes subidos.</div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <br></br>
            <article className="main">
              <div className="summary2Update">
                <span className="themeName"><LocalDrink className="reportShowIcon"/> Composición</span>
                <div className="summary2UpdateForm">
                  <div className="summary2UpdateLeft">
                    <div className="summary2UpdateItem" id="composicion">
                      {isLoading ? (
                        <div>Cargando datos...</div>
                      ) : (
                        files && typeof files === 'object' && Object.keys(files).length > 0 ? (
                        <div>
                          {createLinks().composicion}
                        </div>
                        ) : (
                          <div>No hay examenes subidos.</div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          {/* <div className="summaryUpdate">
            <span className="summaryUpdateTitle">Orina 24 Horas</span>
            <div className="summaryUpdateForm">
              <div className="summaryUpdateLeft">
                <label>Fecha ingreso: </label><label>12/06/2022</label>
                <div className="summaryUpdateItem">
                  <label className="themeName">Obligatorios</label>
                  <label>Ácido úirico (g/d)</label>
                  <label>Calcio (mg/dL)</label>
                  <label>Volumen Orina (L/d)</label>
                  <label>Magnesio (mg/dL)</label>
                  <label>Sodio (mmol/dL)</label>
                  <label>Creatinina (mg/dL)</label>
                </div>
                <div className="summaryUpdateItem">
                  <label className="themeName">Opcionales.</label>
                  <label>Oxalato (mg/dL)</label>
                  <label>Ph Orina</label>
                  <label>Fosforo (g/dL)</label>
                  <label>Nitrógeno ureico (g/dL)</label>
                  <label>Sulfato (mEq/dL)</label>
                  <label>Cloro (mmol/dL)</label>
                  <label>Amorio (mmol/dL)</label>
                  <label>Potasio (mmol/dL)</label>
                  <label>Tasa de catabolismo proteico (g/kg/dL)</label>
                  <label>Creatinina por peso corporal (mg/dL/kg)</label>
                  <label>Calcio por peso corporal (mg/dL/kg)</label>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
